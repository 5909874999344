import React, { useRef, useState } from 'react'
import Button from 'components/button'
import Modal, { ModalProps } from 'components/modal'
import { useProject } from 'hooks/project'
import api from 'helpers/api'
import { history } from 'helpers/history'
import { PROJECT_BASE } from 'routes'
import { useApplicationStore } from 'hooks/application'
import Checkbox from 'components/checkbox'
import { Plus } from 'components/icons'
import constants from 'style/constants.module.scss'
import { IFolder } from 'types'

interface NewTableProps extends ModalProps {
  noRedirect?: boolean
  noRedirectFunction?: (id: string) => void
}

type newTableTypes = 'Upload CSV' | 'Blank Table'

const NewTable: React.FC<NewTableProps> = ({ id, open, setOpen, noRedirect, noRedirectFunction }) => {
  const { displayErrorMessage } = useApplicationStore()

  const [name, setName] = useState<string>()
  const tableSourceInit: newTableTypes = 'Blank Table'
  const [source, setSource] = useState<string>(tableSourceInit)
  const inputFile: any = useRef(null)
  const [forHeadersChecked, setForHeadersChecked] = useState(true)
  const { project, updateProject } = useProject()
  const [creatingTableLoading, setCreatingTableLoading] = useState(false)
  const { setSnackbarMessage } = useApplicationStore()

  const handleClose = () => {
    setOpen(false)
  }

  async function createTable() {
    const blockCreateTable =
      name === undefined ||
      name === '' ||
      source === '' ||
      (source === 'Upload CSV' && !inputFile.current.files[0]) ||
      creatingTableLoading

    if (!blockCreateTable) {
      const file = inputFile.current && inputFile.current.files && inputFile.current.files[0]
      const type = ''
      if (source === 'Blank Table') {
        try {
          const res = await api.createNewTable({
            projectId: project.publicId,
            name,
            type,
            context: { projectId: project.publicId }
          })

          if (res && res.data) {
            updateProject({
              ...project,
              folders: project.folders.map((folder: IFolder) =>
                folder.id === ''
                  ? {
                      ...folder,
                      resources: folder.resources
                        ? [
                            ...folder.resources,
                            {
                              name: res.data.name,
                              type: 'table',
                              table: res.data
                            }
                          ]
                        : [
                            {
                              name: res.data.name,
                              type: 'table',
                              table: res.data
                            }
                          ]
                    }
                  : folder
              )
            })

            if (!noRedirect || !noRedirectFunction) {
              history.push(
                PROJECT_BASE + project.publicId + `/table/` + res.data.publicId + '/view/' + res.data.defaultViewId
              )
              handleClose()
            } else {
              noRedirectFunction(res.data.publicId)
              handleClose()
            }
          }
        } catch (error) {
          displayErrorMessage(error)
        }

        return
      }

      if (source === 'Upload CSV') {
        if (file && creatingTableLoading === false) {
          setCreatingTableLoading(true)
          const reader = new FileReader()
          reader.onload = function () {
            file.text().then(() => {
              api
                .postCSV(name, '', file, forHeadersChecked, project.publicId)
                .then((res: any) => {
                  let jsonResponse = null
                  let data = null
                  try {
                    jsonResponse = JSON.parse(res)
                    data = jsonResponse.data
                  } catch (error) {
                    setSnackbarMessage({
                      status: 'error',
                      message: 'Something went wrong creating the table! Please try again.'
                    })
                    setCreatingTableLoading(false)
                    return
                  }

                  if (data) {
                    setCreatingTableLoading(false)
                    if (!noRedirect || !noRedirectFunction) {
                      history.push(
                        PROJECT_BASE +
                          project.publicId +
                          `/table/` +
                          jsonResponse.data.publicId +
                          '/view/' +
                          jsonResponse.data.defaultViewId
                      )
                      handleClose()
                    } else {
                      noRedirectFunction(jsonResponse.data.publicId)
                      handleClose()
                    }
                  } else {
                    const detail = jsonResponse.detail
                    const errors = detail?.errors
                    if (errors) {
                      setSnackbarMessage({
                        status: 'error',
                        message: errors[0].messages[0]
                      })
                    } else {
                      setSnackbarMessage({
                        status: 'error',
                        message: 'Something went wrong creating the table! Please try again.'
                      })
                    }

                    setCreatingTableLoading(false)
                  }
                })
                .catch((e) => {
                  displayErrorMessage(e)
                  setCreatingTableLoading(false)
                  return
                })
            })
          }
          reader.readAsText(file)
        }
      }
    } else {
      if (name === undefined || name === '') {
        setSnackbarMessage({
          status: 'error',
          message: 'Please enter a name for the table.'
        })
      } else if (source === '') {
        setSnackbarMessage({
          status: 'error',
          message: 'Please select a source for the table.'
        })
      } else if (source === 'Upload CSV' && !inputFile.current.files[0]) {
        setSnackbarMessage({
          status: 'error',
          message: 'Please select a CSV file to upload.'
        })
      }
    }
  }

  return (
    <Modal id={id} open={open} setOpen={setOpen} title="Create New Table">
      <input
        className="w-full"
        data-cy="table-name"
        required
        placeholder="Enter the name of your new table..."
        onChange={(event) => setName(event.target.value)}
        style={{ marginBottom: '20px' }}
        autoFocus
      />

      <div className="flex items-center" style={{ gap: '20px' }}>
        <div
          className={`flex flex-column rounded ${
            source === 'Blank Table' ? 'border-blue' : 'border-grey'
          } border-solid border-2px hover-shadow transition-all cursor-pointer`}
          style={{ height: '400px', width: '300px' }}
          onClick={() => setSource('Blank Table')}
        >
          <div
            className="flex items-center justify-center"
            style={{ minHeight: '200px', maxHeight: '200px', backgroundColor: constants.reallyLightBlue }}
          >
            <img
              className="border-solid border-grey shadow"
              src="/assets/images/newtable.png"
              style={{ height: '60%', width: '60%' }}
            />
          </div>
          <div className="border-t-1px border-solid border-grey" style={{ flex: 1, padding: '10px' }}>
            <div className="font-bold text-lg" style={{ marginBottom: '10px' }}>
              Create from scratch
            </div>
            <div>Start with a blank table and add your own columns and rows.</div>
          </div>
        </div>

        <div
          className={`flex flex-column rounded ${
            source === 'Upload CSV' ? 'border-blue' : 'border-grey'
          } border-solid border-2px hover-shadow transition-all cursor-pointer`}
          style={{ height: '400px', width: '300px' }}
          onClick={() => setSource('Upload CSV')}
        >
          <div
            className="flex items-center justify-center"
            style={{ minHeight: '200px', maxHeight: '200px', backgroundColor: constants.lightGreen }}
          >
            <img
              className="border-solid border-grey shadow"
              src="/assets/images/csvtable.png"
              style={{ height: '60%', width: '60%' }}
            />
          </div>
          <div className="border-t-1px border-solid border-grey" style={{ flex: 1, padding: '10px' }}>
            <div className="font-bold text-lg" style={{ marginBottom: '10px' }}>
              Create from CSV file
            </div>
            <div style={{ marginBottom: '20px' }}>Upload a CSV file to create a table with your data.</div>
            <input
              className="text-sm"
              type="file"
              id="file"
              ref={inputFile}
              accept=".csv"
              style={{ resize: 'none', padding: '5px' }}
            />
            <Checkbox
              checked={forHeadersChecked}
              onChange={(event) => {
                setForHeadersChecked(event.target.checked)
              }}
            >
              Use first row as column headers
            </Checkbox>
          </div>
        </div>
      </div>

      <div className="flex mt-20px">
        <Button
          className="ml-auto text-base"
          internalType="accept"
          onClick={() => createTable()}
          data-cy="create-table-button"
          isLoading={creatingTableLoading}
        >
          <Plus style={{ marginRight: '5px' }} /> Create New Table
        </Button>
      </div>
    </Modal>
  )
}

export default NewTable
