import React, { useState, useEffect } from 'react'
import Modal, { ModalProps } from 'components/modal'
import Button from 'components/button'
import api from 'helpers/api'
import { IProcessSectionObject } from 'types'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import { useDataContext } from 'components/process/contexts/data'

const RestoreProcessSection: React.FC<ModalProps> = ({ id, open, setOpen }) => {
  const { process, setProcess } = useDataContext()
  const [selectedProcessSection, setSelectedProcessSection] = useState<string>('')
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()
  const [deletedSections, setDeletedSections] = useState<Array<IProcessSectionObject>>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (process && process.publicId !== '') {
      setLoading(true)
      api
        .getDeletedProcessSections(process.publicId)
        .then((response) => {
          setDeletedSections(response.data)
          setLoading(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setLoading(false)
        })
    }
  }, [process && process.publicId])

  const updateProcessSection = (
    sectionId: string,
    children: IProcessSectionObject[],
    sectionUpdated: IProcessSectionObject
  ): IProcessSectionObject[] => {
    children.forEach((child) => {
      if (child.publicId === sectionUpdated?.parentId) {
        child.children.push(sectionUpdated)
        return children
      } else {
        return updateProcessSection(sectionId, child.children, sectionUpdated)
      }
    })
    return children
  }

  const restoreProcessSection = () => {
    if (process && selectedProcessSection && selectedProcessSection !== '') {
      api
        .restoreProcessSection(project.publicId, process.publicId, selectedProcessSection)
        .then((response) => {
          setOpen(false)
          setProcess({
            ...process,
            children: updateProcessSection(response.data.publicId, process.children, response.data)
          })

          setSnackbarMessage({ status: 'success' })
        })
        .catch((error) => {
          displayErrorMessage(error)
          setOpen(false)
        })
    }
  }

  return (
    <Modal id={id} open={open} setOpen={setOpen} title="Restore Document Section">
      Please select which archived document section you wish to restore:
      <br />
      <br />
      {!loading && (
        <select
          value={selectedProcessSection}
          data-cy="select-restore-section"
          onChange={(event: any) => setSelectedProcessSection(event.target.value)}
        >
          <option value={''}>None Selected</option>
          {deletedSections &&
            deletedSections.map(function (processSection: IProcessSectionObject, processSectionNumber: number) {
              if (processSection.deletedAt)
                return (
                  <option
                    key={processSectionNumber}
                    value={processSection.publicId}
                    data-cy={`restore-section-${processSectionNumber}`}
                  >
                    {processSection.name + ' (Deleted On ' + processSection.deletedAt.split('T')[0] + ')'}
                  </option>
                )
            })}
        </select>
      )}
      <div className="flex w-full">
        <Button
          style={{ marginTop: '40px', marginLeft: 'auto' }}
          onClick={() => restoreProcessSection()}
          data-cy="restore-process-section-button"
          isLoading={loading}
          disabled={!(selectedProcessSection && selectedProcessSection !== '')}
        >
          Restore Document Section
        </Button>
      </div>
    </Modal>
  )
}

export default RestoreProcessSection
