import React, { useEffect, useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import Button from 'components/button'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import { useDataContext } from 'components/process/contexts/data'
import Select from 'components/select'
import { ISummaryUser, IUserObject } from 'types'

const SetRespondersModal: React.FC<ModalProps> = ({ id, open, setOpen, onClose }) => {
  const { process, setProcess } = useDataContext()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()
  const [loading, setLoading] = useState<boolean>(false)
  const [processName, setProcessName] = useState<string>('')
  const [responders, setResponders] = useState<{ userIds: string[]; users: Array<ISummaryUser | IUserObject> }>({
    userIds: [],
    users: []
  })

  const [permissionedUsers, setPermissionedUsers] = useState<Array<IUserObject | ISummaryUser>>()

  useEffect(() => {
    if (process) {
      api
        .searchUsers({ processId: process.publicId })
        .then((apiResponse) => {
          const list = [
            ...apiResponse.data.filter((user: any) => !responders.userIds.includes(user.firebaseUserId)),
            ...responders.users
          ]
          setPermissionedUsers(list)
        })
        .catch((error) => {
          displayErrorMessage(error)
        })
    }
  }, [process?.publicId])

  const onAllRespondersChange = (options: string[]) => {
    if (permissionedUsers) {
      const newUsers: Array<ISummaryUser | IUserObject> = []
      for (let i = 0; i < options.length; i++) {
        const option = options[i]
        const user = permissionedUsers.find((user) => user.firebaseUserId === option)
        if (user) {
          newUsers.push(user)
        }
      }
      setResponders({
        userIds: newUsers.map((user) => user.firebaseUserId),
        users: newUsers
      })
    }
  }

  function onRespondersChange(option: string) {
    if (permissionedUsers) {
      const user = permissionedUsers.find((user) => user.firebaseUserId === option)
      if (user) {
        const newUsers = [...responders.users]
        const index = newUsers.findIndex((responder) => responder.firebaseUserId === option)
        if (index === -1) {
          newUsers.push(user)
        } else {
          newUsers.splice(index, 1)
        }

        setResponders({
          userIds: newUsers.map((user) => user.firebaseUserId),
          users: newUsers
        })
      }
    }
  }

  const handleClick = async () => {
    if (process && processName === process.name) {
      setLoading(true)
      api
        .updateProcessResponders({
          responders: responders.userIds,
          processId: process.publicId,
          context: { projectId: project.publicId }
        })
        .then((response) => {
          setProcess(response.data)
          setSnackbarMessage({ status: 'success' })
          setLoading(false)
          setOpen(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setLoading(false)
        })
    }
  }

  return (
    <Modal id={id} open={open} setOpen={setOpen} onClose={onClose} title="Set All Responders">
      The users you enter below will be set as responders to all subsections within this document. Only do this if you
      are sure that all questions should be answered by the users below. The existing responders will be replaced with
      those below.
      <div className="w-full" style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Select
          options={
            permissionedUsers
              ? permissionedUsers.map((user) => {
                  return { value: user.firebaseUserId, label: `${user.name} (${user.email})` }
                })
              : []
          }
          multiselect={true}
          loading={permissionedUsers === undefined}
          info={"Only users who have 'contributor' permissions to the document will show up here"}
          onOptionClick={(option) => onRespondersChange(option)}
          setOptionsSelected={(options) => onAllRespondersChange(options)}
          optionsSelected={responders.userIds}
        />
      </div>
      <div className="select-text">
        Please type the name of the process {` ('${process ? process.name : ''}')`} before you confirm the action.
      </div>
      <input
        className="w-full"
        onChange={(event) => setProcessName(event.target.value)}
        style={{ marginTop: '30px' }}
      />
      <div className="flex justify-end mt-20px">
        <Button
          internalType="danger"
          onClick={() => handleClick()}
          isLoading={loading}
          disabled={!(process && processName === process.name)}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

export default SetRespondersModal
