import React, { useEffect, useState } from 'react'
import { useAuth } from 'hooks/auth'
import { useParams } from 'react-router'
import Information from 'views/profile/components/information'
import api from 'helpers/api'
import { IUserObject } from 'types'

interface UrlParams {
  userId: string
  tab: string
}

const Profile: React.FC = () => {
  const urlParams = useParams<UrlParams>()
  const userID = urlParams.userId
  const [userData, setUserData] = useState<IUserObject>()

  useEffect(() => {
    setUserData(undefined)
    api.getUser(userID).then((response) => {
      setUserData(response.data)
      document.title = `${response.data.name}`
    })
  }, [userID])

  const { user, updateUser } = useAuth()

  const ownProfile = user && user.firebaseUserId === userID ? true : false

  const localUpdateUser = (data: IUserObject) => {
    api({
      method: 'PUT',
      endpoint: '/user',
      data: {
        name: data.name,
        bio: data.bio,
        linkedin: data.linkedin,
        twitter: data.twitter,
        university: data.university,
        universityDegree: data.universityDegree,
        signature: data.signature,
        profilePicture: data.profilePicture,
        organisation: data.organisation,
        location: data.location,
        website: data.website
      }
    }).then((response) => {
      setUserData(response.data)
      updateUser(response.data)
    })
  }

  return (
    <div className="flex flex-column h-full w-full select-none bg-white overflow-auto">
      <div className="flex w-full" style={{ padding: '0px 30px' }}>
        <Information updateUser={localUpdateUser} ownProfile={ownProfile} userInfo={userData} />
      </div>
    </div>
  )
}

export default Profile
