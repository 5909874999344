import React, { useEffect, useState } from 'react'
import api, { APIError, ApiErrorCodes } from 'helpers/api'
import { ILogObject, OptionProps, IProject, IProjectMember } from 'types'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import { Table } from 'components/table'
import { Copy } from 'components/icons'
import { dateTime } from 'helpers/date'
import Button from 'components/button'
import Select from 'components/select'
import { AUDIT_RED_KEYWORDS, AUDIT_GREEN_KEYWORDS, PROJECT } from 'app-constants'
import constants from 'style/constants.module.scss'
import { capitaliseFirstLetter } from 'helpers/utils'
import { useLocation } from 'react-router-dom'
interface AuditProps {
  type?: string
  resourceId?: string
  searchTerm?: string
  verbs?: string[]
}

const Audit: React.FC<AuditProps> = ({ type, resourceId, searchTerm, verbs }: AuditProps) => {
  const location = useLocation()
  const isAdminRoute = location.pathname.includes('/admin/')
  const [loading, setLoading] = useState<boolean>(false)

  const [config, setConfig] = useState<{
    search: string
    page: number
    users: string[]
    verbs: string[]
    projects: string[]
    startDate: string
    endDate: string
  }>({
    search: searchTerm ? searchTerm : '',
    page: 1,
    users: [],
    verbs: verbs ? verbs : [],
    projects: [],
    startDate: '',
    endDate: ''
  })

  const [logs, setLogs] = useState<ILogObject[]>([])
  const [availableEventNames, setAvailableEventNames] = useState<string[]>([])
  const [users, setUsers] = useState<OptionProps[]>([])
  const [projects, setProjects] = useState<OptionProps[]>([])
  const [noMoreLogs, setNoMoreLogs] = useState<boolean>(false)
  const [blurLogs, setBlurLogs] = useState<boolean>(false)

  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()

  useEffect(() => {
    fetchAvailabileEventTypes()
    if (isAdminRoute) {
      let projectsCall
      if (resourceId && type) {
        projectsCall = api.getProjects()
      } else {
        projectsCall = api.getAllProjects(true)
      }

      projectsCall
        .then((response) => {
          setProjects(
            response.data
              .sort((aProject: IProject, bProject: IProject) => {
                if (aProject.name.toLowerCase() > bProject.name.toLowerCase()) return 1
                else if (aProject.name.toLowerCase() < bProject.name.toLowerCase()) return -1
                else return 0
              })
              .map((project: IProject) => {
                return { label: `${project.name} (${project.publicId.split('-')[0]})`, value: project.publicId }
              })
          )
        })
        .catch((error) => {
          displayErrorMessage(error)
          setProjects([])
        })
    } else if (resourceId && type && !project.loading) {
      api
        .getProjectMembers(project.publicId)
        .then((response) => {
          setUsers(
            response.data
              .sort((a: IProjectMember, b: IProjectMember) => {
                if (a.user.name.toLowerCase() > b.user.name.toLowerCase()) return 1
                else if (a.user.name.toLowerCase() < b.user.name.toLowerCase()) return -1
                else return 0
              })
              .map((member: IProjectMember) => {
                return { label: `${member.user.name} (${member.user.email})`, value: member.user.publicId }
              })
          )
        })
        .catch((error) => {
          displayErrorMessage(error)
          setUsers([])
        })
    }
  }, [])

  const fetchAvailabileEventTypes = async () => {
    try {
      const response = await api.getNotificationEventTypes()
      let eventTypes: string[] = response.data
      if (type === 'process') {
        eventTypes = response.data.filter(
          (eventType: string) =>
            eventType.startsWith('process') ||
            eventType.startsWith('table.cell') ||
            eventType.startsWith('table.row') ||
            eventType.startsWith('table.sync_completed') ||
            eventType.startsWith('table.sync_error') ||
            eventType.startsWith('table.script_error') ||
            eventType.includes('comment') ||
            eventType.includes('thread') ||
            eventType.includes('csv') ||
            eventType.includes('policy')
        )
      } else if (type === 'table') {
        eventTypes = response.data.filter(
          (eventType: string) =>
            !(eventType.startsWith('project') || eventType.startsWith('member') || eventType.startsWith('process'))
        )
      }
      setAvailableEventNames(eventTypes)
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  useEffect(() => {
    setLoading(true)
    let newLogs: ILogObject[] = logs
    if (config.page === 1) newLogs = []

    const users = config.users
    const verbs = config.verbs
    const startDate =
      config.startDate === '' || isNaN(Date.parse(config.startDate)) ? null : new Date(config.startDate).toISOString()
    const endDate =
      config.endDate === '' || isNaN(Date.parse(config.endDate)) ? null : new Date(config.endDate).toISOString()
    const search = config.search === '' ? null : config.search
    if (isAdminRoute) {
      api
        .getAdminEvents(config.page, users, verbs, startDate, endDate, search, config.projects)
        .then((response) => {
          const additionalLogs: ILogObject[] = response.data
          setLogs(newLogs.concat(additionalLogs))
          setLoading(false)
          if (additionalLogs.length < 50) setNoMoreLogs(true)
          else if (additionalLogs.length === 50) setNoMoreLogs(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setLoading(false)
          setNoMoreLogs(true)
        })
    } else if (resourceId && type) {
      api
        .getEvents(resourceId, type, config.page, users, verbs, startDate, endDate, search)
        .then((response) => {
          const additionalLogs: ILogObject[] = response.data
          setLogs(newLogs.concat(additionalLogs))
          setLoading(false)
          if (additionalLogs.length < 50) setNoMoreLogs(true)
          else if (additionalLogs.length === 50) setNoMoreLogs(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          if (error instanceof APIError) {
            if (error.code == ApiErrorCodes.SUBSCRIPTION_LEVEL) {
              setLogs(
                Array(10)
                  .fill(0)
                  .map(() => {
                    return {
                      publicId: 'YYYY',
                      user: {
                        firebaseUserId: 'YYYY',
                        email: 'AAAA',
                        profilePicture: '/assets/images/user.png',
                        name: 'System',
                        kind: 'User'
                      },
                      contextProject: null,
                      contextProcess: null,
                      contextProcessSection: null,
                      contextProcessResponse: null,
                      contextTable: null,
                      contextTableView: null,
                      contextTableColumn: null,
                      verb: 'created',
                      resource: 'table',
                      change: error.message,
                      createdAt: new Date().toISOString(),
                      context: null
                    }
                  })
              )

              setBlurLogs(true)
            }
          }
          setLoading(false)
          setNoMoreLogs(true)
        })
    }
  }, [config])

  const handleUserChange = (user: string) => {
    let new_users: string[] = []
    if (user === '') {
      new_users = []
    } else if (config.users.includes(user)) {
      new_users = config.users.filter((v) => v !== user)
    } else {
      new_users = [...config.users, user]
    }
    setConfig({
      ...config,
      page: 1,
      users: new_users
    })
  }

  const handleVerbChange = (verb: string) => {
    let new_verbs: string[] = []
    if (verb === '') {
      new_verbs = []
    } else if (config.verbs.includes(verb)) {
      new_verbs = config.verbs.filter((v) => v !== verb)
    } else {
      new_verbs = [...config.verbs, verb]
    }

    setConfig({
      ...config,
      page: 1,
      verbs: new_verbs
    })
  }

  const handleProjectChange = (project: string) => {
    let newProjects: string[] = []
    if (project === '') {
      newProjects = []
    } else if (config.projects.includes(project)) {
      newProjects = config.projects.filter((v) => v !== project)
    } else {
      newProjects = [...config.projects, project]
    }

    setConfig({
      ...config,
      page: 1,
      projects: newProjects
    })
  }

  const handleStartDateChange = (startDate: string) => {
    setConfig({
      ...config,
      page: 1,
      startDate
    })
  }

  const handleEndDateChange = (endDate: string) => {
    setConfig({
      ...config,
      page: 1,
      endDate
    })
  }

  const handleChangeCopy = (change: string) => {
    try {
      navigator.clipboard.writeText(change)
      setSnackbarMessage({
        status: 'success',
        message: `Copied change value to clipboard`
      })
    } catch (error) {
      displayErrorMessage(error)
    }
  }

  return (
    <div
      className="flex flex-column w-full overflow-hidden p-4"
      style={{ maxHeight: '100%', filter: blurLogs ? 'blur(7px)' : '0', pointerEvents: blurLogs ? 'none' : 'auto' }}
    >
      <div className="flex items-center text-sm mb-2" style={{ height: '70px' }}>
        {isAdminRoute && (
          <div className="flex items-center">
            <div className="mr-2 w-250px">
              <Select
                options={projects}
                onOptionClick={(option) => handleProjectChange(option)}
                optionsSelected={config.projects}
                multiselect={true}
                placeholder="All Projects"
              />
            </div>
            <div
              style={{ marginLeft: '5px', cursor: 'pointer' }}
              className="mr-2"
              onClick={() => handleProjectChange('')}
            >
              ❌
            </div>
          </div>
        )}
        {!isAdminRoute && (
          <div className="flex items-center">
            {' '}
            <div className="mr-2 w-250px">
              <Select
                options={users}
                onOptionClick={(option) => handleUserChange(option)}
                optionsSelected={config.users}
                multiselect={true}
                placeholder="All Users"
              />
            </div>
            <div style={{ marginLeft: '5px', cursor: 'pointer' }} className="mr-2" onClick={() => handleUserChange('')}>
              ❌
            </div>
          </div>
        )}
        <div className="mr-2 w-250px">
          <Select
            options={availableEventNames.map((eventTypeName) => {
              return { label: eventTypeName, value: eventTypeName }
            })}
            onOptionClick={(option) => handleVerbChange(option)}
            optionsSelected={config.verbs}
            multiselect={true}
            placeholder="All event types"
          />
        </div>
        <div style={{ marginLeft: '5px', cursor: 'pointer' }} className="mr-2" onClick={() => handleVerbChange('')}>
          ❌
        </div>
        <label style={{ display: 'block' }} htmlFor="start-date">
          From:
        </label>
        <input
          type="datetime-local"
          id="start-date"
          name="start-date"
          style={{ marginLeft: '5px', userSelect: 'none', cursor: 'pointer' }}
          value={config.startDate}
          max={config.endDate}
          onChange={(event) => handleStartDateChange(event.target.value)}
          onClick={(event) => event.stopPropagation()}
        />
        <div
          style={{ marginLeft: '5px', cursor: 'pointer' }}
          className="mr-2"
          onClick={() => handleStartDateChange('')}
        >
          ❌
        </div>
        <label style={{ display: 'block' }} htmlFor="end-date">
          To:
        </label>
        <input
          type="datetime-local"
          id="end-date"
          name="end-date"
          style={{ marginLeft: '5px', userSelect: 'none', cursor: 'pointer' }}
          min={config.startDate}
          value={config.endDate}
          onChange={(event) => handleEndDateChange(event.target.value)}
          onClick={(event) => event.stopPropagation()}
        />
        <div style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => handleEndDateChange('')}>
          ❌
        </div>
      </div>

      <Table
        data={logs.map((log) => {
          return {
            name: {
              label: (
                <div className="flex items-center">
                  <img
                    src={log.user && log.user.profilePicture ? log.user.profilePicture : '/assets/images/user.png'}
                    style={{
                      borderRadius: '50%',
                      width: '40px',
                      height: '40px',
                      objectFit: 'cover',
                      marginRight: '10px'
                    }}
                    alt={`${log.user && log.user.name ? log.user.name : 'Anonymous'} profile picture`}
                  />
                  {log.user && log.user.name && log.user.firebaseUserId ? (
                    <a
                      href={`/profile/${log.user.firebaseUserId}/information`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {log.user.name}
                    </a>
                  ) : (
                    <div>Anonymous</div>
                  )}
                </div>
              ),
              value: log.user && log.user.name ? log.user.name : 'Anonymous'
            },
            project: {
              label: log.contextProject ? (
                <a href={`/project/${log.contextProject.publicId}`} target="_blank" rel="noopener noreferrer">
                  {log.contextProject.name}
                </a>
              ) : (
                ''
              ),
              value: log.contextProject ? log.contextProject.name : ''
            },
            createdAt: { label: dateTime(new Date(Date.parse(log.createdAt))).toLocaleString(), value: log.createdAt },
            action: {
              label: (
                <span
                  className="font-bold rounded"
                  style={{
                    backgroundColor:
                      AUDIT_RED_KEYWORDS.findIndex((keyword) => log.verb.includes(keyword)) !== -1
                        ? constants.lightRed
                        : AUDIT_GREEN_KEYWORDS.findIndex((keyword) => log.verb.includes(keyword)) !== -1
                        ? constants.green
                        : constants.lightBlue,
                    padding: '5px'
                  }}
                >
                  {log.resource}.{log.verb}
                </span>
              ),
              value: `${log.resource}.${log.verb}`
            },
            process: {
              label:
                log.contextProject && log.contextProcess ? (
                  <a
                    href={
                      log.contextProcessSection && log.contextProcessSection.publicId
                        ? `/project/${log.contextProject.publicId}/process/${log.contextProcess.publicId}?section=${log.contextProcessSection.publicId}`
                        : `/project/${log.contextProject.publicId}/process/${log.contextProcess.publicId}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {log.contextProcess.name}
                  </a>
                ) : (
                  'n/a'
                ),
              value: log.contextProcess ? log.contextProcess.name : ''
            },
            processSection: {
              label: log.contextProcessSection ? log.contextProcessSection.name : 'n/a',
              value: log.contextProcessSection ? log.contextProcessSection.name : ''
            },
            table: {
              label: log.contextTable?.publicId ? (
                <a
                  href={`/project/${log.contextProject?.publicId}/table/${log.contextTable?.publicId}/view/${
                    log.contextTableView?.publicId ? log.contextTableView?.publicId : log.contextTable?.defaultViewId
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {log.contextTable?.name}
                </a>
              ) : (
                'n/a'
              ),
              value: log.contextTable ? log.contextTable.name : ''
            },
            column: {
              label: log.contextTableColumn ? log.contextTableColumn.name : 'n/a',
              value: log.contextTableColumn ? log.contextTableColumn.name : ''
            },
            change: {
              label:
                log.change && log.change !== 'null' && log.change !== '{}' ? (
                  <div
                    className="cursor-pointer hover-bg-light-grey transition-all rounded"
                    style={{ padding: '5px', maxWidth: 'fit-content' }}
                    title={log.change}
                    onClick={() => handleChangeCopy(log.change ? log.change : '')}
                  >
                    <Copy />
                  </div>
                ) : (
                  ''
                ),
              value: ''
            }
          }
        })}
        include={[
          { header: 'User', id: 'name' },
          { header: `${capitaliseFirstLetter(PROJECT)}`, id: 'project' },
          { header: 'Date', id: 'createdAt' },
          { header: 'Action', id: 'action' },
          { header: 'Document', id: 'process' },
          { header: 'Document Section', id: 'processSection' },
          { header: 'Table', id: 'table' },
          { header: 'Column', id: 'column' },
          { header: 'Change', id: 'change' }
        ]}
        defaultSort={'createdAt'}
        defaultSortAscending={true}
        sort={false}
        loading={false}
      />
      {!noMoreLogs && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button
            style={{ width: '150px' }}
            onClick={() => setConfig({ ...config, page: config.page + 1 })}
            isLoading={loading}
          >
            Get More Logs
          </Button>
        </div>
      )}
    </div>
  )
}

export default Audit
