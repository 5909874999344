import React, { useEffect, useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import api from 'helpers/api'
import { IProject, ITableViewWithColumns } from 'types'
import Select from 'components/select'
import Button from 'components/button'
import useApplicationStore from 'hooks/application'
import useProject from 'hooks/project'
import { PROJECT } from 'app-constants'
import { SpreadsheetReducerActions } from 'components/spreadsheet/types'
import { capitaliseFirstLetter } from 'helpers/utils'
interface DuplicateProps extends ModalProps {
  type: 'table' | 'hub' | 'process' | 'view'
  publicId: string
  parentPublicId?: string
  setSpreadsheetData?: React.Dispatch<SpreadsheetReducerActions>
}

const Duplicate: React.FC<DuplicateProps> = ({
  id,
  open,
  setOpen,
  type,
  publicId,
  parentPublicId,
  setSpreadsheetData
}) => {
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()

  const [projects, setProjects] = useState<IProject[]>()
  const [targetProjectId, setTargetProjectId] = useState<string>()
  const [duplicateTables, setDuplicateTables] = useState<boolean>(id === 'use-template' ? true : false)
  const [duplicatePermissions, setDuplicatePermissions] = useState<boolean>(true)

  useEffect(() => {
    if (project && project.publicId !== '') {
      setTargetProjectId(project.publicId)
    }
  }, [project])

  useEffect(() => {
    api
      .getOwnerProjects()
      .then((response) => {
        if (response && response.data) {
          setProjects(
            response.data
              .filter((project: IProject) => !project.isDeleted)
              .sort((a: IProject, b: IProject) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
          )
        }
      })
      .catch((error) => {
        displayErrorMessage(error)
      })
  }, [])

  const duplicate = () => {
    if (type === PROJECT) {
      api
        .duplicateProject({ publicId, duplicatePermissions: true })
        .then(() => {
          setSnackbarMessage({
            status: 'success',
            message: 'Duplication has started. You will receive an email once it has been complete.'
          })
          setOpen(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
        })
      return
    }

    if (targetProjectId) {
      if (type === 'table') {
        api
          .duplicateTable({
            publicId,
            targetProjectId,
            duplicatePermissions,
            duplicateTables
          })
          .then(() => {
            setSnackbarMessage({
              status: 'success',
              message: 'Duplication has started. You will receive an email once it has been complete.'
            })
            setOpen(false)
          })
          .catch((error) => {
            displayErrorMessage(error)
          })
      } else if (type === 'process') {
        api
          .duplicateProcess({
            publicId,
            targetProjectId,
            duplicatePermissions,
            duplicateTables
          })
          .then(() => {
            setSnackbarMessage({
              status: 'success',
              message: 'Duplication has started. You will receive an email once it has been complete.'
            })
            setOpen(false)
          })
          .catch((error) => {
            displayErrorMessage(error)
          })
      } else if (type === 'view') {
        if (parentPublicId === undefined || setSpreadsheetData === undefined) {
          setSnackbarMessage({ status: 'error' })
        }
        api
          .duplicateView(parentPublicId!, {
            publicId,
            duplicatePermissions
          })
          .then((response) => {
            setSpreadsheetData!({ type: 'ADD_VIEW', view: response.data as ITableViewWithColumns })
            setSnackbarMessage({
              status: 'success',
              message: 'The view has been successfully duplicated.'
            })
            setOpen(false)
          })
          .catch((error) => {
            displayErrorMessage(error)
          })
      }
    }
  }

  const typeName = type === 'table' ? 'table' : type === 'process' ? 'document' : type

  return (
    <Modal
      id={id}
      open={open}
      setOpen={setOpen}
      title={id === 'use-template' ? 'Use Template' : `Duplicate ${capitaliseFirstLetter(typeName)}`}
    >
      {(type === 'table' || type === 'process') && (
        <div>
          <div style={{ marginBottom: '20px' }}>
            {id === 'use-template'
              ? `Please select the ${PROJECT} you want to use this template in. Only projects in which you are
            'administrator' or 'owner' will be shown.`
              : `Please select the ${PROJECT} you want to duplicate this ${typeName} to. Only projects in which you are
            'administrator' or 'owner' will be shown.`}
          </div>
          <Select
            options={
              projects
                ? projects.map((project) => {
                    return { value: project.publicId, label: project.name }
                  })
                : []
            }
            optionsSelected={targetProjectId ? [targetProjectId] : []}
            onOptionClick={(option: string) => setTargetProjectId(option)}
            loading={projects === undefined}
          />
        </div>
      )}
      {type === 'hub' ? (
        <div>
          <div style={{ marginBottom: '20px' }}>
            All tables, documents, {PROJECT} settings and webhooks will be duplicated into a new {PROJECT}.
          </div>
        </div>
      ) : (
        <div>
          <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold" style={{ marginTop: '30px' }}>
            Advanced Options
          </div>
          <div className="flex items-center justify-center" style={{ marginTop: '20px' }}>
            <div>Do you want to duplicate all linked tables?</div>
            <select
              className="ml-auto"
              style={{ width: '150px' }}
              value={duplicateTables ? 'Yes' : 'No'}
              onChange={(event) => {
                if (event.target.value === 'Yes') {
                  setDuplicateTables(true)
                } else {
                  setDuplicateTables(false)
                }
              }}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="flex items-center justify-center" style={{ marginTop: '20px' }}>
            <div>Should the duplicate resource have the same permissions?</div>
            <select
              className="ml-auto"
              style={{ width: '150px' }}
              value={duplicatePermissions ? 'Yes' : 'No'}
              onChange={(event) => {
                if (event.target.value === 'Yes') {
                  setDuplicatePermissions(true)
                } else {
                  setDuplicatePermissions(false)
                }
              }}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
      )}

      <div className="flex justify-end" style={{ marginTop: '30px' }}>
        <Button internalType="danger" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          internalType="accept"
          onClick={() => duplicate()}
          style={{ marginLeft: '10px' }}
          disabled={targetProjectId === undefined && ['process', 'table'].includes(type)}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

export default Duplicate
