import React, { useState } from 'react'
import Menu, { MenuProps } from 'components/menu'
import { ITableViewColumn, ITableViewSort } from 'types'
import { sortDirections } from 'components/spreadsheet/types'
import { Cross, Drag } from 'components/icons'
import {
  TEXT_COLUMN_TYPES,
  NUMBER_COLUMN_TYPES,
  DATE_COLUMN_TYPES,
  USER_COLUMN_TYPES,
  BLOCK_SORT_BY,
  COLUMN_NAME
} from 'components/spreadsheet/constants/const'
import { useApplicationStore } from 'hooks/application'
import IconSelector from 'components/spreadsheet/components/cell/components/icon'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import Select from 'components/select'

const SortMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const [dragOver, setDragOver] = useState<number>(-1)
  const sortSettings = spreadsheetData.userConfiguration.sortSettings
  const noOfSorts = sortSettings.length
  const { setSnackbarMessage } = useApplicationStore()

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    const id = event.currentTarget.id
    event.dataTransfer.setData('sortNumber', id.split('-')[1])
    event.dataTransfer.effectAllowed = 'move'
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    const id = event.currentTarget.id
    setDragOver(Number(id.split('-')[1]))
    event.dataTransfer.dropEffect = 'move'
  }

  const handleOnDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const draggedSortNumber = event.dataTransfer.getData('sortNumber')
    if (draggedSortNumber !== '' && Number(draggedSortNumber) !== dragOver)
      setSpreadsheetData({
        type: 'CHANGE_SORT_ORDER',
        existingIndex: Number(draggedSortNumber),
        newIndex: dragOver
      })
    setDragOver(-1)
  }

  const handleAddColumnSort = (columnId: string) => {
    const column = spreadsheetData.viewDetails.columns.find((column) => column.publicId === columnId)
    if (column) {
      const columnKind = column.kind
      const columnName = column.name
      if (BLOCK_SORT_BY.includes(columnKind)) {
        setSnackbarMessage({ status: 'error', message: `Sorting columns of type ${columnKind} is not allowed ` })
        return
      }
      const sortExists = !!sortSettings.find((sortSetting) => sortSetting.columnId === columnId)
      if (!sortExists) {
        setSpreadsheetData({ type: 'ADD_SORT', columnId, columnName })
      } else setSnackbarMessage({ status: 'error', message: `You are already sorting by that ${COLUMN_NAME}.` })
    }
  }

  const handleDeleteColumnSort = (columnId: string) => {
    setSpreadsheetData({ type: 'DELETE_SORT', columnId })
  }

  const handleChangeDirection = (columnId: string, newDirection: sortDirections) => {
    setSpreadsheetData({ type: 'CHANGE_SORT_DIRECTION', columnId, newDirection })
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width} zIndex={3000}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '10px' }}>
        {noOfSorts === 0 && (
          <div>
            <div className="font-bold truncate" style={{ padding: '3px 10px', marginBottom: '10px' }}>
              You have not created a sort yet.
            </div>
            <div
              className="truncate"
              style={{ padding: '5px 10px', marginBottom: '20px', marginTop: '20px' }}
            >{`Sort allows you to sort rows by a particular ${COLUMN_NAME}.`}</div>
          </div>
        )}

        {noOfSorts > 0 && (
          <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <li>
              {sortSettings.map((sort: ITableViewSort, sortNumber: number) => {
                const column = spreadsheetData.viewDetails.columns.find(
                  (column: ITableViewColumn) => column.publicId === sort.columnId
                )

                return (
                  <div
                    key={sortNumber}
                    id={`sort-${sortNumber}`}
                    className="flex items-center rounded"
                    style={{ padding: '10px', height: '50px' }}
                    draggable={!spreadsheetData.refreshing && noOfSorts > 1}
                    onDragStart={handleDragStart}
                    onDragOver={handleDragOver}
                    onDrop={handleOnDrop}
                    onDragEnter={handleDragEnter}
                  >
                    <div
                      className="flex items-center cursor-pointer text-secondary"
                      style={{ marginRight: '10px' }}
                      onClick={() => {
                        if (!spreadsheetData.refreshing) handleDeleteColumnSort(sort.columnId)
                      }}
                    >
                      <Cross />
                    </div>
                    <div className="sort-item-text">
                      {sortNumber === 0 && `Sort by`}
                      {sortNumber > 0 && `then by`}
                    </div>

                    <div
                      className="flex items-center bg-light-grey rounded"
                      style={{
                        width: '130px',
                        height: '38px',
                        marginRight: '15px',
                        padding: '5px',
                        marginLeft: '10px'
                      }}
                    >
                      <IconSelector kind={column ? column.kind : 'text'} />
                      <div className="truncate" style={{ marginLeft: '10px' }}>
                        {sort.columnName}
                      </div>
                    </div>

                    <div style={{ marginRight: '15px' }}>from</div>

                    <div
                      className="flex items-center bg-light-grey rounded"
                      style={{ marginLeft: noOfSorts <= 1 ? 'auto' : '0px', width: '160px', padding: '5px' }}
                    >
                      <div
                        className={`flex items-center justify-center rounded border-1px border-solid border-transparent cursor-pointer 
                          ${sort.direction === sortDirections.asc ? 'bg-grey' : ''}`}
                        style={{ marginRight: '10px', padding: '5px', flex: 1 }}
                        onClick={() => {
                          if (!spreadsheetData.refreshing && sort.direction !== sortDirections.asc) {
                            handleChangeDirection(sort.columnId, sortDirections.asc)
                          }
                        }}
                      >
                        {column && TEXT_COLUMN_TYPES.includes(column.kind) && `A → Z`}
                        {column && NUMBER_COLUMN_TYPES.includes(column.kind) && `1 → 9`}
                        {column && DATE_COLUMN_TYPES.includes(column.kind) && `Jan→Dec`}
                        {column && USER_COLUMN_TYPES.includes(column.kind) && `A → Z`}
                        {column && 'select' === column.kind && `A → Z`}
                        {column && 'link' === column.kind && `A → Z`}
                        {column && 'vote' === column.kind && `👎 → 👍`}
                        {column && 'checkbox' === column.kind && `🔲 → ☑️`}
                      </div>
                      <div
                        className={`flex items-center justify-center rounded border-1px border-solid border-transparent cursor-pointer 
                        ${sort.direction === sortDirections.desc ? 'bg-grey' : ''}`}
                        style={{ padding: '5px', flex: 1 }}
                        onClick={() => {
                          if (!spreadsheetData.refreshing && sort.direction !== sortDirections.desc) {
                            handleChangeDirection(sort.columnId, sortDirections.desc)
                          }
                        }}
                      >
                        {column && TEXT_COLUMN_TYPES.includes(column.kind) && `Z → A`}
                        {column && NUMBER_COLUMN_TYPES.includes(column.kind) && `9 → 1`}
                        {column && DATE_COLUMN_TYPES.includes(column.kind) && `Dec→Jan`}
                        {column && USER_COLUMN_TYPES.includes(column.kind) && `Z → A`}
                        {column && 'select' === column.kind && `Z → A`}
                        {column && 'link' === column.kind && `Z → A`}
                        {column && 'vote' === column.kind && `👍 → 👎`}
                        {column && 'checkbox' === column.kind && `☑️ → 🔲`}
                      </div>
                    </div>

                    {noOfSorts > 1 && (
                      <div className="flex items-center ml-auto cursor-grab text-secondary">
                        <Drag />
                      </div>
                    )}
                  </div>
                )
              })}
            </li>
          </div>
        )}
        <div className="flex items-center rounded" style={{ padding: '10px', height: '80px', marginTop: '30px' }}>
          <span style={{ minWidth: '150px', marginRight: '10px' }}>Column To Sort:</span>
          <div className="w-full">
            <Select
              options={spreadsheetData.viewDetails.columns
                .filter((column) => !sortSettings.find((sortSetting) => sortSetting.columnId === column.publicId))
                .map((column) => {
                  return { value: column.publicId, label: column.name }
                })}
              onOptionClick={(option) => handleAddColumnSort(option)}
              info="Only columns that have not been sorted yet can be selected."
            />
          </div>
        </div>
      </div>
    </Menu>
  )
}

export default React.memo(SortMenu)
