import {
  arrayMove,
  calculateFrozenColumnWidths,
  convertToApiColour,
  convertToApiFilter,
  getColumnsPerUserRole
} from 'components/spreadsheet/helpers/functions'
import { ISpreadsheetData, sortDirections, ICellUpdateAction } from 'components/spreadsheet/types'
import {
  ITableColumn,
  ITableRow,
  ITableViewColumn,
  ITableViewSort,
  ITableViewFilter,
  ICommentThreadStats,
  EditorContent,
  ICellValue,
  ITableViewWithColumns,
  ITableViewGroup,
  ITableViewHiddenColumn,
  ITable,
  ITableViewColour,
  ITableViewChart
} from 'types'
import { SpreadsheetReducerActions } from 'components/spreadsheet/types'
import { getGroupingKeysForColumn } from 'components/spreadsheet/helpers/grouping'
import { BLOCK_SEARCH_BY, INITIAL_SELECTED_SEARCH_CELL } from 'components/spreadsheet/constants/const'
import { ITableViewHistory } from './actions'

const changeColumnWidth = (state: ISpreadsheetData, columnId: string, columnWidth: number) => {
  let newColumns = [...state.viewDetails.columns]

  newColumns = newColumns.map((column: ITableViewColumn) => {
    if (column.publicId === columnId) return { ...column, width: columnWidth }
    else return { ...column }
  })

  const newColumnWidths = calculateFrozenColumnWidths(
    newColumns,
    state.viewDetails.frozenIndex,
    state.userConfiguration.hiddenColumns
  )
  return { ...state, columnWidths: newColumnWidths, viewDetails: { ...state.viewDetails, columns: newColumns } }
}

const changeColumnAggregate = (state: ISpreadsheetData, columnId: string, aggregate: number) => {
  let newColumns = [...state.viewDetails.columns]

  newColumns = newColumns.map((column: ITableViewColumn) => {
    if (column.publicId === columnId) return { ...column, aggregate }
    else return { ...column }
  })

  return { ...state, viewDetails: { ...state.viewDetails, columns: newColumns } }
}

const createTableColumn = (state: ISpreadsheetData, column: ITableViewColumn) => {
  const newColumns = [...state.viewDetails.columns]
  newColumns.splice(column.sortOrder, 0, column)
  newColumns.forEach((column, index) => (column.sortOrder = index))
  const newColumnWidths = calculateFrozenColumnWidths(
    newColumns,
    state.viewDetails.frozenIndex,
    state.userConfiguration.hiddenColumns
  )

  // add column to default and current view, hide all others
  const newTableView = [...state.tableView].map((view: ITableViewWithColumns) => {
    const newView = { ...view }
    if (newView.hiddenColumns && view.publicId !== state.activeTableView && !view.isDefault) {
      newView.hiddenColumns.push({ columnId: column.publicId, columnName: column.name })
    } else {
      newView.columns.push(column)
    }
    return newView
  })

  // Add new column to column values count
  const newColumnValuesCount = { ...state.columnValuesCount }
  newColumnValuesCount[column.publicId] = {}

  return {
    ...state,
    tableDetails: {
      ...state.tableDetails,
      columns: [
        ...state.tableDetails.columns,
        {
          aggregate: column.aggregate,
          description: column.description,
          isIndexed: column.isIndexed,
          viewpointSynced: column.viewpointSynced,
          viewpointRfisSynced: column.viewpointRfisSynced,
          autodeskBim360Synced: column.autodeskBim360Synced,
          autodeskBim360ChecklistsSynced: column.autodeskBim360ChecklistsSynced,
          procoreSynced: column.procoreSynced,
          aconexSynced: column.aconexSynced,
          aconexWorkflowsSynced: column.aconexWorkflowsSynced,
          mortaSynced: column.mortaSynced,
          reviztoIssuesSynced: column.reviztoIssuesSynced,
          asiteDocumentsSynced: column.asiteDocumentsSynced,
          isJoined: column.isJoined,
          kind: column.kind,
          name: column.name,
          publicId: column.publicId,
          script: column.script,
          scriptEnabled: column.scriptEnabled,
          width: column.width,
          thousandSeparator: column.thousandSeparator,
          decimalPlaces: column.decimalPlaces,
          dateFormat: column.dateFormat,
          headerBackgroundColor: column.headerBackgroundColor,
          headerTextColor: column.headerTextColor,
          exportWidth: column.exportWidth
        }
      ]
    },
    refreshing: false,
    columnWidths: newColumnWidths,
    tableView: newTableView,
    columnValuesCount: newColumnValuesCount,
    viewDetails: { ...state.viewDetails, columns: newColumns }
  }
}

const deleteTableColumn = (state: ISpreadsheetData, columnId: string) => {
  // Delete column from view
  const newColumnsView = [...state.viewDetails.columns].filter(
    (column: ITableViewColumn) => column.publicId !== columnId
  )
  newColumnsView.forEach((column, index) => (column.sortOrder = index))

  // delete column from table
  const newColumnsTable = [...state.tableDetails.columns].filter((column: ITableColumn) => column.publicId !== columnId)

  // delete column from all views
  const newColumnsTableView = [...state.tableView].map((view: ITableViewWithColumns) => {
    const newView = { ...view }
    newView.columns = view.columns.filter((column) => column.publicId !== columnId)
    newView.filterSettings = view.filterSettings.filter((setting: ITableViewFilter) => setting.columnId !== columnId)
    newView.sortSettings = view.sortSettings.filter((setting: ITableViewSort) => setting.columnId !== columnId)
    newView.groupSettings = view.groupSettings.filter((setting: ITableViewGroup) => setting.columnId !== columnId)
    newView.colourSettings = view.colourSettings.filter((setting: ITableViewColour) => setting.columnId !== columnId)
    newView.hiddenColumns =
      view.hiddenColumns && view.hiddenColumns.filter((column: ITableViewHiddenColumn) => column.columnId !== columnId)
    return newView
  })

  // remove column from hidden columns if exists
  const newHiddenColumns = [...state.userConfiguration.hiddenColumns].filter(
    (hiddenColumnId: string) => hiddenColumnId !== columnId
  )

  // remove column from sorting settings if deleted column is present
  const newSortSettings = [...state.userConfiguration.sortSettings].filter(
    (sortSetting) => sortSetting.columnId !== columnId
  )

  // remove column from filter settings  if deleted column is present
  const newFilterSettings = [...state.userConfiguration.filterSettings].filter(
    (filterSetting) => filterSetting.columnId !== columnId
  )
  // remove column from groupping settings  if deleted column is present
  const newGroupSettings = [...state.userConfiguration.groupSettings].filter(
    (groupSetting) => groupSetting.columnId !== columnId
  )
  // remove column from colour settings  if deleted column is present
  const newColourSettings = [...state.userConfiguration.colourSettings].filter(
    (colourSetting) => colourSetting.columnId !== columnId
  )

  // remove column from data join  if deleted column is present
  const newJoins = state.tableDetails.joins.map((join) => {
    const newDataColumns = join.dataColumns.filter((dataColumn) => dataColumn.targetColumnId !== columnId)
    join.dataColumns = newDataColumns
    return join
  })

  // remove the column from column values count
  const newColumnValuesCount = { ...state.columnValuesCount }
  delete newColumnValuesCount[columnId]

  const newColumnWidths = calculateFrozenColumnWidths(newColumnsView, state.viewDetails.frozenIndex, newHiddenColumns)
  return {
    ...state,
    userConfiguration: {
      ...state.userConfiguration,
      hiddenColumns: newHiddenColumns,
      sortSettings: newSortSettings,
      filterSettings: newFilterSettings,
      colourSettings: newColourSettings,
      groupSettings: newGroupSettings
    },
    tableDetails: { ...state.tableDetails, columns: newColumnsTable, joins: newJoins },
    tableView: newColumnsTableView,
    refreshing: false,
    columnWidths: newColumnWidths,
    columnValuesCount: newColumnValuesCount,
    viewDetails: { ...state.viewDetails, columns: newColumnsView }
  }
}

const changeColumnOrder = (state: ISpreadsheetData, columnId: string, newIndex: number) => {
  const newColumns = [...state.viewDetails.columns]
  const columnIndex = newColumns.findIndex((column: ITableViewColumn) => column.publicId === columnId)
  if (columnIndex !== -1) {
    arrayMove(newColumns, columnIndex, newIndex)
  }

  const newColumnWidths = calculateFrozenColumnWidths(
    newColumns,
    state.viewDetails.frozenIndex,
    state.userConfiguration.hiddenColumns
  )

  return {
    ...state,
    columnWidths: newColumnWidths,
    viewDetails: {
      ...state.viewDetails,
      columns: newColumns
    },
    userConfiguration: {
      ...state.userConfiguration,
      unsavedChanges: state.isAdmin
    }
  }
}

const changeRowOrder = (state: ISpreadsheetData, rowId: string, newIndex: number, newSortOrder: number) => {
  const newRows = [...state.rows]
  const rowIndex = newRows.findIndex((row: ITableRow) => row.publicId === rowId)
  if (rowIndex !== -1) {
    newRows[rowIndex].sortOrder = newSortOrder
    arrayMove(newRows, rowIndex, newIndex)
  }

  return {
    ...state,
    rows: newRows
  }
}

const addSort = (state: ISpreadsheetData, columnId: string, columnName: string) => {
  const newUserConfiguration = {
    ...state.userConfiguration,
    unsavedChanges: state.isAdmin,
    sortSettings: [
      ...state.userConfiguration.sortSettings,
      {
        columnId,
        columnName,
        direction: sortDirections.asc
      }
    ]
  }

  return {
    ...state,
    userConfiguration: newUserConfiguration
  }
}

const deleteSort = (state: ISpreadsheetData, columnId: string) => {
  const newUserConfiguration = { ...state.userConfiguration, unsavedChanges: state.isAdmin }
  newUserConfiguration.sortSettings = newUserConfiguration.sortSettings.filter(
    (sort: ITableViewSort) => sort.columnId !== columnId
  )
  return {
    ...state,
    userConfiguration: newUserConfiguration
  }
}

const changeSortDirection = (state: ISpreadsheetData, columnId: string, newDirection: sortDirections) => {
  const newUserConfiguration = {
    ...state.userConfiguration,
    sortSettings: state.userConfiguration.sortSettings.map((sort: ITableViewSort) =>
      sort.columnId === columnId ? { ...sort, direction: newDirection } : sort
    ),
    unsavedChanges: state.isAdmin
  }

  return {
    ...state,
    userConfiguration: newUserConfiguration
  }
}

const changeSortOrder = (state: ISpreadsheetData, existingIndex: number, newIndex: number) => {
  const newSortSettings = [...state.userConfiguration.sortSettings]
  arrayMove(newSortSettings, existingIndex, newIndex)
  return {
    ...state,
    userConfiguration: {
      ...state.userConfiguration,
      sortSettings: newSortSettings,
      unsavedChanges: state.isAdmin
    }
  }
}

const showColumn = (state: ISpreadsheetData, columnId: string) => {
  let newHiddenColumns = [...state.userConfiguration.hiddenColumns]
  newHiddenColumns = newHiddenColumns.filter((columnPublicId) => columnPublicId !== columnId)

  // When showing a view as admin it is possible that the column is not part of the view anymore
  // This could happen after saving current view.
  const newViewColumns = [...state.viewDetails.columns]
  const foundInView = state.viewDetails.columns.find((col) => col.publicId === columnId)
  if (state.isAdmin && !foundInView) {
    const foundInTable = state.tableDetails.columns.find((col) => col.publicId === columnId)
    const sortOrder =
      state.viewDetails.columns.length > 0
        ? state.viewDetails.columns[state.viewDetails.columns.length - 1].sortOrder + 1
        : 0
    if (foundInTable) {
      // If you are keeping validations in sync you need to find
      // the column in another view
      let sourceColumn: ITableViewColumn | undefined
      for (let i = 0; i < state.tableView.length; i++) {
        const view = state.tableView[i]
        sourceColumn = view.columns.find((col) => col.publicId === columnId)
        break
      }

      newViewColumns.push({
        ...foundInTable,
        locked: false,
        sortOrder: sortOrder,
        required: false,
        stringValidation:
          sourceColumn && state.tableDetails.keepValidationsInSync ? sourceColumn.stringValidation : null,
        validationMessage:
          sourceColumn && state.tableDetails.keepValidationsInSync ? sourceColumn.validationMessage : null,
        hardValidation: sourceColumn && state.tableDetails.keepValidationsInSync ? sourceColumn.hardValidation : false,
        validationNoBlanks:
          sourceColumn && state.tableDetails.keepValidationsInSync ? sourceColumn.validationNoBlanks : false,
        validationNoDuplicates:
          sourceColumn && state.tableDetails.keepValidationsInSync ? sourceColumn.validationNoDuplicates : false
      })
    }
  }

  const newColumnWidths = calculateFrozenColumnWidths(newViewColumns, state.viewDetails.frozenIndex, newHiddenColumns)

  return {
    ...state,
    columnWidths: newColumnWidths,
    viewDetails: {
      ...state.viewDetails,
      columns: newViewColumns.map((column: ITableViewColumn, index: number) => {
        return { ...column, sortOrder: index }
      })
    },
    userConfiguration: {
      ...state.userConfiguration,
      hiddenColumns: newHiddenColumns,
      unsavedChanges: state.isAdmin
    }
  }
}

const hideColumn = (state: ISpreadsheetData, columnId: string) => {
  const newHiddenColumns = [...state.userConfiguration.hiddenColumns, columnId]
  const newColumnWidths = calculateFrozenColumnWidths(
    state.viewDetails.columns,
    state.viewDetails.frozenIndex,
    newHiddenColumns
  )

  return {
    ...state,
    columnWidths: newColumnWidths,
    userConfiguration: {
      ...state.userConfiguration,
      hiddenColumns: newHiddenColumns,
      unsavedChanges: state.isAdmin
    }
  }
}

const showAllColumns = (state: ISpreadsheetData) => {
  const newHiddenColumns: string[] = []
  const newViewColumns = getColumnsPerUserRole(state).map((col, index) => {
    return {
      ...col,
      locked: false,
      sortOrder: index,
      required: false,
      stringValidation: null,
      validationMessage: null,
      hardValidation: false,
      validationNoBlanks: false,
      validationNoDuplicates: false
    }
  })
  const newColumnWidths = calculateFrozenColumnWidths(newViewColumns, state.viewDetails.frozenIndex, newHiddenColumns)
  return {
    ...state,
    columnWidths: newColumnWidths,
    viewDetails: { ...state.viewDetails, columns: newViewColumns },
    userConfiguration: {
      ...state.userConfiguration,
      hiddenColumns: newHiddenColumns,
      unsavedChanges: state.isAdmin
    }
  }
}

const hideAllColumns = (state: ISpreadsheetData) => {
  let newHiddenColumns: string[] = []

  //  Filter out columns that are part of a group
  newHiddenColumns = (state.isAdmin ? state.tableDetails.columns : state.viewDetails.columns)
    .filter((column) => !state.userConfiguration.groupSettings.find((group) => group.columnId === column.publicId))
    .map((column) => column.publicId)

  const newColumnWidths = calculateFrozenColumnWidths(
    state.viewDetails.columns,
    state.viewDetails.frozenIndex,
    newHiddenColumns
  )

  return {
    ...state,
    columnWidths: newColumnWidths,
    userConfiguration: {
      ...state.userConfiguration,
      hiddenColumns: newHiddenColumns,
      unsavedChanges: state.isAdmin
    }
  }
}

const lockColumn = (state: ISpreadsheetData, columnId: string) => {
  const newViewDetails = {
    ...state.viewDetails,
    columns: state.viewDetails.columns.map((column: ITableViewColumn) => {
      if (column.publicId === columnId) {
        const newColumn = Object.assign({}, column)
        newColumn.locked = true
        return newColumn
      } else {
        return column
      }
    })
  }
  return {
    ...state,
    viewDetails: newViewDetails,
    userConfiguration: { ...state.userConfiguration, unsavedChanges: state.isAdmin }
  }
}

const unlockColumn = (state: ISpreadsheetData, columnId: string) => {
  const newViewDetails = {
    ...state.viewDetails,
    columns: state.viewDetails.columns.map((column: ITableViewColumn) => {
      if (column.publicId === columnId) {
        const newColumn = Object.assign({}, column)
        newColumn.locked = false
        return newColumn
      } else {
        return column
      }
    })
  }
  return {
    ...state,
    viewDetails: newViewDetails,
    userConfiguration: { ...state.userConfiguration, unsavedChanges: state.isAdmin }
  }
}

const unlockAllColumns = (state: ISpreadsheetData) => {
  const newViewDetails = {
    ...state.viewDetails,
    columns: state.viewDetails.columns.map((column: ITableViewColumn) => {
      const newColumn = Object.assign({}, column)
      newColumn.locked = false
      return newColumn
    })
  }
  return {
    ...state,
    viewDetails: newViewDetails,
    userConfiguration: { ...state.userConfiguration, unsavedChanges: state.isAdmin }
  }
}

const lockAllColumns = (state: ISpreadsheetData) => {
  const newViewDetails = {
    ...state.viewDetails,
    columns: state.viewDetails.columns.map((column: ITableViewColumn) => {
      const newColumn = Object.assign({}, column)
      newColumn.locked = true
      return newColumn
    })
  }
  return {
    ...state,
    viewDetails: newViewDetails,
    userConfiguration: { ...state.userConfiguration, unsavedChanges: state.isAdmin }
  }
}

const requireColumn = (state: ISpreadsheetData, columnId: string) => {
  const newViewDetails = {
    ...state.viewDetails,
    columns: state.viewDetails.columns.map((column: ITableViewColumn) => {
      if (column.publicId === columnId) {
        const newColumn = Object.assign({}, column)
        newColumn.required = true
        return newColumn
      } else {
        return column
      }
    })
  }
  return {
    ...state,
    viewDetails: newViewDetails,
    userConfiguration: { ...state.userConfiguration, unsavedChanges: state.isAdmin }
  }
}

const unrequireColumn = (state: ISpreadsheetData, columnId: string) => {
  const newViewDetails = {
    ...state.viewDetails,
    columns: state.viewDetails.columns.map((column: ITableViewColumn) => {
      if (column.publicId === columnId) {
        const newColumn = Object.assign({}, column)
        newColumn.required = false
        return newColumn
      } else {
        return column
      }
    })
  }
  return {
    ...state,
    viewDetails: newViewDetails,
    userConfiguration: { ...state.userConfiguration, unsavedChanges: state.isAdmin }
  }
}

const unrequireAllColumns = (state: ISpreadsheetData) => {
  const newViewDetails = {
    ...state.viewDetails,
    columns: state.viewDetails.columns.map((column: ITableViewColumn) => {
      const newColumn = Object.assign({}, column)
      newColumn.required = false
      return newColumn
    })
  }
  return {
    ...state,
    viewDetails: newViewDetails,
    userConfiguration: { ...state.userConfiguration, unsavedChanges: state.isAdmin }
  }
}

const requireAllColumns = (state: ISpreadsheetData) => {
  const newViewDetails = {
    ...state.viewDetails,
    columns: state.viewDetails.columns.map((column: ITableViewColumn) => {
      const newColumn = Object.assign({}, column)
      newColumn.required = true
      return newColumn
    })
  }
  return {
    ...state,
    viewDetails: newViewDetails,
    userConfiguration: { ...state.userConfiguration, unsavedChanges: state.isAdmin }
  }
}

const changeDisplayValidationRows = (state: ISpreadsheetData, displayValidationErrorRows: number) => {
  const newViewDetails = { ...state.viewDetails, displayValidationErrorRows }

  return {
    ...state,
    viewDetails: newViewDetails,
    userConfiguration: { ...state.userConfiguration, unsavedChanges: state.isAdmin }
  }
}

const changeDisplayCommentRows = (state: ISpreadsheetData, displayCommentRows: number) => {
  const newViewDetails = { ...state.viewDetails, displayCommentRows }

  return {
    ...state,
    viewDetails: newViewDetails,
    userConfiguration: { ...state.userConfiguration, unsavedChanges: state.isAdmin }
  }
}

const changeCollapsedGroupView = (state: ISpreadsheetData, collapsedGroupView: boolean) => {
  const newViewDetails = { ...state.viewDetails, collapsedGroupView }

  return {
    ...state,
    viewDetails: newViewDetails,
    userConfiguration: { ...state.userConfiguration, unsavedChanges: state.isAdmin }
  }
}

const changeUnpackMultiselectGroupView = (state: ISpreadsheetData, unpackMultiselectGroupView: boolean) => {
  const newViewDetails = { ...state.viewDetails, unpackMultiselectGroupView }

  return {
    ...state,
    viewDetails: newViewDetails,
    userConfiguration: { ...state.userConfiguration, unsavedChanges: state.isAdmin }
  }
}

const changeFrozenIndex = (state: ISpreadsheetData, frozenIndex: number) => {
  const newViewDetails = { ...state.viewDetails, frozenIndex: frozenIndex }
  const newColumnWidths = calculateFrozenColumnWidths(
    state.viewDetails.columns,
    frozenIndex,
    state.userConfiguration.hiddenColumns
  )

  return {
    ...state,
    viewDetails: newViewDetails,
    columnWidths: newColumnWidths,
    userConfiguration: { ...state.userConfiguration, unsavedChanges: state.isAdmin }
  }
}

const updateTable = (
  state: ISpreadsheetData,
  field:
    | 'name'
    | 'type'
    | 'logo'
    | 'variables'
    | 'keepValidationsInSync'
    | 'keepColoursInSync'
    | 'allowDuplication'
    | 'isDeleted'
    | 'deletedAt'
    | 'syncHourlyFrequency'
    | 'isSyncing'
    | 'failedSyncAttempts'
    | 'lastSync',
  value: string | string[] | boolean | number | null
) => {
  return {
    ...state,
    tableDetails: {
      ...state.tableDetails,
      name: field === 'name' && typeof value === 'string' ? value : state.tableDetails.name,
      type: field === 'type' && typeof value === 'string' ? value : state.tableDetails.type,
      logo: field === 'logo' && typeof value === 'string' ? value : state.tableDetails.logo,
      variables:
        field === 'variables' && typeof value !== 'string' ? (value as string[]) : state.tableDetails.variables,
      keepValidationsInSync:
        field === 'keepValidationsInSync' && typeof value === 'boolean'
          ? value
          : state.tableDetails.keepValidationsInSync,
      keepColoursInSync:
        field === 'keepColoursInSync' && typeof value === 'boolean' ? value : state.tableDetails.keepColoursInSync,
      allowDuplication:
        field === 'allowDuplication' && typeof value === 'boolean' ? value : state.tableDetails.allowDuplication,
      isDeleted: field === 'isDeleted' && typeof value === 'boolean' ? value : state.tableDetails.isDeleted,
      deletedAt:
        field === 'deletedAt' && (typeof value === 'string' || value === null) ? value : state.tableDetails.deletedAt,
      syncHourlyFrequency:
        field === 'syncHourlyFrequency' && typeof value === 'number' ? value : state.tableDetails.syncHourlyFrequency,
      isSyncing: field === 'isSyncing' && typeof value === 'boolean' ? value : state.tableDetails.isSyncing,
      failedSyncAttempts:
        field === 'failedSyncAttempts' && typeof value === 'number' ? value : state.tableDetails.failedSyncAttempts,
      lastSync: field === 'lastSync' && typeof value === 'string' ? value : state.tableDetails.lastSync
    }
  }
}

const updateTableJoins = (state: ISpreadsheetData, table: ITable) => {
  let newState: ISpreadsheetData = {
    ...state,
    tableDetails: {
      ...state.tableDetails,
      columns: state.tableDetails.columns.map((col) => {
        return { ...col }
      })
    },
    viewDetails: {
      ...state.viewDetails,
      columns: state.viewDetails.columns.map((col) => {
        return { ...col }
      })
    }
  }
  const hiddenColumns = state.isAdmin
    ? state.userConfiguration.hiddenColumns
    : state.viewDetails.hiddenColumns.map((hiddenColumn) => hiddenColumn.columnId)

  // Filter out all joined columns that have been deleted
  const joinedColumns = table.columns.filter((col) => col.isJoined)
  const newHiddenColumns = hiddenColumns.filter(
    (hiddenColumn) => !!table.columns.find((column) => column.publicId === hiddenColumn)
  )
  const newJoinedColumns = joinedColumns.filter((col) => !hiddenColumns.includes(col.publicId))
  // Remove all joined columns from table
  state.viewDetails.columns
    .filter((col) => col.isJoined)
    .filter((viewColumn) => !newJoinedColumns.find((col) => viewColumn.publicId === col.publicId))
    .forEach((col) => {
      newState = deleteTableColumn(newState, col.publicId)
    })

  // Add all joined columns from updated table into the view
  const maxSortOrder = Math.max(...newState.viewDetails.columns.map((col: ITableViewColumn) => col.sortOrder))
  newJoinedColumns
    .filter((col) => !newState.viewDetails.columns.find((viewColumn) => viewColumn.publicId === col.publicId))
    .forEach((col, index) => {
      const newCol = {
        locked: false,
        sortOrder: index + maxSortOrder + 1,
        required: true,
        ...col,
        stringValidation: null,
        validationMessage: null,
        validationNoBlanks: false,
        validationNoDuplicates: false,
        hardValidation: false
      }
      newState = createTableColumn(newState, newCol)
      newState = showColumn(newState, newCol.publicId)
    })
  let updatedState = {
    ...newState,
    tableDetails: { ...state.tableDetails, joins: table.joins, columns: table.columns },
    userConfiguration: { ...state.userConfiguration, hiddenColumns: newHiddenColumns }
  }
  updatedState = saveCurrentView(updatedState)
  return updatedState
}

const setView = (state: ISpreadsheetData, viewId: string) => {
  const targetView = state.tableView.find((view: ITableViewWithColumns) => view.publicId === viewId)
  const newState = { ...state }

  if (targetView) {
    newState['userConfiguration']['unsavedChanges'] = false
    newState['activeTableView'] = viewId
    newState['viewDetails'] = targetView
    newState['originalViewColumns'] = targetView.columns.map((column: ITableViewColumn) => column.name)
    newState['userConfiguration']['filterSettings'] = newState['viewDetails']['filterSettings']
    newState['userConfiguration']['sortSettings'] = newState['viewDetails']['sortSettings']
    newState['userConfiguration']['groupSettings'] = newState['viewDetails']['groupSettings']
    newState['userConfiguration']['colourSettings'] = newState['viewDetails']['colourSettings']
    newState['userConfiguration']['chartSettings'] = newState['viewDetails']['chartSettings']
    newState['userConfiguration']['hiddenColumns'] = Array.isArray(newState['viewDetails']['hiddenColumns'])
      ? newState['viewDetails']['hiddenColumns'].map((hiddenColumn) => hiddenColumn.columnId)
      : []

    const columnWidths = calculateFrozenColumnWidths(
      targetView['columns'],
      targetView['frozenIndex'],
      newState['userConfiguration']['hiddenColumns']
    )
    newState['columnWidths'] = columnWidths
  }

  return newState
}

const saveCurrentView = (state: ISpreadsheetData) => {
  const newState = { ...state, userConfiguration: { ...state.userConfiguration, unsavedChanges: false } }
  const newView = state['viewDetails']
  newView['columns'] = state['viewDetails'].columns.filter(
    (column: ITableViewColumn) => !newState.userConfiguration.hiddenColumns.includes(column.publicId)
  )
  const missingColumns = newState['tableDetails']['columns']
    .filter((column: ITableColumn) => newState['userConfiguration']['hiddenColumns'].includes(column.publicId))
    .map((column: ITableColumn) => {
      // Keep validation in sync
      // If you are keeping validations in sync you need to find
      // the column in another view
      let sourceColumn: ITableViewColumn | undefined
      for (let i = 0; i < state.tableView.length; i++) {
        const view = state.tableView[i]
        sourceColumn = view.columns.find((col) => col.publicId === column.publicId)
        break
      }

      return {
        ...column,
        locked: false,
        required: false,
        sortOrder: -1,
        stringValidation:
          sourceColumn && state.tableDetails.keepValidationsInSync ? sourceColumn.stringValidation : null,
        validationMessage:
          sourceColumn && state.tableDetails.keepValidationsInSync ? sourceColumn.validationMessage : null,
        hardValidation: sourceColumn && state.tableDetails.keepValidationsInSync ? sourceColumn.hardValidation : false,
        validationNoBlanks:
          sourceColumn && state.tableDetails.keepValidationsInSync ? sourceColumn.validationNoBlanks : false,
        validationNoDuplicates:
          sourceColumn && state.tableDetails.keepValidationsInSync ? sourceColumn.validationNoDuplicates : false
      }
    })

  newView['hiddenColumns'] = missingColumns.map((col) => {
    return { columnId: col.publicId, columnName: col.name }
  })
  newState['tableView'] = state.tableView.map((view: ITableViewWithColumns) =>
    view.publicId === state.viewDetails.publicId ? newView : view
  )
  newView['filterSettings'] = convertToApiFilter(newState.userConfiguration.filterSettings)
  newView['groupSettings'] = newState.userConfiguration.groupSettings
  newView['sortSettings'] = newState.userConfiguration.sortSettings
  newView['colourSettings'] = convertToApiColour(newState.userConfiguration.colourSettings)
  newView['chartSettings'] = newState.userConfiguration.chartSettings

  if (state.tableDetails.keepColoursInSync) {
    newState['tableView'] = newState['tableView'].map((view: ITableViewWithColumns) => {
      return { ...view, colourSettings: convertToApiColour(newState.userConfiguration.colourSettings) }
    })
  }

  return newState
}

const updateView = (
  state: ISpreadsheetData,
  field:
    | 'name'
    | 'description'
    | 'type'
    | 'disableNewRow'
    | 'allowContributorDelete'
    | 'displayValidationErrorRows'
    | 'displayCommentRows'
    | 'collapsedGroupView'
    | 'unpackMultiselectGroupView',
  viewId: string,
  value: string | number | EditorContent | null | boolean
) => {
  const newTableView = state.tableView.map((tableView) =>
    tableView.publicId === viewId
      ? {
          ...tableView,
          name: field === 'name' && typeof value === 'string' ? value : tableView.name,
          description:
            field === 'description' &&
            typeof value !== 'string' &&
            typeof value !== 'number' &&
            typeof value !== 'boolean'
              ? value
              : tableView.description,
          type: field === 'type' && typeof value === 'number' ? value : tableView.type,
          disableNewRow: field === 'disableNewRow' && typeof value === 'boolean' ? value : tableView.disableNewRow,
          allowContributorDelete:
            field === 'allowContributorDelete' && typeof value === 'boolean' ? value : tableView.allowContributorDelete,
          displayValidationErrorRows:
            field === 'displayValidationErrorRows' && typeof value === 'number'
              ? value
              : tableView.displayValidationErrorRows,
          displayCommentRows:
            field === 'displayCommentRows' && typeof value === 'number' ? value : tableView.displayCommentRows,
          collapsedGroupView:
            field === 'collapsedGroupView' && typeof value === 'boolean' ? value : tableView.collapsedGroupView,
          unpackMultiselectGroupView:
            field === 'unpackMultiselectGroupView' && typeof value === 'boolean'
              ? value
              : tableView.unpackMultiselectGroupView
        }
      : tableView
  )

  const newViewDetails = { ...state.viewDetails }
  if (state.viewDetails.publicId === viewId) {
    if (field === 'name' && typeof value === 'string') newViewDetails.name = value
    else if (field === 'type' && typeof value === 'number') newViewDetails.type = value
    else if (field === 'disableNewRow' && typeof value === 'boolean') newViewDetails.disableNewRow = value
    else if (field === 'allowContributorDelete' && typeof value === 'boolean')
      newViewDetails.allowContributorDelete = value
    else if (field === 'displayValidationErrorRows' && typeof value === 'number')
      newViewDetails.displayValidationErrorRows = value
    else if (field === 'displayCommentRows' && typeof value === 'number') newViewDetails.displayCommentRows = value
    else if (field === 'collapsedGroupView' && typeof value === 'boolean') newViewDetails.collapsedGroupView = value
    else if (field === 'unpackMultiselectGroupView' && typeof value === 'boolean') {
      newViewDetails.unpackMultiselectGroupView = value
    } else if (
      field === 'description' &&
      typeof value !== 'string' &&
      typeof value !== 'number' &&
      typeof value !== 'boolean'
    )
      newViewDetails.description = value
  }

  return {
    ...state,
    viewDetails: newViewDetails,
    tableView: newTableView
  }
}

const deleteView = (state: ISpreadsheetData, viewId: string) => {
  const newTableView = state.tableView.filter((tableView) => tableView.publicId !== viewId)
  return {
    ...state,
    tableView: newTableView
  }
}

const addGroup = (state: ISpreadsheetData, columnId: string, columnName: string) => {
  const group = {
    columnId,
    columnName,
    direction: sortDirections.asc
  }
  return {
    ...state,
    userConfiguration: {
      ...state.userConfiguration,
      groupSettings: [...state.userConfiguration.groupSettings, group],
      unsavedChanges: true
    }
  }
}

const addChart = (state: ISpreadsheetData, chartSettings: ITableViewChart) => {
  return {
    ...state,
    userConfiguration: {
      ...state.userConfiguration,
      chartSettings: chartSettings,
      unsavedChanges: true
    }
  }
}

const deleteGroup = (state: ISpreadsheetData, columnId: string) => {
  const newUserConfiguration = { ...state.userConfiguration, unsavedChanges: true }
  newUserConfiguration.groupSettings = newUserConfiguration.groupSettings.filter((sort) => sort.columnId !== columnId)
  return {
    ...state,
    userConfiguration: newUserConfiguration
  }
}

const changeGroupDirection = (state: ISpreadsheetData, columnId: string, newDirection: sortDirections) => {
  const newUserConfiguration = {
    ...state.userConfiguration,
    unsavedChanges: true,
    groupSettings: state.userConfiguration.groupSettings.map((group) =>
      group.columnId === columnId ? { ...group, direction: newDirection } : group
    )
  }

  return {
    ...state,
    userConfiguration: newUserConfiguration
  }
}

const changeGroupOrder = (state: ISpreadsheetData, existingIndex: number, newIndex: number) => {
  const newGroupSettings = [...state.userConfiguration.groupSettings]
  arrayMove(newGroupSettings, existingIndex, newIndex)
  return {
    ...state,
    userConfiguration: { ...state.userConfiguration, groupSettings: newGroupSettings, unsavedChanges: true }
  }
}

const toggleGroupCollapse = (state: ISpreadsheetData, groupId: string) => {
  const newSet = new Set(state.collapsedGroupIds)
  if (newSet.has(groupId)) {
    newSet.delete(groupId)
  } else {
    newSet.add(groupId)
  }

  return { ...state, collapsedGroupIds: newSet }
}

const expandAllGroups = (state: ISpreadsheetData) => {
  const newSet = new Set<string>()
  return { ...state, collapsedGroupIds: newSet, collapsedColumnIds: new Set<string>() }
}

const collapseAllGroups = (state: ISpreadsheetData, groupIds: string[]) => {
  const newSet = new Set(state.collapsedGroupIds)

  for (let i = 0; i < groupIds.length; i++) {
    newSet.add(groupIds[i])
  }

  // This works out the group keys for the column
  const toGroupColumnsIds = state.userConfiguration.groupSettings.map((toGroupColumn) => {
    const column = state.viewDetails.columns.find((c) => c?.publicId === toGroupColumn.columnId)!
    return column.publicId
  })

  return { ...state, collapsedGroupIds: newSet, collapsedColumnIds: new Set<string>(toGroupColumnsIds) }
}

const editCell = (
  state: ISpreadsheetData,
  columnId: string,
  rowId: string,
  value: { [key: string]: ICellValue },
  oldValue?: ICellValue
) => {
  const row = state.rows.find((row: ITableRow) => row.publicId === rowId)
  const newColumnValuesCount = { ...state.columnValuesCount }
  if (row && Object.keys(state.columnValuesCount).length > 0) {
    if (oldValue) {
      const oldValueString = oldValue.toString()

      if (newColumnValuesCount[columnId] && newColumnValuesCount[columnId][oldValueString] !== undefined) {
        newColumnValuesCount[columnId][oldValueString] -= 1
      } else {
        if (!newColumnValuesCount[columnId]) newColumnValuesCount[columnId] = {}
        newColumnValuesCount[columnId][oldValueString] = 0
      }
    }
  }

  const newRows: ITableRow[] = [
    ...state.rows.map((row: ITableRow) => {
      if (row.publicId === rowId) return { ...row, rowData: { ...row.rowData, ...value } }
      else return { ...row }
    })
  ]

  const newValue = value[columnId]

  if (newValue) {
    const newValueString = newValue.toString()
    if (newColumnValuesCount[columnId] && newColumnValuesCount[columnId][newValueString] !== undefined)
      newColumnValuesCount[columnId][newValueString] += 1
    else {
      if (!newColumnValuesCount[columnId]) newColumnValuesCount[columnId] = {}
      newColumnValuesCount[columnId][newValueString] = 1
    }
  }

  return {
    ...state,
    columnValuesCount: newColumnValuesCount,
    rows: newRows,
    refreshing: false
  }
}

// Update the current filters, the actual filtering is done using an effect
const updateFilters = (state: ISpreadsheetData, filters: ITableViewFilter[]) => {
  return {
    ...state,
    userConfiguration: {
      ...state.userConfiguration,
      filterSettings: filters,
      unsavedChanges: JSON.stringify(filters) !== JSON.stringify(state.viewDetails.filterSettings)
    }
  }
}

// Update the current colours
const updateColours = (state: ISpreadsheetData, colours: ITableViewColour[]) => {
  return {
    ...state,
    userConfiguration: {
      ...state.userConfiguration,
      colourSettings: colours,
      unsavedChanges: JSON.stringify(colours) !== JSON.stringify(state.viewDetails.colourSettings)
    }
  }
}

const setRows = (state: ISpreadsheetData, rows: ITableRow[], totalRows: number, filteredRows?: ITableRow[]) => {
  return { ...state, rows, totalRows, filteredRows: filteredRows !== undefined ? filteredRows : state.filteredRows }
}

const appendSpreadsheetRows = (state: ISpreadsheetData, rows: ITableRow[]) => {
  return {
    ...state,
    rows: [...state.rows, ...rows],
    totalRows: state.totalRows + rows.length
  }
}

const refreshSpreadsheetRows = (state: ISpreadsheetData, rows: ITableRow[], totalRows: number) => {
  return {
    ...state,
    rows: rows,
    totalRows: totalRows,
    streaming: true
  }
}

const updateTableColumn = (state: ISpreadsheetData, column: ITableViewColumn) => {
  // update view column
  const newColumns = [...state.viewDetails.columns]
  const columnNumber = newColumns.findIndex((newColumn) => newColumn.publicId === column.publicId)

  if (columnNumber === -1) {
    console.error(
      'APIError: The column can not be found in the view. Please save the view, refresh the page and try again.'
    )
    return state
  }
  const previousColumnKind = newColumns[columnNumber].kind
  const previousColumnName = newColumns[columnNumber].name
  newColumns[columnNumber] = column
  let filters = [...state.userConfiguration.filterSettings]
  let colours = [...state.userConfiguration.colourSettings]
  let groups = [...state.userConfiguration.groupSettings]
  let sorts = [...state.userConfiguration.sortSettings]
  const updatedRows = [...state.rows]
  if (previousColumnKind !== column.kind) {
    // Remove filters and colours if kind changes
    filters = state.userConfiguration.filterSettings.filter(
      (filterSetting) => filterSetting.columnId != column.publicId
    )
    colours = state.userConfiguration.colourSettings.filter(
      (colourSetting) => colourSetting.columnId != column.publicId
    )
  }

  if (previousColumnName !== column.name) {
    // Update names in filters/sort/group/colours/groups
    filters = state.userConfiguration.filterSettings.map((settings) => {
      if (settings.columnId == column.publicId) {
        settings.columnName = column.name
      }
      return settings
    })

    groups = state.userConfiguration.groupSettings.map((settings) => {
      if (settings.columnId == column.publicId) {
        settings.columnName = column.name
      }
      return settings
    })

    sorts = state.userConfiguration.sortSettings.map((settings) => {
      if (settings.columnId == column.publicId) {
        settings.columnName = column.name
      }
      return settings
    })

    colours = state.userConfiguration.colourSettings.map((settings) => {
      if (settings.columnId == column.publicId) {
        settings.columnName = column.name
      }
      return settings
    })
  }

  // Go through each of the views and update the columns if you need to
  const newViews = [...state.tableView]
  newViews.forEach((view: ITableViewWithColumns, viewNumber: number) => {
    newViews[viewNumber].columns = view.columns.map((viewColumn: ITableViewColumn) =>
      viewColumn.publicId === column.publicId
        ? {
            ...viewColumn,
            name: column.name,
            kind: column.kind,
            kindOptions: column.kindOptions,
            description: column.description,
            script: column.script,
            width: column.width,
            thousandSeparator: column.thousandSeparator,
            decimalPlaces: column.decimalPlaces,
            dateFormat: column.dateFormat,
            scriptEnabled: column.scriptEnabled,
            aggregate: column.aggregate,
            stringValidation:
              state.tableDetails.keepValidationsInSync || view.publicId === state.activeTableView
                ? column.stringValidation
                : viewColumn.stringValidation,
            validationMessage:
              state.tableDetails.keepValidationsInSync || view.publicId === state.activeTableView
                ? column.validationMessage
                : viewColumn.validationMessage,
            hardValidation:
              state.tableDetails.keepValidationsInSync || view.publicId === state.activeTableView
                ? column.hardValidation
                : viewColumn.hardValidation,
            validationNoBlanks:
              state.tableDetails.keepValidationsInSync || view.publicId === state.activeTableView
                ? column.validationNoBlanks
                : viewColumn.validationNoBlanks,
            validationNoDuplicates:
              state.tableDetails.keepValidationsInSync || view.publicId === state.activeTableView
                ? column.validationNoDuplicates
                : viewColumn.validationNoDuplicates,
            headerBackgroundColor: column.headerBackgroundColor,
            headerTextColor: column.headerTextColor,
            exportWidth: column.exportWidth
          }
        : viewColumn
    )
    if (previousColumnKind !== column.kind) {
      // Remove filters and colours if kind changes
      newViews[viewNumber].filterSettings = view.filterSettings.filter(
        (filter: ITableViewFilter) => filter.columnId !== column.publicId
      )
      newViews[viewNumber].colourSettings = view.colourSettings.filter(
        (colour: ITableViewColour) => colour.columnId !== column.publicId
      )
    } else if (previousColumnName !== column.name) {
      // Update names in filters/sort/group/colours/groups
      newViews[viewNumber].colourSettings = view.colourSettings.map((settings) => {
        if (settings.columnId == column.publicId) {
          settings.columnName = column.name
        }
        return settings
      })

      newViews[viewNumber].groupSettings = view.groupSettings.map((settings) => {
        if (settings.columnId == column.publicId) {
          settings.columnName = column.name
        }
        return settings
      })

      newViews[viewNumber].filterSettings = view.filterSettings.map((settings) => {
        if (settings.columnId == column.publicId) {
          settings.columnName = column.name
        }
        return settings
      })

      newViews[viewNumber].sortSettings = view.sortSettings.map((settings) => {
        if (settings.columnId == column.publicId) {
          settings.columnName = column.name
        }
        return settings
      })
    }
  })

  // Go through each of the columns
  const newTableDetails = state.tableDetails
  newTableDetails.columns = newTableDetails.columns.map((tableColumn: ITableColumn) =>
    tableColumn.publicId === column.publicId
      ? {
          ...tableColumn,
          name: column.name,
          description: column.description,
          kind: column.kind,
          kindOptions: column.kindOptions,
          script: column.script,
          scriptEnabled: column.scriptEnabled,
          aggregate: column.aggregate,
          thousandSeparator: column.thousandSeparator,
          decimalPlaces: column.decimalPlaces,
          dateFormat: column.dateFormat,
          headerBackgroundColor: column.headerBackgroundColor,
          headerTextColor: column.headerTextColor,
          exportWidth: column.exportWidth
        }
      : tableColumn
  )

  return {
    ...state,
    refreshing: false,
    rows: updatedRows,
    tableView: newViews,
    viewDetails: { ...state.viewDetails, columns: newColumns },
    userConfiguration: {
      ...state.userConfiguration,
      filterSettings: filters,
      colourSettings: colours,
      sortSettings: sorts,
      groupSettings: groups
    }
  }
}

const insertRow = (state: ISpreadsheetData, row: ITableRow, position: number) => {
  const newColumnValuesCount = { ...state.columnValuesCount }
  const columnKeys = Object.keys(row.rowData)
  for (let i = 0; i < columnKeys.length; i++) {
    const columnId = columnKeys[i]
    const value = row.rowData[columnId]
    if (value) {
      if (newColumnValuesCount[columnId] && newColumnValuesCount[columnId][value.toString()])
        newColumnValuesCount[columnId][value.toString()] += 1
      else {
        if (!newColumnValuesCount[columnId]) newColumnValuesCount[columnId] = {}
        newColumnValuesCount[columnId][value.toString()] = 1
      }
    }
  }

  return {
    ...state,
    rows: [...state.rows.slice(0, position), row, ...state.rows.slice(position)],
    totalRows: state.totalRows + 1,
    totalRowsOnServer: state.totalRowsOnServer + 1
  }
}

const appendRows = (state: ISpreadsheetData, rows: ITableRow[]) => {
  const newColumnValuesCount = { ...state.columnValuesCount }
  for (let j = 0; j < rows.length; j++) {
    const row = rows[j]
    const columnKeys = Object.keys(row.rowData)
    for (let i = 0; i < columnKeys.length; i++) {
      const columnId = columnKeys[i]
      const value = row.rowData[columnId]
      if (value) {
        if (newColumnValuesCount[columnId] && newColumnValuesCount[columnId][value.toString()])
          newColumnValuesCount[columnId][value.toString()] += 1
        else {
          if (!newColumnValuesCount[columnId]) newColumnValuesCount[columnId] = {}
          newColumnValuesCount[columnId][value.toString()] = 1
        }
      }
    }
  }

  return {
    ...state,
    rows: [...state.rows, ...rows],
    totalRows: state.totalRows + rows.length,
    totalRowsOnServer: state.totalRowsOnServer + rows.length
  }
}

const deleteRows = (state: ISpreadsheetData, rowIds: string[]) => {
  const newColumnValuesCount = { ...state.columnValuesCount }
  for (let i = 0; i < rowIds.length; i++) {
    const rowId = rowIds[i]
    const row = state.rows.find((row: ITableRow) => row.publicId === rowId)
    if (row) {
      const columnKeys = Object.keys(row.rowData)
      for (let i = 0; i < columnKeys.length; i++) {
        const columnId = columnKeys[i]
        const value = row.rowData[columnId]
        if (value !== undefined && value !== null && newColumnValuesCount[columnId] && [value.toString()]) {
          newColumnValuesCount[columnId][value.toString()] -= 1
        }
      }
    }
  }

  return {
    ...state,
    columnValuesCount: newColumnValuesCount,
    totalRows: state.totalRows - rowIds.length,
    totalRowsOnServer: state.totalRowsOnServer - rowIds.length,
    rows: state.rows.filter((row: ITableRow) => !rowIds.includes(row.publicId))
  }
}

const commentThreadsChange = (
  state: ISpreadsheetData,
  rowId: string,
  noOpenCommentThreads: number,
  noResolvedCommentThreads: number
) => {
  const newCommentThreads = [...state.comments]
  const currentCommentThread = newCommentThreads.find(
    (commentThread: ICommentThreadStats) => commentThread.referenceId === rowId
  )
  if (currentCommentThread) {
    currentCommentThread.openCommentThreads = noOpenCommentThreads
    currentCommentThread.resolvedCommentThreads = noResolvedCommentThreads
  } else {
    newCommentThreads.push({
      openCommentThreads: noOpenCommentThreads,
      referenceId: rowId,
      resolvedCommentThreads: noResolvedCommentThreads
    })
  }

  return {
    ...state,
    comments: newCommentThreads
  }
}

const toggleCollapsedGroupedColumns = (state: ISpreadsheetData, columnId: string, rows: ITableRow[]) => {
  const collapsedColumnIds = new Set(state.collapsedColumnIds)
  const collapsedGroupIds = new Set(state.collapsedGroupIds)

  // This works out the group keys for the column
  const toGroupColumns = state.userConfiguration.groupSettings.map((toGroupColumn) => {
    const column = state.viewDetails.columns.find((c) => c.publicId === toGroupColumn.columnId)!
    return column
  })
  const groupKeys = getGroupingKeysForColumn(rows, toGroupColumns, columnId)

  if (collapsedColumnIds.has(columnId)) {
    collapsedColumnIds.delete(columnId)
    for (const key of groupKeys) {
      collapsedGroupIds.delete(key)
    }
  } else {
    collapsedColumnIds.add(columnId)
    for (const key of groupKeys) {
      collapsedGroupIds.add(key)
    }
  }

  return { ...state, collapsedColumnIds, collapsedGroupIds }
}

const searchTable = (state: ISpreadsheetData, searchTerm: string) => {
  const newRows = [...state.rows]
  let found = 0
  const columns = state.viewDetails.columns.filter(
    (column: ITableViewColumn) =>
      !state.userConfiguration.hiddenColumns.includes(column.publicId) && !BLOCK_SEARCH_BY.includes(column.kind)
  )
  for (let i = 0; i < newRows.length; i++) {
    const row = newRows[i]
    row.searched = []
    for (let j = 0; j < columns.length; j++) {
      const key = columns[j].publicId
      const value = row.rowData[key]?.toString().toLowerCase()
      if (value?.includes(searchTerm.toLowerCase())) {
        row.searched.push(key)
        found += 1
      }
    }
  }

  return {
    ...state,
    rows: newRows,
    searching: false,
    searchTerm,
    searchFound: found
  }
}

const bulkEditCells = (state: ISpreadsheetData, cells: ICellUpdateAction[]) => {
  // convert to rowId -> ICellUpdateAction map for faster lookup in the rows map()
  // function below
  const seenColumns = new Set<string>()
  const rowIdToCellUpdate = cells.reduce((prev, current) => {
    // Merge together rows, as there could be multiple updates to
    // the same row
    seenColumns.add(current.columnId)
    if (prev[current.rowId]) {
      prev[current.rowId].value = {
        ...prev[current.rowId].value,
        ...current.value
      }
    } else {
      prev[current.rowId] = current
    }
    return prev
  }, {} as Record<string, ICellUpdateAction>)

  const newRows: ITableRow[] = [
    ...state.rows.map((row: ITableRow) => {
      if (rowIdToCellUpdate[row.publicId]) {
        return { ...row, rowData: { ...row.rowData, ...rowIdToCellUpdate[row.publicId].value } }
      } else {
        return { ...row }
      }
    })
  ]
  const newColumnValuesCount = { ...state.columnValuesCount }
  seenColumns.forEach((columnId: string) => {
    if (Object.keys(newColumnValuesCount).includes(columnId)) {
      delete newColumnValuesCount[columnId]
    }
  })
  newRows.forEach((row) => {
    seenColumns.forEach((columnId: string) => {
      const rowValue = row.rowData[columnId] as string
      if (newColumnValuesCount[columnId] !== undefined && newColumnValuesCount[columnId][rowValue] !== undefined) {
        newColumnValuesCount[columnId][rowValue] += 1
      } else {
        if (newColumnValuesCount[columnId] === undefined) newColumnValuesCount[columnId] = {}
        newColumnValuesCount[columnId][rowValue] = 1
      }
    })
  })

  return {
    ...state,
    rows: newRows,
    refreshing: false,
    columnValuesCount: newColumnValuesCount
  }
}

const addHistory = (state: ISpreadsheetData, event: ITableViewHistory) => {
  let newHistory = [...state.history.slice(0, state.historyPoint + 1)]
  newHistory = newHistory.concat(event)
  const newHistoryPoint = newHistory.length - 1
  return { ...state, history: newHistory, historyPoint: newHistoryPoint }
}

export const spreadsheetDataReducer = (
  state: ISpreadsheetData,
  action: SpreadsheetReducerActions
): ISpreadsheetData => {
  switch (action.type) {
    case 'CHANGE_COLUMN_WIDTH':
      return changeColumnWidth(state, action.columnId, action.columnWidth)
    case 'CHANGE_COLUMN_AGGREGATE':
      return changeColumnAggregate(state, action.columnId, action.aggregate)
    case 'SET_SPREADSHEET_DATA':
      return action.spreadsheetData
    case 'SET_SPREADSHEET_ROWS':
      return setRows(state, action.rows, action.totalRows, action.filteredRows)
    case 'SET_COLUMN_VALUES_COUNT':
      return { ...state, columnValuesCount: action.columnValuesCount }
    case 'ADD_COLUMN':
      return createTableColumn(state, action.column)
    case 'UPDATE_COLUMN':
      return updateTableColumn(state, action.column)
    case 'DELETE_COLUMN':
      return deleteTableColumn(state, action.columnId)
    case 'SHOW_COLUMN':
      return showColumn(state, action.columnId)
    case 'HIDE_COLUMN':
      return hideColumn(state, action.columnId)
    case 'HIDE_ALL_COLUMNS':
      return hideAllColumns(state)
    case 'SHOW_ALL_COLUMNS':
      return showAllColumns(state)
    case 'LOCK_COLUMN':
      return lockColumn(state, action.columnId)
    case 'UNLOCK_COLUMN':
      return unlockColumn(state, action.columnId)
    case 'UNLOCK_ALL_COLUMNS':
      return unlockAllColumns(state)
    case 'LOCK_ALL_COLUMNS':
      return lockAllColumns(state)
    case 'REQUIRE_COLUMN':
      return requireColumn(state, action.columnId)
    case 'UNREQUIRE_COLUMN':
      return unrequireColumn(state, action.columnId)
    case 'REQUIRE_ALL_COLUMNS':
      return requireAllColumns(state)
    case 'UNREQUIRE_ALL_COLUMNS':
      return unrequireAllColumns(state)
    case 'CHANGE_COLUMN_ORDER':
      return changeColumnOrder(state, action.columnId, action.newIndex)
    case 'CHANGE_ROW_ORDER':
      return changeRowOrder(state, action.rowId, action.newIndex, action.newSortOrder)
    case 'EDIT_CELL':
      return editCell(state, action.columnId, action.rowId, action.value, action.oldValue)
    case 'CHANGE_ROW_HEIGHT':
      return {
        ...state,
        viewDetails: { ...state.viewDetails, rowHeight: action.newRowHeight },
        userConfiguration: { ...state.userConfiguration, unsavedChanges: true }
      }
    case 'REFRESH':
      return { ...state, refreshing: action.refreshing }
    case 'LOADING':
      return { ...state, loading: action.loading }
    case 'ADD_SORT':
      return addSort(state, action.columnId, action.columnName)
    case 'DELETE_SORT':
      return deleteSort(state, action.columnId)
    case 'CHANGE_SORT_DIRECTION':
      return changeSortDirection(state, action.columnId, action.newDirection)
    case 'CHANGE_SORT_ORDER':
      return changeSortOrder(state, action.existingIndex, action.newIndex)
    case 'CHANGE_FROZEN_INDEX':
      return changeFrozenIndex(state, action.frozenIndex)
    case 'CHANGE_DISPLAY_VALIDATION_ROWS':
      return changeDisplayValidationRows(state, action.displayValidationErrorRows)
    case 'CHANGE_DISPLAY_COMMENT_ROWS':
      return changeDisplayCommentRows(state, action.displayCommentRows)
    case 'CHANGE_COLLAPSED_GROUP_VIEW':
      return changeCollapsedGroupView(state, action.collapsedGroupView)
    case 'CHANGE_UNPACK_MULTISELECT_GROUP_VIEW':
      return changeUnpackMultiselectGroupView(state, action.unpackMultiselectGroupView)
    case 'UPDATE_VIEW':
      return updateView(state, action.field, action.viewId, action.value)
    case 'UPDATE_TABLE':
      return updateTable(state, action.field, action.value)
    case 'UPDATE_TABLE_JOINS':
      return updateTableJoins(state, action.table)
    case 'SET_VIEW':
      return setView(state, action.viewId)
    case 'ADD_VIEW':
      return { ...state, tableView: [...state.tableView, action.view] }
    case 'SAVE_CURRENT_VIEW':
      return saveCurrentView(state)
    case 'DELETE_VIEW':
      return deleteView(state, action.viewId)
    case 'EXPAND_ALL_GROUPS':
      return expandAllGroups(state)
    case 'COLLAPSE_ALL_GROUPS':
      return collapseAllGroups(state, action.groupIds)
    case 'TOGGLE_GROUP_COLLAPSE':
      return toggleGroupCollapse(state, action.groupId)
    case 'ADD_GROUP':
      return addGroup(state, action.columnId, action.columnName)
    case 'ADD_CHART':
      return addChart(state, action.chart)
    case 'DELETE_GROUP':
      return deleteGroup(state, action.columnId)
    case 'CHANGE_GROUP_DIRECTION':
      return changeGroupDirection(state, action.columnId, action.newDirection)
    case 'CHANGE_GROUP_ORDER':
      return changeGroupOrder(state, action.existingIndex, action.newIndex)
    case 'UPDATE_FILTERS':
      return updateFilters(state, action.filters)
    case 'UPDATE_COLOURS':
      return updateColours(state, action.colours)
    case 'REFRESH_SPREADSHEET_ROWS':
      return refreshSpreadsheetRows(state, action.rows, action.totalRows)
    case 'APPEND_SPREADSHEET_ROWS':
      return appendSpreadsheetRows(state, action.rows)
    case 'STREAMING':
      return { ...state, streaming: action.streaming }
    case 'INSERT_ROW':
      return insertRow(state, action.row, action.position)
    case 'APPEND_ROWS':
      return appendRows(state, action.rows)
    case 'DELETE_ROWS':
      return deleteRows(state, action.rowIds)
    case 'COMMENT_THREADS_CHANGE':
      return commentThreadsChange(state, action.rowId, action.noOpenCommentThreads, action.noResolvedCommentThreads)
    case 'TOGGLE_COLUMN_COLLAPSE':
      return toggleCollapsedGroupedColumns(state, action.columnId, action.rows)
    case 'SEARCHING':
      return { ...state, searching: true }
    case 'SEARCH_TABLE':
      return searchTable(state, action.searchTerm)
    case 'CLEAR_SEARCH':
      return {
        ...state,
        searchTerm: '',
        searching: false,
        selectedSearchCell: INITIAL_SELECTED_SEARCH_CELL,
        rows: state.rows.map((row: ITableRow) => {
          return { ...row, searched: [] }
        })
      }
    case 'SET_SELECTED_SEARCH_CELL':
      return {
        ...state,
        selectedSearchCell: {
          rowId: action.rowId,
          columnId: action.columnId,
          columnNumber: action.columnNumber,
          rowNumber: action.rowNumber
        }
      }
    case 'BULK_EDIT_CELL':
      return bulkEditCells(state, action.cells)
    case 'ADD_HISTORY':
      return addHistory(state, action.event)
    case 'UPDATE_HISTORY_POINT':
      return { ...state, historyPoint: action.point }
    default:
      return state
  }
}
