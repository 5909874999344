import React, { useState } from 'react'
import { IContextMenuState } from 'types'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import { PERMISSIONS } from 'helpers/auth'
import {
  CollapseAll,
  Visible,
  Invisible,
  Dots,
  ExpandAll,
  Search,
  Filter,
  Pencil,
  Permissions,
  Audit,
  Variable,
  Export
} from 'components/icons'
import SettingsMenu from 'components/process/components/menu/views/settings'
import VariablesMenu from 'components/process/components/menu/views/variables'
import PermissionsMenu from 'components/process/components/menu/views/permissions'
import SearchMenu from 'components/process/components/menu/views/search'
import DefaultVariablesMenu from 'components/process/components/menu/views/defaultVariables'
import AuditModal from 'components/process/components/modal/audit'
import ExportModal from 'components/process/components/modal/export'
import { useDataContext } from 'components/process/contexts/data'
import SetRespondersModal from 'components/process/components/modal/setResponders'
import useApplicationStore from 'hooks/application'
import RestoreSection from 'components/process/components/modal/restoreSection'
import Duplicate from 'components/duplicate'
import Delete from 'components/delete'
import useProject from 'hooks/project'
import api from 'helpers/api'
import { PROJECT } from 'app-constants'
interface ToolbarProps {
  addSection: (parentId: string | null) => void
}

const Toolbar: React.FC<ToolbarProps> = ({ addSection }) => {
  const { project, openSearch } = useProject()
  const {
    process,
    updateProcess,
    editMode,
    setEditMode,
    navigationMenu,
    setNavigationMenu,
    expandAllSections
  } = useDataContext()
  const { setSnackbarMessage, displayErrorMessage } = useApplicationStore()

  const [permissionsMenu, setPermissionsMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [defaultVariablesMenu, setDefaultVariablesMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [settingsMenu, setSettingsMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [searchMenu, setSearchMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [variablesMenu, setVariablesMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [auditModal, setAuditModal] = useState<boolean>(false)
  const [allRespondersModal, setAllRespondersModal] = useState<boolean>(false)
  const [exportModal, setExportModal] = useState<boolean>(false)
  const [restoreSectionModal, setRestoreSectionModal] = useState<boolean>(false)
  const [duplicateModal, setDuplicateModal] = useState<boolean>(false)
  const [archiveModal, setArchiveModal] = useState<boolean>(false)
  const [archivingProcess, setArchivingProcess] = useState<{ isArchiving: boolean; status: string | null }>({
    isArchiving: false,
    status: null
  })

  const settingsWidth = 400
  const searchWidth = 400
  const variablesWidth = 500
  const permissionsWidth = 1000
  const defaultVariablesWidth = 600

  const isAdmin = process && process.permissionLevel === PERMISSIONS.owner

  if (archivingProcess.status) {
    setSnackbarMessage({
      status: archivingProcess.status
    })
    setArchivingProcess({ ...archivingProcess, status: null })
  }

  const archiveProcess = () => {
    if (project && process) {
      api
        .deleteProcess(process.publicId, { context: { projectId: project.publicId } })
        .then(() => {
          updateProcess({ ...process, isDeleted: true, deletedAt: new Date().toISOString() })
        })
        .catch((e) => {
          displayErrorMessage(e)
        })
    }
  }

  if (process) {
    return (
      <div
        className="flex items-center rounded heavy-shadow w-fit-content sm-w-200px overflow-x-auto"
        style={{
          position: 'fixed',
          inset: '10px 10px auto auto',
          zIndex: 4,
          paddingLeft: '20px',
          paddingRight: '20px',
          backgroundColor: 'white',
          fontSize: '90%',
          borderBottom: 'none'
        }}
      >
        {isAdmin && !process.isDeleted && (
          <div
            className={editMode ? 'toolbar-button-active' : 'toolbar-button'}
            title={editMode ? 'Switch To View Mode' : 'Switch To Edit Mode'}
            style={{ marginLeft: 'auto' }}
            onClick={() => {
              setEditMode(!editMode)
            }}
          >
            <Pencil />
          </div>
        )}
        <div
          className={navigationMenu ? 'toolbar-button-active' : 'toolbar-button'}
          title={navigationMenu ? 'Hide Navigation Menu' : 'Show Navigation Menu'}
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            setNavigationMenu(!navigationMenu)
          }}
        >
          {navigationMenu ? <Invisible /> : <Visible />}
        </div>
        <div
          className={'toolbar-button'}
          title="Search Within Document"
          onClick={() => {
            openSearch(process.publicId, process.name)
          }}
        >
          <Search />
        </div>
        {!process.isDeleted && (
          <div
            className={'toolbar-button'}
            title="Filter Using Public Variables"
            onClick={(event) => {
              setVariablesMenu({
                open: true,
                top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                left: `${event.currentTarget.getBoundingClientRect().left - variablesWidth + 25}px`,
                right: 'auto',
                bottom: 'auto'
              })
            }}
          >
            <Filter />
          </div>
        )}
        {isAdmin && !process.isDeleted && (
          <div
            className="toolbar-button"
            title="Default Variables"
            onClick={(event) => {
              setDefaultVariablesMenu({
                open: true,
                top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                left: `${event.currentTarget.getBoundingClientRect().left - defaultVariablesWidth + 20}px`,
                right: 'auto',
                bottom: 'auto'
              })
            }}
          >
            <Variable />
          </div>
        )}
        {isAdmin && !process.isDeleted && (
          <div
            className="toolbar-button"
            title="Permissions"
            onClick={(event) => {
              setPermissionsMenu({
                open: true,
                top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                left: `${event.currentTarget.getBoundingClientRect().left - permissionsWidth + 20}px`,
                right: 'auto',
                bottom: 'auto'
              })
            }}
          >
            <Permissions />
          </div>
        )}
        {isAdmin && (
          <div className="toolbar-button" title="Audit Trail" onClick={() => setAuditModal(true)}>
            <Audit />
          </div>
        )}
        <div
          className="toolbar-button"
          title="Expand All Sections"
          onClick={() => {
            if (process) {
              expandAllSections(process, true)
            }
          }}
        >
          <ExpandAll />
        </div>
        <div
          className="toolbar-button"
          title="Collapse All Sections"
          onClick={() => {
            if (process) {
              expandAllSections(process, false)
            }
          }}
        >
          <CollapseAll />
        </div>
        <div
          className="toolbar-button"
          style={{ marginLeft: '5px' }}
          title={'Export as PDF or Word'}
          onClick={() => {
            setExportModal(true)
          }}
        >
          <Export />
        </div>
        {isAdmin && (
          <div
            className="toolbar-button"
            style={{ marginLeft: '5px' }}
            title={'Document Settings'}
            onClick={(event: React.MouseEvent) =>
              setSettingsMenu({
                open: true,
                top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                left: `${event.currentTarget.getBoundingClientRect().left - settingsWidth + 20}px`,
                right: 'auto',
                bottom: 'auto'
              })
            }
          >
            <Dots />
          </div>
        )}
        {settingsMenu.open && (
          <SettingsMenu
            id={`settings-process-context-menu`}
            menuState={settingsMenu}
            setMenuState={setSettingsMenu}
            width={settingsWidth}
            setExportModal={(open: boolean) => {
              setExportModal(open)
              if (open) setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
            setRespondersModal={(open: boolean) => {
              setAllRespondersModal(open)
              if (open) setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
            addSection={addSection}
            setRestoreSectionModal={setRestoreSectionModal}
            setDuplicateModal={setDuplicateModal}
            setArchiveModal={setArchiveModal}
          />
        )}
        {variablesMenu.open && (
          <VariablesMenu
            id={`variables-process-context-menu`}
            menuState={variablesMenu}
            setMenuState={setVariablesMenu}
            width={variablesWidth}
          />
        )}
        {permissionsMenu.open && (
          <PermissionsMenu
            id={`permissions-process-context-menu`}
            menuState={permissionsMenu}
            setMenuState={setPermissionsMenu}
            width={permissionsWidth}
          />
        )}
        {defaultVariablesMenu.open && (
          <DefaultVariablesMenu
            id={`default-variables-process-context-menu`}
            menuState={defaultVariablesMenu}
            setMenuState={setDefaultVariablesMenu}
            width={defaultVariablesWidth}
          />
        )}
        {searchMenu.open && (
          <SearchMenu
            id={`search-process-context-menu`}
            menuState={searchMenu}
            setMenuState={setSearchMenu}
            width={searchWidth}
          />
        )}
        <AuditModal id="process-audit-modal" open={auditModal} setOpen={setAuditModal} />
        {exportModal && (
          <ExportModal
            id="export-process-modal"
            open={exportModal}
            setOpen={setExportModal}
            onClose={() => {
              setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
          />
        )}
        {archiveModal && (
          <Delete
            id="archive-process-modal"
            resourceName={process.name}
            open={archiveModal}
            setOpen={setArchiveModal}
            deleteResource={() => {
              archiveProcess()
            }}
            otherContent={
              <div style={{ marginTop: '20px' }}>
                Once this document is archived it will be removed from the navigation sidebar. If this document is used
                as the {PROJECT} homepage it may disrupt the functionality of your project.
              </div>
            }
          />
        )}
        {allRespondersModal && (
          <SetRespondersModal
            id="set-all-responders-modal"
            open={allRespondersModal}
            setOpen={setAllRespondersModal}
            onClose={() => {
              setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
          />
        )}
        {restoreSectionModal && (
          <RestoreSection
            id="restore-section-modal"
            open={restoreSectionModal}
            setOpen={setRestoreSectionModal}
            onClose={() => setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)}
          />
        )}
        {duplicateModal && (
          <Duplicate
            id="duplicate-process"
            type="process"
            publicId={process.publicId}
            open={duplicateModal}
            setOpen={setDuplicateModal}
          />
        )}
      </div>
    )
  } else {
    return <div />
  }
}

export default Toolbar
