import { IColumnTypes, IResultFieldValidation } from 'components/spreadsheet/types'
import { ICellValue, ITableViewColumn } from 'types'
import { NUMBER_COLUMN_TYPES, SELECT_COLUMN_TYPES, TEXT_COLUMN_TYPES } from '../constants/const'
import { isSameValueStringValue, validateField } from './functions'

export const reformattedPasteData = (text: string) => {
  const newString = text
    .replace(/^\s+|\s+$/g, '')
    .replace(/("[\s\S]*?")/g, (m, cg) => {
      return cg.replace(/\n/g, 'LINE-BREAK-TO-PRESERVE')
    })
    .split('\n')
    .map((i) => i.replace(/LINE-BREAK-TO-PRESERVE/g, '\n'))
  return newString
}

export const transformStringToCorrectFormat = (value: string | null, kind: IColumnTypes) => {
  if (value === null) return null
  else {
    if (kind === 'multilink' || kind === 'multiselect') {
      let array
      try {
        array = JSON.parse(value)
      } catch {
        throw `Cannot transform '${value}' into '${kind}'.`
      }

      if (!Array.isArray(array)) throw `Cannot transform '${value}' into '${kind}'.`
      array.forEach((item) => {
        if (typeof item !== 'string') throw `Cannot transform '${value}' into '${kind}'.`
      })
      return array
    } else if (kind === 'attachment' || kind === 'vote') {
      let array
      try {
        array = JSON.parse(value)
      } catch {
        throw `Cannot transform '${value}' into '${kind}'.`
      }
      if (!Array.isArray(array)) throw `Cannot transform '${value}' into '${kind}'.`
      return array
    } else if (kind === 'integer' || kind === 'duration' || kind === 'autonumber') {
      const number = Math.round(Number(value.replace(/,/g, '')))
      if (isNaN(number)) throw `Cannot transform '${value}' into '${kind}'.`
      return number
    } else if (kind === 'float' || kind === 'percentage') {
      const number = Number.parseFloat(value.replace(/,/g, ''))
      if (isNaN(number)) throw `Cannot transform '${value}' into '${kind}'.`
      return number
    } else if (kind === 'checkbox') {
      if (value === 'True' || value === 'true' || value === '1') return 'true'
      else return 'false'
    } else if (kind === 'date' || kind === 'datetime') {
      const parsedDate = Date.parse(value)
      if (isNaN(parsedDate)) throw `Cannot transform '${value}' into '${kind}'.`
      const toIsoString = new Date(parsedDate).toISOString().split('T')[0]
      if (!value.startsWith(toIsoString)) throw `Cannot transform '${value}' into '${kind}'.`
      else return value
    } else {
      return value
    }
  }
}

export const validatePasteField = (value: ICellValue, column: ITableViewColumn) => {
  const kind = column.kind
  if (kind !== 'multilink' && kind !== 'attachment' && kind !== 'select' && kind !== 'multiselect' && kind !== 'vote') {
    const result = validateField(kind, value, column.name)
    if (!(result as IResultFieldValidation).pass) {
      throw `'${value}' is not a valid value for column with type '${kind}'.`
    }
  }

  return true
}

export const checkValuesSame = (oldValue: ICellValue, newValue: ICellValue, kind: IColumnTypes) => {
  if ([...SELECT_COLUMN_TYPES, 'multilink', 'attachment', 'vote'].includes(kind)) {
    // if both are arrays, we need to check if the values are the same
    if (Array.isArray(newValue) && Array.isArray(oldValue)) {
      if ((newValue as string[]).length === (oldValue as string[]).length) {
        const isNewValue = (newValue as string[])
          .map((item) => Array.isArray(oldValue) && (oldValue as string[]).includes(item))
          .some((e) => e === false)
        if (!isNewValue) {
          return true
        }
      }
    } else if (newValue === oldValue) {
      // if both values are the same either empty string or null we don't need to save
      return true
    }
  } else if (
    [...TEXT_COLUMN_TYPES, ...NUMBER_COLUMN_TYPES, 'link'].includes(kind) &&
    isSameValueStringValue(newValue as string, oldValue as string)
  ) {
    return true
  }
  return false
}
