import React, { useState } from 'react'
import { EditorState } from 'draft-js'
import UploadMedia from 'components/editor/components/UploadMedia'
import IframeInsert from 'components/editor/components/IframeInsert'
import { HighlighterColors, HighlighterIcon } from 'components/editor/components/Highlighter'
import { AddAnchorControl, RemoveAnchorControl } from 'components/editor/components/Anchor'
import { IFileParentResource } from 'types'
import { Bold, Italic, Underline, Bullet, NumberedBullet } from 'components/icons'

interface StyleButtonProps {
  active: boolean
  icon: any
  onToggle: (controlType: 'INLINE' | 'BLOCK', style: any) => void
  style: any
  controlType: 'INLINE' | 'BLOCK'
  title: string
}

const StyleButton: React.FC<StyleButtonProps> = ({
  active,
  icon,
  onToggle,
  style,
  controlType,
  title
}: StyleButtonProps) => {
  const ButtonIcon = icon
  let className = 'flex items-center hover-bg-light-grey transition-all rounded RichEditor-styleButton'
  if (active) className += ' RichEditor-activeButton'

  return (
    <span
      title={title}
      className={className}
      onMouseDown={() => onToggle(controlType, style)}
      style={{ padding: '7px' }}
    >
      <ButtonIcon style={{ height: '25px', width: '25px' }} />
    </span>
  )
}

interface StyleControlProps {
  editorState: any
  onToggle: (controlType: 'INLINE' | 'BLOCK', style: any) => void
}

const InlineStyleControls: React.FC<StyleControlProps> = ({ editorState, onToggle }: StyleControlProps) => {
  const currentStyle = editorState.getCurrentInlineStyle()

  const INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD', icon: Bold, title: 'Bold' },
    { label: 'Italic', style: 'ITALIC', icon: Italic, title: 'Italic' },
    { label: 'Underline', style: 'UNDERLINE', icon: Underline, title: 'Underline' }
  ]

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          icon={type.icon}
          onToggle={onToggle}
          style={type.style}
          controlType="INLINE"
          title={type.title}
        />
      ))}
    </div>
  )
}

const BlockStyleControls: React.FC<StyleControlProps> = ({ editorState, onToggle }: StyleControlProps) => {
  const selection = editorState.getSelection()
  const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType()

  const BLOCK_TYPES = [
    { label: 'UL', style: 'unordered-list-item', icon: Bullet, title: 'Add Bulleted List' },
    { label: 'OL', style: 'ordered-list-item', icon: NumberedBullet, title: 'Add Numbered List' }
  ]

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          icon={type.icon}
          onToggle={onToggle}
          style={type.style}
          controlType="BLOCK"
          title={type.title}
        />
      ))}
    </div>
  )
}

interface ControlPanelProps {
  editorState: any
  onToggle: (controlType: 'INLINE' | 'BLOCK', style: any) => void
  onChange: (newContent: any) => void
  addImageModifier: any
  addVideoModifier: any
  addFileModifier: any
  addIframeModifier: (editorState: EditorState, url: string, height: number) => EditorState
  resources?: IFileParentResource[]
}

const ControlPanel: React.FC<ControlPanelProps> = ({
  editorState,
  onToggle,
  onChange,
  addImageModifier,
  addVideoModifier,
  addFileModifier,
  addIframeModifier,
  resources
}: ControlPanelProps) => {
  const [highlightActive, setHighlightActive] = useState<boolean>(false)

  return (
    <div>
      <div
        className="flex items-center rounded border-1px border-solid border-black heavy-shadow"
        style={{ padding: '10px', width: 'fit-content', marginTop: '10px' }}
        tabIndex={0}
      >
        <InlineStyleControls editorState={editorState} onToggle={onToggle} />
        <BlockStyleControls editorState={editorState} onToggle={onToggle} />
        <AddAnchorControl editorState={editorState} onChange={onChange} />
        <RemoveAnchorControl editorState={editorState} onChange={onChange} />
        <div>
          <div className="hightlighter-tooltip relative inline-block">
            <HighlighterIcon onClick={() => setHighlightActive(!highlightActive)} />
            <span
              className="highlighter-tooltip-text absolute bg-white text-center rounded border-1px border-solid border-black"
              style={{
                visibility: highlightActive ? 'visible' : 'hidden',
                zIndex: 1,
                bottom: '100%',
                left: '50%',
                marginLeft: '-100px'
              }}
            >
              <HighlighterColors editorState={editorState} onChange={onChange} />
            </span>
          </div>
        </div>
        <div className="flex items-center border-l-1px border-solid border-grey" style={{ paddingLeft: '10px' }}>
          <UploadMedia
            editorState={editorState}
            onChange={onChange}
            modifier={addImageModifier}
            mediaType="image"
            key={'image'}
            resources={resources}
          />

          <UploadMedia
            mediaType="video"
            editorState={editorState}
            onChange={onChange}
            modifier={addVideoModifier}
            key={'video'}
            resources={resources}
          />
          <UploadMedia
            mediaType="file"
            editorState={editorState}
            onChange={onChange}
            modifier={addFileModifier}
            key={'file'}
            resources={resources}
          />
        </div>
        <div style={{ borderLeft: '1px solid #ccc', marginLeft: '10px', paddingLeft: '10px' }}>
          <IframeInsert editorState={editorState} onChange={onChange} modifier={addIframeModifier} />
        </div>
      </div>
    </div>
  )
}

export default ControlPanel
