import React from 'react'
import { useApplicationStore } from 'hooks/application'

const MortaSnackbar: React.FC = () => {
  const { snackbarMessage } = useApplicationStore()
  const { message, status, action } = snackbarMessage

  const messageString = () => {
    let returnString = 'That was successful!'
    if (status === 'error') {
      returnString = 'Uh oh something went wrong...please refresh and try again'
    }
    if (!!message && typeof message === 'string') {
      returnString = message
    }
    return returnString
  }

  return (
    <div
      className={`flex items-center justify-center absolute ${
        status === undefined || status === 'noShow' ? 'invisible' : 'visible'
      } ${
        status === 'error'
          ? 'bg-red'
          : status === 'success'
          ? 'bg-green'
          : status === 'warning'
          ? 'bg-orange'
          : status === 'subscription'
          ? 'bg-alert'
          : 'bg-light-grey'
      } ${status === 'subscription' ? 'text-primary' : 'text-white'} p-4 rounded transition-all`}
      style={{ bottom: '10px', right: '10px', zIndex: 2000 }}
    >
      {messageString()}
      {action}
    </div>
  )
}

export default MortaSnackbar
