import React, { useState, useEffect, useRef } from 'react'
import Button from 'components/button'
import { ICommentThreadObject, CommentReferenceType } from 'types'
import api from 'helpers/api'
import CommentThread from './commentThread'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import { ISpreadsheetData } from 'components/spreadsheet/types'

export interface ICommentsThreadsComponentProps {
  referenceType: CommentReferenceType
  referenceId: string
  permissionLevel: number
  selected: boolean
  mainReferenceId?: string
  spreadsheetData?: ISpreadsheetData
  onOpenCommentTreadsChange: (value: number) => void
  onCommentThreadsChange?: (newCommentThreads: ICommentThreadObject[]) => void
}

const CommentThreads: React.FC<ICommentsThreadsComponentProps> = ({
  referenceType,
  referenceId,
  permissionLevel,
  selected,
  mainReferenceId,
  spreadsheetData,
  onOpenCommentTreadsChange,
  onCommentThreadsChange
}) => {
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()
  const refCommentButton = useRef<HTMLDivElement>(null)
  const refNewCommentThreadField = useRef<HTMLTextAreaElement>(null)

  const [commentsThreads, setCommentsThreads] = useState<ICommentThreadObject[]>()
  const [addingNewParentComment, setAddingNewParentComment] = useState<boolean>(false)
  const [newParentComment, setNewParentComment] = useState('')
  const [selectedCommentThread, setSelectedCommentThread] = useState('')

  useEffect(() => {
    if (refNewCommentThreadField && selected) {
      refNewCommentThreadField.current?.focus()
    }
    api
      .getCommentThreadsForReference(referenceType, referenceId, mainReferenceId)
      .then((response) => {
        setCommentsThreads(response.data)
      })
      .catch(() => {
        setSnackbarMessage({
          status: 'error',
          message: `Error loading the comment threads for resource ${referenceType} reference ${referenceId}`
        })
      })
  }, [])

  useEffect(() => {
    if (commentsThreads) {
      const activeCommentThreads = commentsThreads.filter(
        (commentThread) => commentThread.deletedAt == null && commentThread.resolvedAt == null
      )
      onOpenCommentTreadsChange(activeCommentThreads.length)
      if (onCommentThreadsChange) {
        onCommentThreadsChange(commentsThreads)
      }
    }
  }, [commentsThreads])

  useEffect(() => {
    if (selected && refCommentButton && refCommentButton!.current) {
      refCommentButton!.current.click()
    }
  }, [selected])

  const handleOnCommentThreadChange = (index: number, commentsThread: ICommentThreadObject) => {
    if (commentsThreads) {
      commentsThreads[index] = commentsThread
      const updatedCommentsThreads = commentsThreads.filter((item) => !item.deletedAt)
      setCommentsThreads(updatedCommentsThreads)
    }
  }

  const addNewCommentThread = () => {
    setAddingNewParentComment(true)

    let additionalContext = {}
    if (spreadsheetData) {
      additionalContext = {
        processPublicId: spreadsheetData.processId,
        processSectionPublicId: spreadsheetData.processSectionId,
        processResponsePublicId: spreadsheetData.processResponseId
      }
    }
    api
      .postCommentThread({
        referenceId: referenceId,
        referenceType: referenceType,
        commentText: newParentComment,
        mainReferenceId: mainReferenceId,
        context: {
          projectId: project.publicId,
          ...additionalContext
        }
      })
      .then((response) => {
        const updatedCommentsThreads = commentsThreads ? [...commentsThreads, response.data] : [response.data]
        setAddingNewParentComment(false)
        setNewParentComment('')
        setCommentsThreads(updatedCommentsThreads)
        onOpenCommentTreadsChange(updatedCommentsThreads.length)
      })
      .catch((error) => {
        displayErrorMessage(error)
        setAddingNewParentComment(false)
      })
  }

  return (
    <div className="overflow-y-auto" style={{ padding: '10px', height: '600px' }}>
      {commentsThreads &&
        commentsThreads.map((commentThread, index) => (
          <CommentThread
            projectId={project.publicId}
            key={`comment_thread_${commentThread.publicId}`}
            intialCommentThread={commentThread}
            selected={selectedCommentThread === commentThread.publicId}
            index={index}
            permissionLevel={permissionLevel}
            onSelected={(public_id) => setSelectedCommentThread(public_id)}
            onCommentThreadChange={(index, commentThread) => handleOnCommentThreadChange(index, commentThread)}
            spreadsheetData={spreadsheetData}
          />
        ))}
      <div
        className="rounded flex items-center flex-column"
        style={{ padding: '5px 5px 0px 5px', marginBottom: '5px' }}
      >
        <textarea
          placeholder="Add a new comment here..."
          style={{ marginBottom: '5px' }}
          value={newParentComment}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setNewParentComment(event.target.value)}
          ref={refNewCommentThreadField}
        />
        <div className="w-full flex items-center justify-end">
          <Button
            disabled={!newParentComment || addingNewParentComment}
            isLoading={addingNewParentComment}
            onClick={() => addNewCommentThread()}
          >
            Add Comment
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CommentThreads
