import React, { useEffect, useRef } from 'react'
import { Option, Join, Filter, Sort } from 'components/icons'
import { IColumnTypes } from 'components/spreadsheet/types'
import { cancelTimeout, requestTimeout, TimeoutID } from 'helpers/timer'
import { updateColumn } from 'components/spreadsheet/contexts/data/actions'
import constants from 'style/constants.module.scss'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import { IContextMenuState } from 'types'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import IconSelector from '../cell/components/icon'

interface HeaderProps {
  cumulativeWidth?: number
  width?: number
  optionClick?: (menuState: IContextMenuState) => void
  columnNumber?: number
  columnId?: string
  kind?: IColumnTypes
  value?: string
  backgroundColor?: string
  border?: string
  frozen: boolean
  locked?: boolean
  joined?: boolean
  filtered?: boolean
  sorted?: boolean
  dragOver?: string
  setDragOver?: (dragOver: string) => void
  isAdmin?: boolean
  isLastFrozenColumn: boolean
  viewId?: string
  draggedColumnId?: string
  setDraggedColumnId?: (draggedColumnId: string) => void
  setQuickFilterMenu?: (menuState: IContextMenuState) => void
  textColor?: string
}

const Header: React.FC<HeaderProps> = ({
  cumulativeWidth,
  width,
  optionClick,
  columnNumber,
  columnId,
  kind,
  value,
  backgroundColor,
  border,
  frozen,
  locked,
  joined,
  filtered,
  sorted,
  dragOver,
  setDragOver,
  isAdmin,
  isLastFrozenColumn,
  viewId,
  draggedColumnId,
  setDraggedColumnId,
  textColor
}) => {
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const dragging = useRef<boolean>(false)
  const mouseStart = useRef<number>(0)
  const originalWidth = useRef<number>(width ? width : Number(constants.columnMinWidth))
  const widthTimeout = useRef<TimeoutID | null>(null)
  const { setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    if (setDraggedColumnId) {
      const id = event.currentTarget.id
      setDraggedColumnId(id.split('/')[1])
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  // TODO: dragging column to the left is not moving the container to the left
  // TODO: prevent vertical scrolling in rows container when dragging and moving a column
  const handleDragEnter = () => {
    if (draggedColumnId && draggedColumnId !== '' && setDragOver && columnId !== undefined) {
      setDragOver(columnId)
    }
  }

  const handleOnDrop = () => {
    if (setDraggedColumnId && setDragOver && columnNumber !== undefined) {
      if (draggedColumnId && draggedColumnId !== '' && setSpreadsheetData)
        setSpreadsheetData({
          type: 'CHANGE_COLUMN_ORDER',
          columnId: draggedColumnId,
          newIndex: columnNumber
        })
      setDragOver('')
      setDraggedColumnId('')
    }
  }

  const handleMouseDown = (event: React.MouseEvent) => {
    dragging.current = true
    originalWidth.current = width ? width : Number(constants.columnMinWidth)
    mouseStart.current = event.clientX
  }

  const onWidthUpdateSuccess = () => {
    return null
  }

  const onWidthUpdateFailure = () => {
    setSnackbarMessage({ status: 'success', message: 'The column width has been saved successfully' })
  }

  const handleMouseMove = (event: MouseEvent) => {
    if (dragging.current === true) {
      const delta = event.clientX - mouseStart.current
      const newWidth = Math.max(originalWidth.current + delta, Number(constants.columnMinWidth))

      if (setSpreadsheetData && columnId !== undefined)
        setSpreadsheetData({ type: 'CHANGE_COLUMN_WIDTH', columnId, columnWidth: newWidth })

      if (isAdmin && columnId !== undefined && viewId !== undefined) {
        if (widthTimeout.current !== null) cancelTimeout(widthTimeout.current)

        widthTimeout.current = requestTimeout(() => {
          updateColumn(
            project.publicId,
            'width',
            spreadsheetData,
            setSpreadsheetData,
            columnId,
            newWidth,
            onWidthUpdateSuccess,
            onWidthUpdateFailure
          )
        }, 2000)
      }
    }
  }

  const handleMouseUp = () => {
    if (dragging.current === false) return

    dragging.current = false
    mouseStart.current = 0
  }

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [columnNumber, width])

  return (
    <div
      id={columnId !== undefined ? `column/${columnId}` : `column-number`}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDrop={handleOnDrop}
      onDragEnter={handleDragEnter}
      draggable={!locked}
      className="box-border inline-block"
      style={{
        height: `${Number.parseInt(constants.headerHeight) - 1}px`,
        width: width ? `${width}px` : '100%',
        backgroundColor: backgroundColor ? backgroundColor : constants.lightGrey,
        borderRight: border
          ? 'initial'
          : columnId !== undefined && dragOver === columnId
          ? `1px solid ${constants.pink}`
          : `1px solid ${constants.grey}`,
        position: frozen ? 'sticky' : 'relative',
        left: frozen ? `${cumulativeWidth}px` : 'auto',
        zIndex: frozen ? 6 : 'auto',
        cursor: 'grab',
        boxShadow: isLastFrozenColumn
          ? `${Number.parseInt(constants.frozenShadowWidth)}px 0 1px ${constants.grey}`
          : 'none',
        color: textColor ? textColor : constants.textPrimary
      }}
    >
      <div
        className="w-full h-full flex items-center absolute box-inherit"
        style={{ padding: '3px' }}
        onContextMenu={(event: React.MouseEvent) => {
          event.preventDefault()
          if (kind && optionClick && !joined) {
            optionClick({
              open: true,
              top: `${event.clientY + 5}px`,
              left: `${event.clientX - 120}px`,
              right: 'auto',
              bottom: 'auto',
              columnNumber: columnNumber !== undefined ? columnNumber : -1,
              columnId: columnId || '',
              rowNumber: -1,
              rowId: ''
            })
          }
        }}
      >
        {kind && (
          <span className="flex items-center">
            <IconSelector kind={kind} />
          </span>
        )}
        {value && (
          <div
            className="overflow-hidden"
            style={{ marginLeft: '5px', maxHeight: `${Number.parseInt(constants.headerHeight) - 5}px` }}
          >
            {value}
          </div>
        )}

        <div className="flex ml-auto">
          {filtered && (
            <div className="flex items-center justify-center truncate" style={{ minWidth: '30px', maxWidth: '30px' }}>
              <Filter />
            </div>
          )}
          {sorted && (
            <div className="flex items-center justify-center truncate" style={{ minWidth: '30px', maxWidth: '30px' }}>
              <Sort />
            </div>
          )}
          {joined && (
            <div className="flex items-center justify-center truncate" style={{ minWidth: '30px', maxWidth: '30px' }}>
              <Join />
            </div>
          )}
        </div>
        {kind && optionClick && !joined && (
          <div
            className="flex items-center justify-center truncate"
            style={{ minWidth: '30px', maxWidth: '30px', cursor: 'pointer' }}
            onClick={(event: React.MouseEvent) =>
              optionClick({
                open: true,
                top: `${event.currentTarget.getBoundingClientRect().bottom}px`,
                left: `${event.currentTarget.getBoundingClientRect().left - 130}px`,
                right: 'auto',
                bottom: 'auto',
                columnNumber: columnNumber !== undefined ? columnNumber : -1,
                columnId: columnId || '',
                rowNumber: -1,
                rowId: ''
              })
            }
          >
            <Option />
          </div>
        )}
        {columnNumber !== undefined && (
          <div
            className="absolute cursor-resize"
            style={{
              top: '-1px',
              right: '-7.5px',
              width: '16px',
              height: `${Number.parseInt(constants.headerHeight) - 2}px`,
              zIndex: 2
            }}
            onMouseDown={(event: React.MouseEvent) => {
              event.preventDefault()
              handleMouseDown(event)
            }}
          >
            <div
              className="absolute block rounded h-full bg-blue opacity-0 hover-opacity-1"
              style={{ left: '7px', width: '3px' }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo(Header)
