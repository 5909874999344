import React from 'react'
import Menu, { MenuProps } from 'components/menu'
import Variables from 'components/variables'
import { useDataContext } from 'components/process/contexts/data'

const VariablesMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { process, setProcess } = useDataContext()
  const onVariableChange = (variableIds: string[]) => {
    if (process) {
      setProcess({ ...process, tempVariables: variableIds })
    }
  }
  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <div
        className="overflow-x-hidden overflow-y-auto"
        style={{ listStyle: 'none', padding: '10px', height: '500px', margin: '10px' }}
      >
        <div style={{ marginTop: '20px', marginBottom: '40px' }}>
          Select a public variable to filter tables in this process.
        </div>

        <Variables
          initialVariables={process ? process.tempVariables : []}
          onChange={(variableIds: string[]) => onVariableChange(variableIds)}
          hideTags={true}
          hideVariables={process ? process.variables : []}
        />
      </div>
    </Menu>
  )
}

export default VariablesMenu
