import { IProcessObject, IProcessSectionObject, IProcessResponseObject } from 'types'

export const RESPONSE_TYPES = ['Flexible', 'File Upload', 'Table', 'Selection', 'Signature']

export const findSection = function (
  sectionId: string | null,
  parent: IProcessObject | IProcessSectionObject | null
): any {
  let foundSection = null
  let foundSectionIndex = 0
  if (sectionId && parent && parent.children) {
    for (let i = 0; i < parent.children.length; i++) {
      const child = parent.children[i]
      if (child.publicId === sectionId) {
        foundSection = child
        foundSectionIndex = i
      } else {
        const searchedSection = findSection(sectionId, child)
        if (searchedSection.section) {
          foundSectionIndex = searchedSection.index
          foundSection = searchedSection.section
        }
      }
    }
  }

  return { section: foundSection, index: foundSectionIndex }
}

export const findSectionParent = function (
  sectionId: string | null,
  parent: IProcessObject | IProcessSectionObject | null
): any {
  let foundSectionParentId = null

  if (sectionId && parent && parent.children) {
    for (let i = 0; i < parent.children.length; i++) {
      const child = parent.children[i]
      if (child.publicId === sectionId) {
        return parent.publicId
      } else {
        const searchedSection: IProcessSectionObject | null = findSectionParent(sectionId, child)
        if (searchedSection) {
          foundSectionParentId = searchedSection
        }
      }
    }
  }

  return foundSectionParentId
}

export const findSectionGroup = function (sectionId: string | null, process: IProcessObject): string | null {
  if (sectionId) {
    for (let i = 0; i < process.children.length; i++) {
      const section = process.children[i]
      if (findSection(sectionId, section).section) return section.publicId
    }
    return sectionId
  } else return null
}

export const findResponse = function (responseId: string | null, section: IProcessSectionObject): any {
  let foundResponse: IProcessResponseObject | null = null
  if (responseId && parent) {
    for (let j = 0; j < section.responses.length; j++) {
      const response = section.responses[j]
      if (response.publicId === responseId) {
        foundResponse = response
      }
    }

    for (let i = 0; i < section.children.length; i++) {
      const child = section.children[i]
      const searchedResponse: IProcessResponseObject | null = findResponse(responseId, child)
      if (searchedResponse) foundResponse = searchedResponse
    }
  }

  return foundResponse
}

export const addSectionToProcess = (
  parentId: string | null,
  children: IProcessSectionObject[],
  section: IProcessSectionObject,
  position?: number
): IProcessSectionObject[] => {
  const children_copy = [...children]

  if (position === undefined) {
    if (parentId === null) {
      children_copy.push(section)
      return children_copy
    } else {
      children_copy.forEach((child) => {
        if (child.publicId === parentId) {
          child.children.push(section)
          return children_copy
        } else {
          return addSectionToProcess(parentId, child.children, section)
        }
      })
      return children_copy
    }
  } else {
    if (parentId === null) {
      children_copy.splice(position, 0, section)
      return children_copy
    } else {
      children_copy.forEach((child) => {
        if (child.publicId === parentId) {
          child.children.splice(position, 0, section)
          return children_copy
        } else {
          return addSectionToProcess(parentId, child.children, section, position)
        }
      })
      return children_copy
    }
  }
}

export const removeSectionFromProcess = (
  sectionId: string,
  children: IProcessSectionObject[]
): IProcessSectionObject[] => {
  const children_copy = [...children]
  const children_iteration = [...children]

  const index = children_iteration.findIndex((child) => child.publicId === sectionId)
  if (index >= 0) {
    children_copy.splice(index, 1)
    return children_copy
  } else {
    children_iteration.forEach((child) => {
      child.children = removeSectionFromProcess(sectionId, child.children)
    })
    return children_iteration
  }
}

interface IFlatData {
  parentId: string | null
  position: number
  sectionId: string
}

export const getSectionFlatData = (section: IProcessSectionObject): IFlatData[] => {
  let flatData: IFlatData[] = []

  for (let i = 0; i < section.children.length; i++) {
    const child = section.children[i]
    flatData.push({ parentId: section.publicId, position: i, sectionId: child.publicId })
    flatData = flatData.concat(getSectionFlatData(child))
  }

  return flatData
}

export const getFlatData = (process: IProcessObject): IFlatData[] => {
  let flatData: IFlatData[] = []

  for (let i = 0; i < process.children.length; i++) {
    const child = process.children[i]
    flatData.push({ parentId: null, position: i, sectionId: child.publicId })
    flatData = flatData.concat(getSectionFlatData(child))
  }

  return flatData
}

export const doesSectionContain = (section: IProcessSectionObject, sectionId: string): boolean => {
  let containsSection = false
  for (let i = 0; i < section.children.length; i++) {
    const child = section.children[i]
    if (child.publicId === sectionId) {
      containsSection = true
      break
    } else {
      if (doesSectionContain(child, sectionId)) {
        containsSection = true
      }
    }
  }

  return containsSection
}

export const doesSectionContainName = (section: IProcessSectionObject, sectionName: string): boolean => {
  let containsSection = false
  for (let i = 0; i < section.children.length; i++) {
    const child = section.children[i]
    if (child.name.toLowerCase().includes(sectionName.toLowerCase())) {
      containsSection = true
      break
    } else {
      if (doesSectionContainName(child, sectionName)) {
        containsSection = true
      }
    }
  }

  return containsSection
}

export const scrollToSection = (sectionId: string) => {
  const sectionContainer = document.getElementById(`section/${sectionId}`)
  if (sectionContainer) {
    sectionContainer.scrollIntoView({
      inline: 'center'
    })
  }
}

export const sectionSearchMatch = (section: IProcessSectionObject, searchTerm: string) => {
  const search = searchTerm.toLowerCase()
  const sectionNameMatches = section.name.toLowerCase().includes(search)
  const sectionContainsName = doesSectionContainName(section, search)

  return sectionNameMatches || sectionContainsName
}
