import React from 'react'
import { ISummaryUser } from 'types'
import { Link } from 'components/icons'
import constants from 'style/constants.module.scss'
import { getRowHeightVariable } from 'components/spreadsheet/helpers/functions'
interface UserCellProps {
  value: ISummaryUser
  selected: boolean
  rowHeight: number
  width: number
}

const UserCellComponent: React.FC<UserCellProps> = ({ value, selected, width, rowHeight }) => {
  const isUserData = (value: string | number | ISummaryUser): value is ISummaryUser => {
    return value !== undefined && value !== null && !!(value as ISummaryUser).firebaseUserId
  }
  return (
    <div
      className={`cell-value ${selected ? 'focus' : ''} `}
      style={{ height: `${Number(constants.smallRowHeight) - 2}px`, width: 'inherit' }}
    >
      {isUserData(value) ? (
        <div
          className="flex h-inherit w-inherit items-center overflow-y-hidden bg-light-grey rounded"
          style={{ padding: '5px', width: width - 12, height: `${getRowHeightVariable(rowHeight) - 3}px` }}
        >
          {value.profilePicture && (
            <img
              style={{ borderRadius: '50%', width: '20px', height: '20px', objectFit: 'cover', marginRight: '10px' }}
              src={value.profilePicture}
              alt={`${value.name} profile picture`}
            />
          )}
          {!value.profilePicture && (
            <img
              style={{ borderRadius: '50%', width: '20px', height: '20px', objectFit: 'cover', marginRight: '10px' }}
              src="/assets/images/user.png"
              alt="user-no-profile-picture"
            />
          )}
          <div className="truncate">{value.name ? value.name : 'Anonymous User'}</div>
          {value.firebaseUserId && (
            <a
              className="user-link"
              href={`/profile/${value.firebaseUserId}/information`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Link />
            </a>
          )}
        </div>
      ) : (
        <div
          className="flex h-inherit w-inherit items-center overflow-y-hidden bg-light-grey rounded"
          style={{ padding: '5px' }}
        >
          <img
            style={{ borderRadius: '50%', width: '20px', height: '20px', objectFit: 'cover', marginRight: '10px' }}
            src="/assets/images/user.png"
            alt="user-no-profile-picture"
          />

          <div className="truncate">Anonymous User</div>
        </div>
      )}
    </div>
  )
}

export const UserCell = React.memo(UserCellComponent)
