import React, { useCallback, useState } from 'react'
import constants from 'style/constants.module.scss'
import Cell from 'components/spreadsheet/components/cell'
import { ITableRow, ITableViewSort, ITableViewFilter, ICommentThreadStats, ICellValue, IContextMenuState } from 'types'
import { updateRowPosition } from 'components/spreadsheet/contexts/data/actions'
import {
  SpreadsheetReducerActions,
  IViewPortColumn,
  ISelectedCell,
  ITableRowInGroup,
  ISelectedCellState,
  ISelectedCellRange,
  ISpreadsheetData
} from 'components/spreadsheet/types'
import { IUpdateTableViewCell } from 'types'
import { getRowHeightVariable, canRearrangeRows, isRowInGroup } from 'components/spreadsheet/helpers/functions'
import GroupCell from '../grouping/GroupCell'
import { useProject } from 'hooks/project'
import GroupLoadingCell from '../grouping/GroupLoading'
import { IntegrationSyncTypes } from 'components/spreadsheet/constants/const'
import { SELECT_COLUMN_TYPES } from 'components/spreadsheet/constants/const'

interface RowProps {
  rowNumber: number
  row: ITableRow | ITableRowInGroup
  prevRowId: string
  nextRowId: string
  rowHeight: number
  columns: IViewPortColumn[]
  filteredColumns: ITableViewFilter[]
  sortedColumns: ITableViewSort[]
  groupingEnabled: boolean
  uniqueNumber: number
  contextClick: (menuState: IContextMenuState) => void
  selectedCell: ISelectedCellState
  setSelectedCell: (selectedCell: ISelectedCellState) => void
  selectedCellRange: ISelectedCellRange
  setSelectedCellRange: (selectedCellRange: ISelectedCellRange) => void
  width: number
  dragOver: string
  isContributor: boolean
  handleKeyDown: (event: React.KeyboardEvent, selectedCell: ISelectedCell) => void
  setSpreadsheetData?: React.Dispatch<SpreadsheetReducerActions>
  setDragOver: (dragOver: string) => void
  isAdmin?: boolean
  setCellValue?: (cells: IUpdateTableViewCell, onSuccess: () => void, onError: () => void) => void
  spreadsheetData: ISpreadsheetData
  onGroupToggle: (groupKey: string) => void
  onGroupContext: (menuState: IContextMenuState) => void
  isFirstRow: boolean
  groupedColumns: IViewPortColumn[]
  nonGroupedColumns: IViewPortColumn[]
  setExpandModal: (expandModal: boolean, focusInput: boolean, rowId: string, rowNumber: number) => void
  setIsFocused?: (iFocused: boolean) => void
}

const Row: React.FC<RowProps> = ({
  rowNumber,
  row,
  prevRowId,
  nextRowId,
  rowHeight,
  groupingEnabled,
  contextClick,
  selectedCell,
  setSelectedCell,
  selectedCellRange,
  setSelectedCellRange,
  width,
  uniqueNumber,
  handleKeyDown,
  setSpreadsheetData,
  dragOver,
  setDragOver,
  isAdmin,
  isContributor,
  setCellValue,
  spreadsheetData,
  onGroupToggle,
  onGroupContext,
  isFirstRow,
  groupedColumns,
  nonGroupedColumns,
  setExpandModal,
  setIsFocused
}) => {
  const { project } = useProject()
  const [hovered, setHovered] = useState<boolean>(false)
  const handleMouseEnter = () => {
    if (row) setHovered(true)
  }

  const handleMouseLeave = () => {
    if (hovered) setHovered(false)
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    if (isAdmin) {
      event.preventDefault()
    }
  }

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    if (isAdmin && row !== undefined && row !== null) {
      setDragOver(row.publicId)
      event.dataTransfer.dropEffect = 'move'
    }
  }

  const handleOnDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (isAdmin && dragOver !== '' && spreadsheetData !== undefined) {
      const draggedRowId = event.dataTransfer.getData('rowId')
      if (draggedRowId && draggedRowId !== '' && setSpreadsheetData !== undefined)
        updateRowPosition(
          project.publicId,
          draggedRowId,
          rowNumber,
          spreadsheetData,
          setSpreadsheetData,
          () => {
            return null
          },
          () => {
            return null
          }
        )

      setDragOver('')
    } else {
      setDragOver('')
    }
  }
  const setCellValueCallback = useCallback(
    (cells: IUpdateTableViewCell, onSuccess: () => void, onError: (message?: string) => void) => {
      if (setCellValue && setSpreadsheetData) {
        cells.cells.forEach((cell) => {
          if (cell.oldValue !== undefined) {
            setSpreadsheetData({
              type: 'EDIT_CELL',
              columnId: cell.columnId,
              rowId: cell.rowId,
              value: { [cell.columnId]: cell.value as string },
              oldValue: cell.oldValue
            })
          }
        })

        setCellValue(cells, onSuccess, onError)
      }
    },
    [nonGroupedColumns]
  )

  const rowComments =
    spreadsheetData && row
      ? spreadsheetData.comments.find((comment: ICommentThreadStats) => comment.referenceId === row.publicId)
      : undefined

  const noRowComments = rowComments === undefined ? -1 : rowComments.openCommentThreads

  const noUnresolvedRowComments =
    rowComments === undefined ? -1 : rowComments.openCommentThreads - rowComments.resolvedCommentThreads

  const canDrag = canRearrangeRows(spreadsheetData) && isAdmin

  // The `isRowInGroup` function is required because although column may be marked as group, the useEffect
  // which actually puts the rows into the group may not have run yet, this normally happens when we're
  // still streaming data into the frontend
  const groupedColumnsLoaded = isRowInGroup(row) ? groupedColumns : []
  const groupedColumnsYetToLoad = isRowInGroup(row) ? [] : groupedColumns
  const startOfGroupRow = isRowInGroup(row) && row.start

  let isFirstColumnGroupLabel = false

  // This will be empty if there is no grouping applied
  const groupedColumnElements = groupedColumnsLoaded.map((vColumn, index) => {
    const rowInGroup = row as ITableRowInGroup
    const groupKey = rowInGroup.groupKeys[index]
    const value = row.rowData[vColumn.column.publicId]
    let shouldShowValue = false

    if (rowInGroup.groupsToRender.includes(groupKey) && rowInGroup.start) {
      shouldShowValue = true
      if (index === 0) {
        isFirstColumnGroupLabel = true
      }
    }

    return (
      <GroupCell
        key={vColumn.column.publicId}
        column={vColumn}
        rowHeight={rowHeight}
        colourSettings={spreadsheetData.userConfiguration.colourSettings}
        onGroupToggle={onGroupToggle}
        groupKey={groupKey}
        value={value}
        canCollapse={true}
        onContext={onGroupContext}
        shouldShowValue={shouldShowValue}
      />
    )
  })

  // Renders placeholder group cells when we're still streaming data to the frontend
  const groupLoadingCells = groupedColumnsYetToLoad.map((vColumn) => (
    <GroupLoadingCell
      key={vColumn.column.publicId}
      column={vColumn}
      rowHeight={rowHeight}
      value={isFirstRow ? 'Loading group...' : ''}
    />
  ))

  return (
    <div
      key={`row/${row.publicId}/${uniqueNumber}`}
      id={`row/${row.publicId}/${uniqueNumber}`}
      className="absolute inline-flex"
      onDragOver={handleDragOver}
      onDrop={handleOnDrop}
      onDragEnter={handleDragEnter}
      style={{
        width: `${width}px`,
        margin: 0,
        padding: 0,
        height: 'auto',
        top: `${Number(constants.headerHeight) + rowNumber * getRowHeightVariable(rowHeight)}px`,
        backgroundColor: hovered ? constants.reallyLightGrey : 'white'
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Cell
        width={Number(constants.rowNumberColumnWidth)}
        cumulativeWidth={0}
        value={groupingEnabled ? '' : (rowNumber + 1).toString()}
        color={constants.textSecondary}
        align={'center'}
        rowHeight={rowHeight}
        loading={!row}
        rowNumber={rowNumber}
        rowId={row ? row.publicId : undefined}
        kind={'text'}
        scriptEnabled={false}
        isJoined={false}
        contextClick={contextClick}
        frozen={true}
        canDrag={canDrag}
        isDragOver={dragOver === row.publicId}
        isContributor={false}
        isAdmin={false}
        uniqueNumber={uniqueNumber}
        noComments={noRowComments}
        unresolvedComments={noUnresolvedRowComments}
        isStartOfGroup={isFirstColumnGroupLabel}
        useGreyBackground={groupingEnabled}
        setSelectedCell={setSelectedCell}
        setCellValue={setCellValue}
        setSelectedCellRange={setSelectedCellRange}
        editing={false}
        setExpandModal={setExpandModal}
        type={spreadsheetData.viewDetails.type}
        colourSettings={[]}
        columnValuesCount={undefined}
      />
      {groupLoadingCells}
      {groupedColumnElements}
      {nonGroupedColumns.map((viewportColumn: IViewPortColumn, columnNumber: number) => {
        const prevColumn = nonGroupedColumns[columnNumber - 1]
        const nextColumn = nonGroupedColumns[columnNumber + 1]

        const value: ICellValue =
          row.rowData[viewportColumn.column.publicId] !== undefined ? row.rowData[viewportColumn.column.publicId] : null

        const selected = selectedCell.columnId === viewportColumn.column.publicId && selectedCell.rowId === row.publicId

        const inSelectedRowRange =
          (selectedCellRange.endRowIndex !== -1 &&
            rowNumber >= selectedCell.rowNumber &&
            rowNumber <= selectedCellRange.endRowIndex) ||
          (rowNumber <= selectedCell.rowNumber && rowNumber >= selectedCellRange.endRowIndex)
        const inSelectedColumnRange =
          (selectedCellRange.endColumnIndex !== -1 &&
            columnNumber <= selectedCell.columnNumber &&
            columnNumber >= selectedCellRange.endColumnIndex) ||
          (columnNumber >= selectedCell.columnNumber && columnNumber <= selectedCellRange.endColumnIndex)

        const inSelectedCellRange = inSelectedRowRange && inSelectedColumnRange
        const hasActiveValidation =
          (viewportColumn.column.stringValidation !== '' && viewportColumn.column.stringValidation != null) ||
          viewportColumn.column.hardValidation ||
          viewportColumn.column.validationNoBlanks ||
          viewportColumn.column.validationNoDuplicates
        return (
          <Cell
            key={`$row-${row.publicId}-column-${viewportColumn.column.publicId}`}
            width={viewportColumn.column.width}
            cumulativeWidth={viewportColumn.left}
            value={value}
            rowNumber={rowNumber}
            rowHeight={rowHeight}
            rowId={row.publicId}
            prevRowId={prevRowId}
            nextRowId={nextRowId}
            scriptEnabled={viewportColumn.column.scriptEnabled}
            columnNumber={columnNumber}
            columnId={viewportColumn.column.publicId}
            prevColumnId={prevColumn && prevColumn.column ? prevColumn.column.publicId : ''}
            nextColumnId={nextColumn && nextColumn.column ? nextColumn.column.publicId : ''}
            setSelectedCell={setSelectedCell}
            kind={viewportColumn.column.kind}
            isJoined={viewportColumn.column.isJoined}
            contextClick={contextClick}
            frozen={viewportColumn.isFrozen}
            isLastFrozenCell={viewportColumn.isLastFrozenColumn}
            isContributor={isContributor}
            isAdmin={!!isAdmin}
            handleKeyDown={handleKeyDown}
            uniqueNumber={uniqueNumber}
            locked={
              viewportColumn.column.locked ||
              viewportColumn.column.isJoined ||
              viewportColumn.isGrouped ||
              viewportColumn.column.viewpointSynced === IntegrationSyncTypes.READ_ONLY_SYNCED ||
              viewportColumn.column.viewpointRfisSynced === IntegrationSyncTypes.READ_ONLY_SYNCED ||
              viewportColumn.column.autodeskBim360Synced === IntegrationSyncTypes.READ_ONLY_SYNCED ||
              viewportColumn.column.autodeskBim360ChecklistsSynced === IntegrationSyncTypes.READ_ONLY_SYNCED ||
              viewportColumn.column.procoreSynced === IntegrationSyncTypes.READ_ONLY_SYNCED ||
              viewportColumn.column.mortaSynced === IntegrationSyncTypes.READ_ONLY_SYNCED ||
              viewportColumn.column.reviztoIssuesSynced === IntegrationSyncTypes.READ_ONLY_SYNCED ||
              viewportColumn.column.asiteDocumentsSynced === IntegrationSyncTypes.READ_ONLY_SYNCED ||
              viewportColumn.column.aconexWorkflowsSynced === IntegrationSyncTypes.READ_ONLY_SYNCED ||
              viewportColumn.column.aconexSynced === IntegrationSyncTypes.READ_ONLY_SYNCED
            }
            setCellValue={setCellValueCallback}
            columnName={viewportColumn.column.name}
            isStartOfGroup={startOfGroupRow}
            selected={selected}
            searched={
              row && row.searched && row.searched.length !== 0 && row.searched.includes(viewportColumn.column.publicId)
            }
            inSelectedCellRange={inSelectedCellRange}
            setSelectedCellRange={setSelectedCellRange}
            editing={selected && selectedCell.editing}
            tempValue={selected ? selectedCell.value : undefined}
            setExpandModal={setExpandModal}
            setIsFocused={setIsFocused}
            rowData={
              SELECT_COLUMN_TYPES.includes(viewportColumn.column.kind) &&
              viewportColumn.column.kindOptions &&
              viewportColumn.column.kindOptions.tableOptions &&
              viewportColumn.column.kindOptions.tableOptions.dependencies &&
              viewportColumn.column.kindOptions.tableOptions.dependencies.length > 0
                ? row.rowData
                : undefined
            }
            columnValuesCount={hasActiveValidation ? spreadsheetData.columnValuesCount : undefined}
            column={viewportColumn.column}
            type={spreadsheetData.viewDetails.type}
            colourSettings={spreadsheetData.userConfiguration.colourSettings}
            startRowId={spreadsheetData.rows[0]?.publicId}
            finalRowId={spreadsheetData.rows[spreadsheetData?.rows.length - 1]?.publicId}
            finalRowNumber={spreadsheetData.rows.length}
            startColumnId={spreadsheetData.viewDetails.columns[0]?.publicId}
            finalColumnId={spreadsheetData.viewDetails.columns[spreadsheetData.viewDetails.columns.length - 1].publicId}
            finalColumnNumber={spreadsheetData.viewDetails.columns.length}
          />
        )
      })}
    </div>
  )
}

export default React.memo(Row)
