import React, { useState } from 'react'
import Button from 'components/button'
import { IntegrationProviders } from 'app-constants'
export const BUTTON_WIDTH = '185px'

interface IntegrationProviderViewProps {
  provider: IntegrationProviders
  logo: string
  connected: boolean
  redirectUrl: string
  connectingAccount: boolean
  disconnectAccount: (provider: IntegrationProviders) => void
}

const IntegrationProviderView: React.FC<IntegrationProviderViewProps> = ({
  provider,
  logo,
  connected,
  redirectUrl,
  connectingAccount,
  disconnectAccount
}) => {
  const [region, selectRegion] = useState<string>('')

  const ACONEX_REGIONS: Record<string, any> = {
    'canada-ca1': { name: 'Canada (CA1)', url: 'ca1.aconex.com' },
    'canada-uk1': {
      name: 'Canada (UK1)',
      url: 'uk1.aconex.co.uk'
    },
    'uslatin-us1': {
      name: 'US & Latin America (US1)',
      url: 'us1.aconex.com'
    },

    'uslatin-us2': {
      name: 'US GOV (US2)',
      url: 'us2.aconex.com'
    },
    'uk-uk1': {
      name: 'United Kingdom (UK1)',
      url: 'uk1.aconex.co.uk'
    },
    'europe-eu1': {
      name: 'Europe (EU1)',
      url: 'eu1.aconex.com'
    },
    'europe:uk1': {
      name: 'Europe (UK1)',
      url: 'uk1.aconex.co.uk'
    },
    'saudi-ksa1': {
      name: 'Saudi Arabia (KSA1)',
      url: 'ksa1.aconex.com'
    },
    'middle-mea': {
      name: 'Rest of Middle East (MEA)',
      url: 'mea.aconex.com'
    },
    'africa-uk1': {
      name: 'Africa (UK1)',
      url: 'uk1.aconex.co.uk'
    },
    'cn1-cn1': {
      name: 'CN1',
      url: 'cn1.aconexasia.com/'
    },
    'asia-hk1': {
      name: 'Rest of Asia (HK1)',
      url: 'hk1.aconex.com'
    },
    'aus-au1': {
      name: 'Australia/NZ (AU1)',
      url: 'au1.aconex.com'
    },
    'aus-au2': {
      name: 'AU GOV (AU2)',
      url: 'au2.aconex.com'
    },
    'aldar-eu1': {
      name: 'ALDAR (EU1)',
      url: 'eu1.aconex.com'
    },
    test: {
      name: 'Test',
      url: 'ea1.aconex.com'
    }
  }

  const displayRegions = provider === IntegrationProviders.ACONEX || provider === IntegrationProviders.REVIZTO

  const buildRedirectUrl = () => {
    if (provider === IntegrationProviders.ACONEX) {
      if (region) {
        return `${redirectUrl.replace('$host$', getRegions()[region].url)}&state=${getRegions()[region].url}`
      }
      return ''
    }
    return redirectUrl
  }

  const getRegions = (): Record<string, any> => {
    if (provider === IntegrationProviders.ACONEX) {
      return ACONEX_REGIONS
    }
    return {}
  }

  const getLabel = (regionKey: string) => {
    if (provider === IntegrationProviders.ACONEX) {
      return getRegions()[regionKey].name
    } else return ''
  }

  return (
    <div>
      <div className="flex flex-column rounded heavy-shadow w-full p-10px flex-wrap my-20px border-1px border-solid border-black">
        <div className="flex flex-row items-center">
          <img src={logo} alt={`${provider} logo`} style={{ width: '150px', marginRight: 'auto' }} />
          {!connected && displayRegions && (
            <div className="flex flex-row items-center" style={{ width: '300px' }}>
              Region:
              <select
                style={{ marginLeft: '20px' }}
                value={region}
                onChange={(event) => selectRegion(event.target.value)}
              >
                <option value="">{`Select ${provider} Region`}</option>
                {Object.keys(getRegions()).map((regionKey: string, regionIndex: number) => (
                  <option key={regionIndex} value={regionKey}>
                    {getLabel(regionKey)}
                  </option>
                ))}
              </select>
            </div>
          )}

          {!connected ? (
            provider !== IntegrationProviders.ACONEX || region !== '' ? (
              <a
                href={buildRedirectUrl()}
                style={{
                  cursor: connectingAccount ? 'not-allowed' : 'auto',
                  pointerEvents: connectingAccount ? 'none' : 'auto'
                }}
              >
                <Button style={{ width: BUTTON_WIDTH }} disabled={connectingAccount}>
                  Connect Account
                </Button>
              </a>
            ) : null
          ) : (
            <Button
              internalType="danger"
              style={{ width: BUTTON_WIDTH }}
              onClick={() => disconnectAccount(provider)}
              disabled={connectingAccount}
            >
              Disconnect Account
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default IntegrationProviderView
