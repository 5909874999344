import React, { useState, useCallback, useEffect, useRef } from 'react'
import Button from 'components/button'
import { IContextMenuState, OptionProps } from 'types'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import { ChevronDown, ChevronUp } from 'components/icons'
import SelectMenu from 'components/select/components/menu'
import { cancelTimeout, requestTimeout, TimeoutID } from 'helpers/timer'

export interface SelectProps {
  options: Array<OptionProps>
  onOptionClick: (option: string) => void
  loading?: boolean
  disabled?: boolean
  multiselect?: boolean
  optionsSelected?: string[]
  groupBy?: boolean
  setOptionsSelected?: (selectedOptions: string[]) => void
  error?: string
  info?: string
  freeEntry?: boolean
  customAddText?: string
  customRemoveText?: string
  switchColours?: boolean
  defaultOpen?: boolean
  placeholder?: string
  label?: string
}

const Select: React.FC<SelectProps> = ({
  options,
  onOptionClick,
  disabled,
  loading,
  multiselect,
  optionsSelected,
  groupBy,
  setOptionsSelected,
  error,
  info,
  freeEntry,
  customAddText,
  customRemoveText,
  switchColours,
  defaultOpen,
  placeholder,
  label
}) => {
  const [selectMenuOpen, setSelectMenuOpen] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [userEntry, setUserEntry] = useState<string>()
  const [width, setWidth] = useState<number>(200)

  const freeEntryTimeout = useRef<TimeoutID | null>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (options && !multiselect && optionsSelected && optionsSelected.length == 1) {
      const selectedOption = options.find((option) => option.value === optionsSelected[0])

      if (selectedOption) {
        setUserEntry(selectedOption.label)
      } else {
        setUserEntry(optionsSelected[0])
      }
    }
  }, [options, optionsSelected])

  useEffect(() => {
    if (defaultOpen && buttonRef.current) {
      buttonRef.current.click()
    }
  }, [])

  const div = useCallback((node) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width)
    }
  }, [])

  const handleUserEntryChange = (input: string) => {
    setUserEntry(input)
    if (freeEntryTimeout.current !== null) cancelTimeout(freeEntryTimeout.current)

    freeEntryTimeout.current = requestTimeout(() => {
      onOptionClick(input)
    }, 1000)
  }

  return (
    <div ref={div} id="select-component">
      {label && (
        <div className="text-secondary text-sm" style={{ marginBottom: '5px' }}>
          {label}
        </div>
      )}
      <Button
        innerRef={buttonRef}
        className="w-full flex items-center"
        style={{ height: '45px', padding: '11px 16px' }}
        internalType="outline"
        disabled={disabled}
        onClick={(event) =>
          setSelectMenuOpen({
            open: true,
            top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
            left: `${event.currentTarget.getBoundingClientRect().left}px`,
            right: 'auto',
            bottom: 'auto'
          })
        }
      >
        <div className="flex overflow-hidden w-full h-full font-normal" style={{}}>
          {loading && (
            <div className="p-1" style={{ minWidth: '100px' }}>
              <div className="spin" style={{ height: '24px', width: '24px' }} />
            </div>
          )}
          {optionsSelected &&
            optionsSelected.length > 0 &&
            !loading &&
            optionsSelected.map((key: string, index: number) => {
              const selectedOption = options.find((option) => option.value === key)
              if (selectedOption && !freeEntry) {
                return (
                  <div
                    key={index}
                    className={`${
                      multiselect ? 'border-2px border-solid border-grey rounded mr-2 p-1 truncate bg-light-grey' : ''
                    }`}
                    style={{ minWidth: multiselect ? '100px' : '' }}
                  >
                    {selectedOption && selectedOption.label}
                  </div>
                )
              } else if (freeEntry && !multiselect) {
                return (
                  <input
                    key={index}
                    className="w-full border-0"
                    style={{ outline: 'none', padding: '0px' }}
                    value={userEntry}
                    onChange={(event) => handleUserEntryChange(event.target.value)}
                  />
                )
              }
            })}
          {(!optionsSelected || optionsSelected.length === 0) && !loading && (
            <div>{placeholder || 'None selected'}</div>
          )}
        </div>
        <div className="ml-auto font-normal">{selectMenuOpen.open ? <ChevronUp /> : <ChevronDown />}</div>
      </Button>
      {info && (
        <div className="text-secondary text-sm" style={{ marginTop: '5px' }}>
          {info}
        </div>
      )}
      {error && (
        <div className="text-red text-sm" style={{ marginTop: '5px' }}>
          {error}
        </div>
      )}

      {selectMenuOpen.open && (
        <SelectMenu
          id="select-options"
          menuState={selectMenuOpen}
          multiselect={multiselect}
          setMenuState={setSelectMenuOpen}
          loading={loading}
          options={options}
          optionsSelected={optionsSelected}
          onOptionClick={onOptionClick}
          groupBy={groupBy}
          width={width}
          setOptionsSelected={setOptionsSelected}
          customAddText={customAddText}
          customRemoveText={customRemoveText}
          switchColours={switchColours}
        />
      )}
    </div>
  )
}

export default Select
