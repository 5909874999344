import React from 'react'
import constants from 'style/constants.module.scss'

const Beta: React.FC = () => {
  return (
    <div
      className="flex items-center justify-center text-xs h-full box-border font-bold"
      style={{
        height: '20px',
        textAlign: 'center',
        color: 'white',
        marginLeft: '5px',
        padding: '4px',
        backgroundColor: constants.red,
        borderRadius: '5px'
      }}
    >
      Beta
    </div>
  )
}

export default Beta
