import React, { useRef, useState } from 'react'
import Menu, { MenuProps } from 'components/menu'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import ResourceType from 'components/resourceType'
import useApplicationStore from 'hooks/application'
import { cancelTimeout, requestTimeout, TimeoutID } from 'helpers/timer'
import { updateTable, updateView } from 'components/spreadsheet/contexts/data/actions'
import useProject from 'hooks/project'
import { Colour, Copy, Csv, Delete, Join, Plus, Sync, People, Restore } from 'components/icons'
import Toggle from 'components/toggle'
import Select from 'components/select'
import { ViewTypes, ViewTypesNames } from 'components/spreadsheet/constants/const'
import api from 'helpers/api'

interface SettingsMenuProps extends MenuProps {
  setDeleteTableModal: (open: boolean) => void
  setDeleteViewModal: (open: boolean) => void
  setJoinsModal: (open: boolean) => void
  setDuplicateTableModal: (open: boolean) => void
  setDuplicateViewModal: (open: boolean) => void
}

const SettingsMenu: React.FC<SettingsMenuProps> = ({
  id,
  menuState,
  setMenuState,
  width,
  setDeleteTableModal,
  setDeleteViewModal,
  setJoinsModal,
  setDuplicateTableModal,
  setDuplicateViewModal
}) => {
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()

  const [downloadingCsv, setDownloadingCsv] = useState<boolean>(false)
  const [downloadingChartImage, setDownloadingChartImage] = useState<boolean>(false)

  const changeTimeout = useRef<TimeoutID | null>(null)
  const viewChangeTimeout = useRef<TimeoutID | null>(null)

  const handleUpdateSuccess = () => {
    setSnackbarMessage({ status: 'success', message: 'Save has been successful!' })
  }

  const handleUpdateFailure = (error: any) => {
    displayErrorMessage(error)
  }

  const handleTableChange = (
    field: 'name' | 'type' | 'keepValidationsInSync' | 'keepColoursInSync' | 'allowDuplication' | 'isDeleted',
    value: string | string[] | boolean
  ) => {
    if (changeTimeout.current !== null) cancelTimeout(changeTimeout.current)

    changeTimeout.current = requestTimeout(() => {
      updateTable(
        field,
        spreadsheetData,
        setSpreadsheetData,
        value,
        project.publicId,
        handleUpdateSuccess,
        handleUpdateFailure
      )
    }, 1000)
  }

  const handleViewChange = (
    field: 'name' | 'type' | 'disableNewRow' | 'allowContributorDelete',
    value: string | number | boolean
  ) => {
    if (viewChangeTimeout.current !== null) cancelTimeout(viewChangeTimeout.current)

    viewChangeTimeout.current = requestTimeout(() => {
      updateView(
        field,
        spreadsheetData,
        setSpreadsheetData,
        spreadsheetData.viewDetails.publicId,
        value,
        project.publicId,
        handleUpdateSuccess,
        handleUpdateFailure
      )
    }, 1000)
  }

  const handleClickDownloadCsv = async () => {
    if (downloadingCsv) {
      return
    }

    if (spreadsheetData) {
      setDownloadingCsv(true)
      await api
        .getTableViewCSV(
          spreadsheetData.viewDetails.publicId,
          `${spreadsheetData.tableDetails.name} - ${spreadsheetData.viewDetails.name}.csv`,
          spreadsheetData.processId,
          spreadsheetData.userConfiguration.filterSettings,
          spreadsheetData.viewDetails.displayCommentRows
        )
        .catch((error) => {
          displayErrorMessage(error)
        })
      setDownloadingCsv(false)
    }
  }

  const handleClickDownloadChartImage = async () => {
    if (downloadingChartImage) {
      return
    }

    if (spreadsheetData) {
      const chartSettings =
        spreadsheetData.userConfiguration.chartSettings === null
          ? spreadsheetData.viewDetails.chartSettings === null
            ? { chartType: null, showLegend: true, showValues: false, columnLabelId: '', columnValueId: '' }
            : spreadsheetData.viewDetails.chartSettings
          : spreadsheetData.userConfiguration.chartSettings

      if (chartSettings.chartType === null) {
        setSnackbarMessage({ status: 'error', message: 'Select a chart type' })
        return
      } else if (chartSettings.columnLabelId === '') {
        setSnackbarMessage({ status: 'error', message: 'Select a column as label for the chart.' })
        return
      } else if (chartSettings.columnValueId === '') {
        setSnackbarMessage({ status: 'error', message: 'Select a column as value for the chart.' })
        return
      }
      setDownloadingChartImage(true)
      api
        .downloadChartImage(
          spreadsheetData.viewDetails.publicId,
          `${spreadsheetData.tableDetails.name} - ${spreadsheetData.viewDetails.name}.png`,
          chartSettings
        )
        .then(() => {
          setDownloadingChartImage(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setDownloadingChartImage(false)
        })
    }
  }

  const restoreTable = () => {
    api.restoreTable(spreadsheetData.tableDetails.publicId, { context: { projectId: project.publicId } }).then(() => {
      window.location.reload()
    })
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width} zIndex={3000}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '0px' }}>
        {/* Table Settings */}
        <div className="w-full bg-light-grey font-bold p-10px text-base">Table Settings</div>
        <div className="p-10px border-b-1px border-solid border-grey">
          {!spreadsheetData.tableDetails.isDeleted && (
            <li className="w-full flex items-center" style={{ marginBottom: '10px' }}>
              <span style={{ marginRight: '5px', width: '75px' }}>Table Name:</span>
              <input
                defaultValue={spreadsheetData.tableDetails.name}
                onChange={(event) => handleTableChange('name', event.target.value)}
                disabled={!spreadsheetData.isAdmin}
              />
            </li>
          )}

          {spreadsheetData.isAdmin && (
            <li className="w-full flex items-center" style={{ marginBottom: '10px' }}>
              <span style={{ marginRight: '5px', width: '75px' }}>Table Type:</span>
              <div style={{ flex: 1 }}>
                <ResourceType
                  handleChange={(value: string | null) =>
                    value ? handleTableChange('type', value) : handleTableChange('type', '')
                  }
                  defaultValue={spreadsheetData.tableDetails.type}
                  helperText=""
                />
              </div>
            </li>
          )}

          {spreadsheetData.isAdmin && (
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                onClick={() => setJoinsModal(true)}
                title="Bring data from other tables into this table with a join."
              >
                <Join />
                <span className="ml-10px">Table Look Ups</span>
              </div>
            </li>
          )}

          {spreadsheetData.isAdmin && (
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                title="This will keep column validations in sync across views. This will only
                  synchronise updates so any initially set values will remain. To trigger the sync just update the column
                  validations in one of the views"
              >
                <Sync />
                <span className="ml-10px">Column Validation Updates Sync Across Views</span>
                <Toggle
                  toggled={spreadsheetData.tableDetails.keepValidationsInSync}
                  onToggle={(toggle) => {
                    updateTable(
                      'keepValidationsInSync',
                      spreadsheetData,
                      setSpreadsheetData,
                      toggle,
                      project.publicId,
                      handleUpdateSuccess,
                      handleUpdateFailure
                    )
                  }}
                />
              </div>
            </li>
          )}
          {spreadsheetData.isAdmin && (
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                title="This will keep colouring in sync across views. This will only
                  synchronise updates so any initially set values will remain. To trigger the sync just update the colours in one of the views."
              >
                <Colour />
                <span className="ml-10px">Colours In Sync Across Views</span>
                <Toggle
                  toggled={spreadsheetData.tableDetails.keepColoursInSync}
                  onToggle={(toggle) => {
                    updateTable(
                      'keepColoursInSync',
                      spreadsheetData,
                      setSpreadsheetData,
                      toggle,
                      project.publicId,
                      handleUpdateSuccess,
                      handleUpdateFailure
                    )
                  }}
                />
              </div>
            </li>
          )}
          {spreadsheetData.isAdmin && (
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                title="This will allow any user to duplicate the entire table, including all data, all views, joins and permissions."
              >
                <Copy />
                <span className="ml-10px">Allow Table Duplication</span>
                <Toggle
                  toggled={spreadsheetData.tableDetails.allowDuplication}
                  onToggle={(toggle) => {
                    updateTable(
                      'allowDuplication',
                      spreadsheetData,
                      setSpreadsheetData,
                      toggle,
                      project.publicId,
                      handleUpdateSuccess,
                      handleUpdateFailure
                    )
                  }}
                />
              </div>
            </li>
          )}

          {!spreadsheetData.tableDetails.isDeleted && spreadsheetData.tableDetails.allowDuplication && (
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                onClick={() => setDuplicateTableModal(true)}
              >
                <Copy />
                <span className="ml-10px">Duplicate Entire Table</span>
              </div>
            </li>
          )}

          {!spreadsheetData.tableDetails.isDeleted && spreadsheetData.isAdmin && (
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                onClick={() => setDeleteTableModal(true)}
              >
                <Delete />
                <span className="ml-10px">Archive Table</span>
              </div>
            </li>
          )}

          {spreadsheetData.tableDetails.isDeleted &&
            (spreadsheetData.isAdmin || spreadsheetData.tableDetails.permissionLevel === 4) && (
              <li>
                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                  onClick={() => restoreTable()}
                >
                  <Restore />
                  <span className="ml-10px">Restore Table</span>
                </div>
              </li>
            )}
        </div>

        {/* View Settings */}
        {!spreadsheetData.tableDetails.isDeleted && (
          <div>
            <div className="w-full bg-light-grey font-bold p-10px text-base">View Settings</div>
            <div className="p-10px border-b-1px border-solid border-grey">
              <li className="w-full flex items-center" style={{ marginBottom: '10px' }}>
                <span style={{ marginRight: '5px', width: '75px' }}>View Name:</span>
                <input
                  defaultValue={spreadsheetData.viewDetails.name}
                  disabled={spreadsheetData.viewDetails.isDefault || !spreadsheetData.isAdmin}
                  onChange={(event) => handleViewChange('name', event.target.value)}
                />
              </li>
              {spreadsheetData.isAdmin && (
                <li className="w-full flex items-center" style={{ marginBottom: '10px' }}>
                  <span style={{ marginRight: '5px', width: '75px' }}>View Type:</span>
                  <div style={{ flex: 1 }}>
                    <Select
                      options={[
                        { label: ViewTypesNames[ViewTypes.SPREADSHEET], value: String(ViewTypes.SPREADSHEET) },
                        { label: ViewTypesNames[ViewTypes.FORM], value: String(ViewTypes.FORM) },
                        { label: ViewTypesNames[ViewTypes.CHART], value: String(ViewTypes.CHART) }
                      ]}
                      onOptionClick={(option) =>
                        updateView(
                          'type',
                          spreadsheetData,
                          setSpreadsheetData,
                          spreadsheetData.viewDetails.publicId,
                          parseInt(option),
                          project.publicId,
                          handleUpdateSuccess,
                          handleUpdateFailure
                        )
                      }
                      optionsSelected={[spreadsheetData.viewDetails.type.toString()]}
                    />
                  </div>
                </li>
              )}

              {spreadsheetData.viewDetails.type !== ViewTypes.FORM && (
                <li>
                  <div
                    className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                    style={{
                      padding: '0 10px',
                      height: '32px',
                      lineHeight: '32px',
                      cursor:
                        !spreadsheetData.processId && spreadsheetData.userConfiguration.unsavedChanges
                          ? 'not-allowed'
                          : 'pointer'
                    }}
                    onClick={() => {
                      const isDisabled = !spreadsheetData.processId && spreadsheetData.userConfiguration.unsavedChanges
                      if (!isDisabled) {
                        spreadsheetData.viewDetails.type === ViewTypes.SPREADSHEET
                          ? handleClickDownloadCsv()
                          : handleClickDownloadChartImage()
                      } else {
                        setSnackbarMessage({
                          status: 'error',
                          message: 'Please save changes to the view before downloading.'
                        })
                      }
                    }}
                    data-cy={`${
                      spreadsheetData.viewDetails.type === ViewTypes.SPREADSHEET ? 'download-csv' : 'download-png'
                    }`}
                  >
                    {downloadingCsv ? <div className="spin" style={{ height: 10, width: 10 }} /> : <Csv />}
                    <span className="ml-10px">{`Download as ${
                      spreadsheetData.viewDetails.type === ViewTypes.SPREADSHEET ? 'CSV' : 'PNG'
                    }`}</span>
                  </div>
                </li>
              )}

              {spreadsheetData.isAdmin && (
                <li>
                  <div
                    className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                    style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                    onClick={() => setDuplicateViewModal(true)}
                  >
                    <Copy />
                    <span className="ml-10px">Duplicate This View</span>
                  </div>
                </li>
              )}
              {spreadsheetData.isAdmin && (
                <li>
                  <div
                    className={`relative flex items-center rounded text-primary select-none transition-all truncate ${
                      spreadsheetData.tableDetails.isSynced
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer hover-bg-light-grey'
                    }`}
                    style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                  >
                    <Plus />
                    <span className="ml-10px">Disable Adding New Row in View</span>
                    <Toggle
                      toggled={spreadsheetData.viewDetails.disableNewRow || spreadsheetData.tableDetails.isSynced}
                      onToggle={(toggle) => {
                        updateView(
                          'disableNewRow',
                          spreadsheetData,
                          setSpreadsheetData,
                          spreadsheetData.viewDetails.publicId,
                          toggle,
                          project.publicId,
                          handleUpdateSuccess,
                          handleUpdateFailure
                        )
                      }}
                      disabled={spreadsheetData.tableDetails.isSynced}
                    />
                  </div>
                </li>
              )}
              {spreadsheetData.isAdmin && (
                <li>
                  <div
                    className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                    style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                  >
                    <People />
                    <span className="ml-10px">Allow Contributors To Delete Rows in View</span>
                    <Toggle
                      toggled={spreadsheetData.viewDetails.allowContributorDelete}
                      onToggle={(toggle) => {
                        updateView(
                          'allowContributorDelete',
                          spreadsheetData,
                          setSpreadsheetData,
                          spreadsheetData.viewDetails.publicId,
                          toggle,
                          project.publicId,
                          handleUpdateSuccess,
                          handleUpdateFailure
                        )
                      }}
                    />
                  </div>
                </li>
              )}
              {spreadsheetData.isAdmin && (
                <li>
                  <div
                    className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                    style={{
                      padding: '0 10px',
                      height: '32px',
                      lineHeight: '32px',
                      cursor: spreadsheetData.viewDetails.isDefault ? 'not-allowed' : 'pointer'
                    }}
                    onClick={() => {
                      if (spreadsheetData.viewDetails.isDefault) {
                        return
                      }
                      setDeleteViewModal(true)
                    }}
                    title={
                      spreadsheetData.viewDetails.isDefault
                        ? 'You are unable to delete the default view of a table.'
                        : ''
                    }
                  >
                    <Delete />
                    <span className="ml-10px">Delete View</span>
                  </div>
                </li>
              )}
            </div>
          </div>
        )}
      </div>
    </Menu>
  )
}

export default SettingsMenu
