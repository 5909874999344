import React, { useState, useRef, useEffect } from 'react'
import Modal, { ModalProps } from 'components/modal'
import { cancelTimeout, requestTimeout, TimeoutID } from 'helpers/timer'
import { updateColumn } from 'components/spreadsheet/contexts/data/actions'
import { useApplicationStore } from 'hooks/application'
import Checkbox from 'components/checkbox'
import Editor from 'components/editor'
import {
  ALL_TYPES,
  SELECT_COLUMN_TYPES,
  BLOCK_SELECT_OPTION_TYPES,
  BLOCK_GROUP_BY,
  ALLOWED_VIEW_TYPES_ON_SELECT_OPTIONS,
  COLUMN_TYPE_NAMES,
  TEXT_COLUMN_TYPES,
  USER_COLUMN_TYPES,
  DATE_COLUMN_TYPES,
  IntegrationSyncTypes,
  NUMBER_COLUMN_TYPES
} from 'components/spreadsheet/constants/const'
import { IColumnTypes, sortDirections } from 'components/spreadsheet/types'
import {
  ITable,
  ITableView,
  SelectKindOptions,
  ITableViewColumn,
  SelectDependencyColumn,
  EditorContent,
  ISummaryUser
} from 'types'
import { Cross, Delete, Pencil, Save, Arrow, AI } from 'components/icons'
import { useProject } from 'hooks/project'
import api, { APIError } from 'helpers/api'
import CodeMirror from 'react-codemirror'
import 'codemirror/mode/python/python'
import 'codemirror/addon/fold/foldcode.js'
import 'codemirror/addon/fold/foldgutter.js'
import 'codemirror/addon/fold/brace-fold'
import 'codemirror/addon/fold/xml-fold'
import 'codemirror/addon/fold/indent-fold'
import 'codemirror/addon/fold/markdown-fold'
import 'codemirror/addon/fold/comment-fold'
import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/theme/material-darker.css'
import ColumnKindModal from 'components/spreadsheet/components/modal/views/columnkind'
import { isEditorEmpty } from 'components/spreadsheet/helpers/functions'
import { FixedSizeList } from 'react-window'
import { capitaliseFirstLetter, getProjectTables } from 'helpers/utils'
import Button from 'components/button'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { getFilterValues } from 'components/spreadsheet/helpers/filtering'
import Select from 'components/select'
import constants from 'style/constants.module.scss'
import IconSelector from 'components/spreadsheet/components/cell/components/icon'
import Beta from 'components/beta'
import { CompactPicker } from 'react-color'

export interface EditColumnModalProps extends ModalProps {
  initialColumnId: string | undefined
  initialColumnNumber: number | undefined
}

const EditColumnModal: React.FC<EditColumnModalProps> = ({
  id,
  open,
  setOpen,
  initialColumnId,
  initialColumnNumber
}) => {
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const [columnId, setColumnId] = useState<string>()
  const [columnNumber, setColumnNumber] = useState<number>()
  const [aiScriptText, setAiScriptText] = useState<string>('')
  const [isAiScriptLoading, setIsAiScriptLoading] = useState<boolean>(false)
  const [aiScriptResponse, setAiScriptResponse] = useState<string | undefined>()

  const { spreadsheetData, setSpreadsheetData } = useDataContext()

  const column = spreadsheetData.viewDetails.columns.find((col) => col.publicId === columnId)

  const {
    name,
    publicId,
    description,
    kind,
    script,
    scriptEnabled,
    validationMessage,
    stringValidation,
    hardValidation,
    validationNoBlanks,
    validationNoDuplicates,
    isJoined
  } = column || {}

  const [scriptError, setScriptError] = useState<string>('')

  const [tables, setTables] = useState<ITable[]>()
  const [newScript, setNewScript] = useState<string>('')
  const [selectedKind, setSelectedKind] = useState<IColumnTypes>()
  const [processing, setProcessing] = useState<boolean>(false)
  const stringValidationTimeout = useRef<TimeoutID | null>(null)
  const validationMessageTimeout = useRef<TimeoutID | null>(null)
  const nameTimeout = useRef<TimeoutID | null>(null)
  const descriptionTimeout = useRef<TimeoutID | null>(null)
  const dateFormatTimeout = useRef<TimeoutID | null>(null)
  const exportWidthTimeout = useRef<TimeoutID | null>(null)
  const codeMirrorRef = useRef<any>(null)
  const [changeColumnKindModalState, setChangeColumnKindModalState] = useState<boolean>(false)
  const [showDependencyColumn, setShowDependencyColumn] = useState<boolean>(false)
  const [showDependencyJoinColumn, setShowDependencyJoinColumn] = useState<boolean>(false)
  const [canAddDependency, setCanAddDependency] = useState<boolean>(false)
  const [dependencyColumn, setDependencyColumn] = useState<SelectDependencyColumn>({
    columnId: '',
    columnJoinId: ''
  })
  const [regexError, setRegexError] = useState<string>()

  // Select properties
  const { project } = useProject()
  const [selectedTableViews, setSelectedTableViews] = useState<ITableView[]>()
  const [selectedTableViewColumns, setSelectedTableViewColumns] = useState<ITableViewColumn[]>()
  const [manualOption, setManualOption] = useState<string>()
  const [manualOptionSortDirection, setManualOptionSortDirection] = useState<sortDirections>()
  const [editingOption, setEditingOption] = useState<number>()
  const [currentKindOptions, setCurrentKindOptions] = useState<SelectKindOptions | undefined>(
    column ? column.kindOptions : undefined
  )

  // Loaders
  const [noAccess, setNoAccess] = useState<boolean>(false)

  useEffect(() => {
    if (script) setNewScript(script)
  }, [script])

  useEffect(() => {
    setColumnId(initialColumnId)
    setColumnNumber(initialColumnNumber)
  }, [])

  useEffect(() => {
    if (currentKindOptions?.tableOptions) {
      if (currentKindOptions.tableOptions?.tableId) {
        setProcessing(true)
        api
          .getTableViews(currentKindOptions.tableOptions.tableId, { ignoreColumns: true })
          .then((response) => {
            setSelectedTableViews(getViews(response.data))
            if (currentKindOptions.tableOptions?.viewId) {
              api
                .getTableView(currentKindOptions.tableOptions.viewId, { ignoreCachedOptions: true })
                .then((response) => {
                  setSelectedTableViewColumns(response.data.columns)
                  setProcessing(false)
                })
                .catch(() => {
                  setProcessing(false)
                  setSelectedTableViewColumns([])
                  setSelectedTableViewColumns([])
                })
            } else {
              setProcessing(false)
            }
          })
          .catch((error) => {
            if (error.status && error.status === 403) {
              setNoAccess(true)
            }
            setSelectedTableViews([])
            setProcessing(false)
            setSelectedTableViewColumns([])
          })

        if (currentKindOptions.tableOptions?.dependencies && currentKindOptions.tableOptions.dependencies.length > 0) {
          setShowDependencyColumn(true)
        }
      }
    }
    if (currentKindOptions?.manualOptions) {
      const manualOptionsUpperCase = currentKindOptions!.manualOptions.map((item) => item.toUpperCase())
      const manualOptionsAsc = [...manualOptionsUpperCase]
      manualOptionsAsc.sort()
      if (manualOptionsUpperCase.every((element, index) => element === manualOptionsAsc[index])) {
        setManualOptionSortDirection(sortDirections.asc)
      } else {
        const manualOptionsDesc = [...manualOptionsAsc]
        manualOptionsDesc.reverse()
        if (manualOptionsUpperCase.every((element, index) => element === manualOptionsDesc[index])) {
          setManualOptionSortDirection(sortDirections.desc)
        } else {
          setManualOptionSortDirection(undefined)
        }
      }
    }
  }, [columnId, currentKindOptions])

  useEffect(() => {
    if (open && project.publicId !== '' && kind && SELECT_COLUMN_TYPES.includes(kind)) {
      getProjectTables(project.publicId)
        .then((tables) => {
          setTables(tables)
        })
        .catch((error) => displayErrorMessage(error))
    }
  }, [open, project.publicId, kind])

  useEffect(() => {
    const columnFound = spreadsheetData.viewDetails.columns.find((col) => col.publicId === columnId)
    if (kind && SELECT_COLUMN_TYPES.includes(kind) && columnFound?.kindOptions) {
      setCurrentKindOptions(columnFound.kindOptions)
    } else {
      setCurrentKindOptions(undefined)
    }
  }, [
    kind,
    columnNumber !== undefined &&
      spreadsheetData.viewDetails.columns[columnNumber] &&
      spreadsheetData.viewDetails.columns[columnNumber].kindOptions
  ])

  const handleNameChange = (name: string) => {
    if (nameTimeout.current !== null) cancelTimeout(nameTimeout.current)

    nameTimeout.current = requestTimeout(() => {
      if (publicId) {
        setProcessing(true)
        updateColumn(
          project.publicId,
          'name',
          spreadsheetData,
          setSpreadsheetData,
          publicId,
          name,
          handleUpdateColumnSuccess,
          (error) => {
            handleUpdateColumnError(error)
          }
        )
      }
    }, 1000)
  }

  const handleStringValidationChange = (regex: string) => {
    if (stringValidationTimeout.current !== null) cancelTimeout(stringValidationTimeout.current)

    try {
      new RegExp(regex)
      if (regexError) {
        setRegexError(undefined)
      }
    } catch (e) {
      if (e instanceof SyntaxError) {
        setRegexError(e.message)
      }
      return
    }

    stringValidationTimeout.current = requestTimeout(() => {
      if (publicId) {
        setProcessing(true)
        updateColumn(
          project.publicId,
          'stringValidation',
          spreadsheetData,
          setSpreadsheetData,
          publicId,
          regex,
          handleUpdateColumnSuccess,
          (error) => {
            handleUpdateColumnError(error)
          }
        )
      }
    }, 1000)
  }

  const handleValidationMessageChange = (message: string) => {
    if (validationMessageTimeout.current !== null) cancelTimeout(validationMessageTimeout.current)

    validationMessageTimeout.current = requestTimeout(() => {
      if (publicId) {
        setProcessing(true)
        updateColumn(
          project.publicId,
          'validationMessage',
          spreadsheetData,
          setSpreadsheetData,
          publicId,
          message,
          handleUpdateColumnSuccess,
          (error) => {
            handleUpdateColumnError(error)
          }
        )
      }
    }, 1000)
  }

  const handleScriptChange = () => {
    if (publicId) {
      updateColumn(
        project.publicId,
        'script',
        spreadsheetData,
        setSpreadsheetData,
        publicId,
        newScript,
        () => {
          setSnackbarMessage({ status: 'success', message: 'The column was updated successfully!' })
          setProcessing(false)
          setScriptError('')
          setOpen(false)
        },
        (error) => {
          if (error instanceof APIError) {
            if (error.rawCode === 422) {
              handleUpdateColumnError('Something is wrong with your script')
              setScriptError(error.message)
            } else {
              handleUpdateColumnError(error)
            }
          } else {
            handleUpdateColumnError(error)
          }
        },
        {
          runScriptOnAllCells: true
        }
      )
    }
  }

  const handleScriptEnabledChange = (checked: boolean) => {
    if (isJoined || !publicId || checked === undefined) return
    updateColumn(
      project.publicId,
      'scriptEnabled',
      spreadsheetData,
      setSpreadsheetData,
      publicId,
      checked,
      handleUpdateColumnSuccess,
      handleUpdateColumnError
    )
  }

  const handleHardValidationChange = (checked: boolean) => {
    if (publicId && checked !== undefined) {
      updateColumn(
        project.publicId,
        'hardValidation',
        spreadsheetData,
        setSpreadsheetData,
        publicId,
        checked,
        handleUpdateColumnSuccess,
        handleUpdateColumnError
      )
    }
  }

  const handleDescriptionChange = (description: EditorContent) => {
    if (descriptionTimeout.current !== null) cancelTimeout(descriptionTimeout.current)

    descriptionTimeout.current = requestTimeout(() => {
      if (publicId) {
        setProcessing(true)
        updateColumn(
          project.publicId,
          'description',
          spreadsheetData,
          setSpreadsheetData,
          publicId,
          isEditorEmpty(description) ? null : description,
          handleUpdateColumnSuccess,
          (error) => {
            handleUpdateColumnError(error)
          }
        )
      }
    }, 1000)
  }

  const handleOnDateFormatChange = (dateFormat: string) => {
    if (dateFormatTimeout.current !== null) cancelTimeout(dateFormatTimeout.current)

    dateFormatTimeout.current = requestTimeout(() => {
      if (publicId) {
        updateColumn(
          project.publicId,
          'dateFormat',
          spreadsheetData,
          setSpreadsheetData,
          publicId,
          dateFormat,
          handleUpdateColumnSuccess,
          (error) => {
            handleUpdateColumnError(error)
          }
        )
      }
    }, 1000)
  }

  const handleExportWidthChange = (width: number) => {
    if (exportWidthTimeout.current !== null) cancelTimeout(exportWidthTimeout.current)

    exportWidthTimeout.current = requestTimeout(() => {
      if (publicId) {
        updateColumn(
          project.publicId,
          'exportWidth',
          spreadsheetData,
          setSpreadsheetData,
          publicId,
          width,
          handleUpdateColumnSuccess,
          (error) => {
            handleUpdateColumnError(error)
          }
        )
      }
    }, 1000)
  }

  const handleUpdateColumnSuccess = () => {
    setSnackbarMessage({ status: 'success', message: 'The column was updated successfully!' })
    setProcessing(false)
    setScriptError('')
  }

  const handleUpdateColumnError = (error: any) => {
    setProcessing(false)
    setSelectedKind(undefined)
    displayErrorMessage(error)
  }

  const handleOnKindChange = (selectedKind: string) => {
    const newKind = selectedKind as IColumnTypes
    const isGroupedColumn = !!spreadsheetData.userConfiguration.groupSettings.find(
      (group) => group.columnId === publicId
    )

    if (isGroupedColumn && BLOCK_GROUP_BY.includes(newKind)) {
      setSnackbarMessage({ status: 'error', message: `The new column kind ${newKind} cannot be grouped` })
      return
    }
    setSelectedKind(newKind)
    setChangeColumnKindModalState(true)
  }

  const handleOnThousandSeparatorChange = (checked: boolean) => {
    if (publicId) {
      updateColumn(
        project.publicId,
        'thousandSeparator',
        spreadsheetData,
        setSpreadsheetData,
        publicId,
        checked,
        handleUpdateColumnSuccess,
        (error) => {
          handleUpdateColumnError(error)
        }
      )
    }
  }

  const handleOnDecimalChange = (decimalPlaces: number) => {
    if (publicId) {
      updateColumn(
        project.publicId,
        'decimalPlaces',
        spreadsheetData,
        setSpreadsheetData,
        publicId,
        decimalPlaces,
        handleUpdateColumnSuccess,
        (error) => {
          handleUpdateColumnError(error)
        }
      )
    }
  }

  const handleOnTableChange = (tableId: string) => {
    setProcessing(true)
    setSelectedTableViews(undefined)
    const newCurrentKindOptions = {
      ...currentKindOptions!,
      tableOptions: {
        cachedOptions: [],
        tableId: tableId,
        viewId: '',
        columnId: '',
        dependencies: [],
        liveValues: false
      }
    }
    setCurrentKindOptions(newCurrentKindOptions)
    api
      .getTableViews(tableId, { ignoreColumns: true })
      .then((response) => {
        setSelectedTableViews(getViews(response.data))
        setSelectedTableViewColumns([])
        setProcessing(false)
      })
      .catch((error) => {
        displayErrorMessage(error)
        setProcessing(false)
        setSelectedTableViewColumns([])
        setSelectedTableViews([])
      })
  }

  const getViews = (views: ITableView[]) => {
    return views.filter((view) => ALLOWED_VIEW_TYPES_ON_SELECT_OPTIONS.includes(view.type))
  }

  const handleOnTableViewChange = (viewId: string) => {
    setSelectedTableViewColumns(undefined)
    setProcessing(true)
    const newCurrentKindOptions = {
      ...currentKindOptions!,
      tableOptions: {
        ...currentKindOptions!.tableOptions,
        viewId: viewId,
        columnId: '',
        dependencies: []
      }
    }
    setCurrentKindOptions(newCurrentKindOptions)
    api
      .getTableView(viewId)
      .then((response) => {
        const view = response.data
        setSelectedTableViewColumns(view.columns)
        setProcessing(false)
      })
      .catch(() => {
        setProcessing(false)
        setSelectedTableViewColumns([])
      })
  }

  const handleOnTableViewColumnChange = (columnId: string) => {
    const columnKind = selectedTableViewColumns!.find((column) => column.publicId === columnId)!.kind
    if (BLOCK_SELECT_OPTION_TYPES.includes(columnKind)) {
      setCurrentKindOptions({
        ...currentKindOptions!,
        tableOptions: {
          ...currentKindOptions!.tableOptions,
          columnId: '',
          dependencies: []
        }
      })
      setCanAddDependency(false)
      setDependencyColumn({ columnId: '', columnJoinId: '' })
      setSnackbarMessage({
        status: 'error',
        message: `${kind} options are not applicable to table columns of type ${columnKind}`
      })
      return
    }
    const newCurrentKindOptions = {
      ...currentKindOptions!,
      tableOptions: { ...currentKindOptions!.tableOptions, columnId: columnId }
    }
    setProcessing(true)
    setCurrentKindOptions(newCurrentKindOptions)
    handleUpdateKindOptions(newCurrentKindOptions, (error) => {
      setCurrentKindOptions({
        ...currentKindOptions!,
        tableOptions: {
          ...currentKindOptions!.tableOptions,
          columnId: '',
          dependencies: []
        }
      })
      handleUpdateColumnError(error)
    })
  }

  const handleOnColumnDependencyChange = (columnId: string) => {
    const foundColumn = spreadsheetData.viewDetails.columns.find((col) => col.publicId === columnId)
    if (foundColumn && BLOCK_SELECT_OPTION_TYPES.includes(foundColumn.kind)) {
      setDependencyColumn({ ...dependencyColumn, columnId: '', columnJoinId: '' })
      setShowDependencyJoinColumn(true)
      setCanAddDependency(false)
      setSnackbarMessage({
        status: 'error',
        message: `${foundColumn.kind} options are not applicable as dependencies`
      })
      return
    }
    if (foundColumn && SELECT_COLUMN_TYPES.includes(foundColumn.kind)) {
      const needsJoin =
        foundColumn.kindOptions?.tableOptions?.tableId !== tableId ||
        foundColumn.kindOptions?.tableOptions?.viewId !== viewId
      setShowDependencyJoinColumn(needsJoin)
      setCanAddDependency(!needsJoin)
    } else {
      setShowDependencyJoinColumn(true)
      setCanAddDependency(false)
    }

    setDependencyColumn({ ...dependencyColumn, columnId: columnId })
  }

  const handleOnColumnDependencyJoinChange = (columnId: string) => {
    if (columnId === currentKindOptions?.tableOptions?.columnId) {
      setCanAddDependency(false)
      setSnackbarMessage({
        status: 'error',
        message: `Column has been already selected as table options`
      })
      return
    }
    const foundColumn =
      selectedTableViewColumns && selectedTableViewColumns.find((column) => column.publicId === columnId)
    if (foundColumn && BLOCK_SELECT_OPTION_TYPES.includes(foundColumn.kind)) {
      setCanAddDependency(false)
      setSnackbarMessage({
        status: 'error',
        message: `${foundColumn.kind} options are not applicable as dependencies`
      })
      return
    }

    setDependencyColumn({ ...dependencyColumn, columnJoinId: columnId })
    setCanAddDependency(true)
  }

  const handleAddDependencyColumn = () => {
    if (!canAddDependency) return

    const newDependencies = currentKindOptions!.tableOptions?.dependencies
      ? [...currentKindOptions!.tableOptions.dependencies]
      : []

    newDependencies.push(dependencyColumn)
    const newCurrentKindOptions = {
      ...currentKindOptions!,
      tableOptions: {
        ...currentKindOptions!.tableOptions,
        dependencies: newDependencies
      }
    }
    setCanAddDependency(false)
    setShowDependencyJoinColumn(false)
    handleUpdateKindOptions(newCurrentKindOptions, () => {
      handleUpdateColumnError('Adding the column dependency failed')
    })
    setDependencyColumn({ columnId: '', columnJoinId: '' })
  }

  const handleRemoveTableOptions = () => {
    if (currentKindOptions && currentKindOptions.tableOptions) {
      const newCurrentKindOptions = {
        ...currentKindOptions,
        tableOptions: {
          ...currentKindOptions.tableOptions,
          tableId: '',
          viewId: '',
          columnId: '',
          dependencies: []
        }
      }
      setCurrentKindOptions(newCurrentKindOptions)
      handleUpdateKindOptions(newCurrentKindOptions, handleUpdateColumnError)
    }
  }

  const handleRemoveTableDependency = (index: number) => {
    const newDependencies = [...currentKindOptions!.tableOptions!.dependencies]
    newDependencies.splice(index, 1)
    const newCurrentKindOptions = {
      ...currentKindOptions!,
      tableOptions: {
        ...currentKindOptions!.tableOptions,
        dependencies: newDependencies
      }
    }
    setCurrentKindOptions(newCurrentKindOptions)
    handleUpdateKindOptions(newCurrentKindOptions, handleUpdateColumnError)
    if (newDependencies.length === 0) {
      setShowDependencyColumn(false)
    }
  }

  const handleSetLiveValues = (liveValues: boolean) => {
    if (processing || !currentKindOptions) return
    handleUpdateKindOptions(
      {
        ...currentKindOptions,
        tableOptions: {
          ...currentKindOptions.tableOptions,
          liveValues
        }
      },
      handleUpdateColumnError
    )
  }

  const handleAddManualOption = () => {
    if (processing) return
    const newManualOptions = currentKindOptions?.manualOptions ? [...currentKindOptions!.manualOptions] : []
    if (getOptions().includes(manualOption!)) {
      setSnackbarMessage({
        status: 'error',
        message: `Option ${manualOption} already defined.`
      })
      setManualOption('')
      return
    }
    handleUpdateKindOptions(
      {
        ...currentKindOptions!,
        manualOptions: [...newManualOptions, manualOption!]
      },
      handleUpdateColumnError
    )
  }

  const handleEditManualOption = (index: number, value: string) => {
    if (processing) return
    if (currentKindOptions!.manualOptions[index] === value) {
      setEditingOption(undefined)
      return
    }

    if (currentKindOptions!.manualOptions[index] !== value && currentKindOptions!.manualOptions.includes(value)) {
      setSnackbarMessage({ status: 'error', message: 'Option already exists in current options list' })
      return
    }
    const newManualOptions = [...currentKindOptions!.manualOptions]
    newManualOptions[index] = value

    handleUpdateKindOptions({ ...currentKindOptions!, manualOptions: newManualOptions }, handleUpdateColumnError)
    setEditingOption(undefined)
  }

  const handleRemoveManualOption = (index: number) => {
    if (processing) return

    const newManualOptions = [...currentKindOptions!.manualOptions]
    newManualOptions.splice(index, 1)
    handleUpdateKindOptions({ ...currentKindOptions!, manualOptions: newManualOptions }, handleUpdateColumnError)
  }

  const handleSortManualOption = (currentIndex: number, newIndex: number) => {
    if (processing) return
    const manualOption = currentKindOptions!.manualOptions[currentIndex]
    const newManualOptions = [...currentKindOptions!.manualOptions]
    newManualOptions.splice(currentIndex, 1)
    newManualOptions.splice(newIndex, 0, manualOption)
    handleUpdateKindOptions({ ...currentKindOptions!, manualOptions: newManualOptions }, handleUpdateColumnError)
    setManualOptionSortDirection(undefined)
  }

  const handleChangeSortDirectionManualOption = (direction: sortDirections) => {
    if (processing || direction === manualOptionSortDirection) return
    const newManualOptions = [...currentKindOptions!.manualOptions]
    newManualOptions.sort()
    if (direction === sortDirections.desc) {
      newManualOptions.reverse()
    }
    setManualOptionSortDirection(direction)

    handleUpdateKindOptions({ ...currentKindOptions!, manualOptions: newManualOptions }, handleUpdateColumnError)
    setEditingOption(undefined)
  }

  const handleUpdateKindOptions = (kindOptions: SelectKindOptions, onError: (error?: string) => void) => {
    setProcessing(true)
    const newKindOptions = JSON.parse(JSON.stringify(kindOptions))
    if (newKindOptions?.tableOptions) {
      delete newKindOptions.tableOptions.cachedOptions
    }
    if (publicId) {
      updateColumn(
        project.publicId,
        'kindOptions',
        spreadsheetData,
        setSpreadsheetData,
        publicId,
        newKindOptions,
        handleUpdateColumnSuccess,
        onError
      )
    }

    setManualOption('')
  }

  const handleNoBlanksChange = (checked: boolean) => {
    if (publicId) {
      updateColumn(
        project.publicId,
        'validationNoBlanks',
        spreadsheetData,
        setSpreadsheetData,
        publicId,
        checked,
        handleUpdateColumnSuccess,
        handleUpdateColumnError
      )
    }
  }

  const handleNoDuplicatesChange = (checked: boolean) => {
    if (publicId) {
      updateColumn(
        project.publicId,
        'validationNoDuplicates',
        spreadsheetData,
        setSpreadsheetData,
        publicId,
        checked,
        handleUpdateColumnSuccess,
        handleUpdateColumnError
      )
    }
  }

  const getOptions = () => {
    let allOptions: string[] = []
    if (currentKindOptions?.manualOptions && currentKindOptions.manualOptions.length > 0) {
      allOptions = allOptions.concat(currentKindOptions?.manualOptions)
    }
    if (
      currentKindOptions?.tableOptions?.cachedOptions &&
      currentKindOptions.tableOptions.cachedOptions.length > 0 &&
      Array.isArray(currentKindOptions.tableOptions.cachedOptions)
    ) {
      allOptions = allOptions.concat(currentKindOptions.tableOptions.cachedOptions)
    }
    return allOptions
  }

  const generateManualOptions = () => {
    if (!column || processing) return
    const newManualOptions = currentKindOptions?.manualOptions ? [...currentKindOptions!.manualOptions] : []
    const values = new Set()
    const shouldTransformUserObject = column && USER_COLUMN_TYPES.includes(column.kind)

    getFilterValues(spreadsheetData.rows, column, column.publicId).forEach(values.add, values)
    getFilterValues(spreadsheetData.filteredRows, column, column.publicId).forEach(values.add, values)
    const data = Array.from(values)
    const uniqueValues = data.map((dataValue: any) => {
      const value =
        dataValue !== undefined && shouldTransformUserObject ? (dataValue as ISummaryUser).firebaseUserId : dataValue
      return value ? value.toString() : ''
    })

    handleUpdateKindOptions(
      {
        ...currentKindOptions!,
        manualOptions: [...newManualOptions, ...uniqueValues]
      },
      handleUpdateColumnError
    )
  }

  const insertText = (text: string, placeAtStart = false) => {
    if (codeMirrorRef.current) {
      const codeMirror = codeMirrorRef.current.codeMirror

      if (!placeAtStart) {
        const cursor = codeMirror.getCursor()
        const line = cursor.line
        const currentSpaces = cursor.ch
        const currentTabs = Math.floor(currentSpaces / 4)
        const newCursor = { ...cursor, line: line, ch: currentTabs * 4 }
        let newText = text + '\n'
        newText = newText.replaceAll('\n', '\n' + ' '.repeat(currentTabs * 4))
        codeMirror.replaceRange(newText, newCursor)
        const numberOfLines = newText.split('\n').length - 1
        codeMirror.setCursor({ line: line + numberOfLines, ch: currentTabs * 4 })
        codeMirror.focus()
      } else {
        codeMirror.replaceRange(text + '\n', { line: 0, ch: 0 })
        codeMirror.focus()
      }
    }
  }

  // Script helpers
  const libraries = ['math', 'urllib', 'datetime', 'time', 'json', 're', 'difflib']

  const renderLibraries = () => {
    return libraries.map((library) => {
      return (
        <div
          key={library}
          className="text-sm hover-bg-light-grey cursor-pointer transition-all"
          style={{ padding: '5px 10px' }}
          onClick={() => {
            const text = `import ${library}`
            insertText(text, true)
          }}
        >
          {library}
        </div>
      )
    })
  }

  const parseColumnName = (columnName: string) => {
    let newColumnName = columnName
      .replaceAll(' ', '_')
      .replace(/[.,\/#!$%\^&\*;:{}=\-\[\]`~()]/g, '')
      .toLowerCase()

    // Replace any non-ascii characters
    newColumnName = newColumnName.replace(/[^\x00-\x7F]/g, '')

    // If it starts with a number add an undescroe
    if (!newColumnName.match(/^[a-z]/)) {
      newColumnName = 'A' + newColumnName
    }

    return newColumnName
  }

  const renderTableFields = () => {
    const columns = spreadsheetData.viewDetails.columns

    return columns.map((column) => {
      return (
        <div
          key={column.publicId}
          className="flex items-center text-sm hover-bg-light-grey cursor-pointer transition-all"
          style={{ padding: '5px 10px' }}
          onClick={() => {
            let noneType = "''"

            if (NUMBER_COLUMN_TYPES.includes(column.kind)) {
              noneType = '0'
            } else if (DATE_COLUMN_TYPES.includes(column.kind)) {
              if (column.kind === 'date') {
                noneType = 'datetime.date.today()'
              } else {
                noneType = 'datetime.datetime.utcnow()'
              }
            }

            const columnInput = `${parseColumnName(column.name)}_value = args['row']['${column.name}'] if '${
              column.name
            }' in args['row'] and args['row']['${column.name}'] is not None else ${noneType}`
            insertText(columnInput)
          }}
        >
          <span className="flex items-center">
            <IconSelector kind={column.kind} />
          </span>
          <span className="truncate" style={{ marginLeft: '5px' }}>
            {column.name} Value
          </span>
        </div>
      )
    })
  }

  const renderRowFields = () => {
    const rowFields = ['public_id', 'created_at', 'updated_at', 'created_user', 'modified_user']

    return rowFields.map((rowField) => {
      return (
        <div
          key={rowField}
          className="text-sm hover-bg-light-grey cursor-pointer transition-all"
          style={{ padding: '5px 10px' }}
          onClick={() => {
            const rowFieldInput = `row_${rowField} = str(args['row']['${rowField}'])`
            insertText(rowFieldInput)
          }}
        >
          Row {rowField.replace('_', ' ')}
        </div>
      )
    })
  }

  const renderOtherVariables = () => {
    const otherFields = ['action', 'secrets', 'table_id']

    return otherFields.map((otherField) => {
      return (
        <div
          key={otherField}
          className="text-sm hover-bg-light-grey cursor-pointer transition-all"
          style={{ padding: '5px 10px' }}
          onClick={() => {
            const otherFieldInput = `${otherField} = str(args['${otherField}'])`
            insertText(otherFieldInput)
          }}
        >
          {capitaliseFirstLetter(otherField.replace('_', ' '))}
        </div>
      )
    })
  }

  const renderRowChanges = () => {
    const columns = spreadsheetData.viewDetails.columns

    return columns.map((column) => {
      return (
        <div
          key={column.publicId}
          className="text-sm hover-bg-light-grey cursor-pointer transition-all"
          style={{ padding: '5px 10px' }}
          onClick={() => {
            const columnChange = `${parseColumnName(
              column.name
            )}_old_value = next((x['old_value'] for x in args['updated_cells'] if x['column_name'] == '${
              column.name
            }'), None) if args['updated_cells'] else ""`

            insertText(columnChange)
          }}
        >
          {column.name} Old Value
        </div>
      )
    })
  }

  const examples = [
    {
      name: 'Text Variable',
      code: "y = 'John' # https://www.w3schools.com/python/python_variables.asp"
    },
    {
      name: 'Number Variable',
      code: 'x = 10 # https://www.w3schools.com/python/python_variables.asp'
    },
    {
      name: 'String Concatenation',
      code: "x = 'John' + ' ' + 'Doe' # https://www.w3schools.com/python/python_strings_concatenate.asp"
    },
    {
      name: 'If Statement',
      code:
        '# Code exmaple: if else statement\n# More info: https://www.w3schools.com/python/python_conditions.asp\na = 33\nb = 200\nc = ""\n\nif b > a:\n    c = "b is bigger"\n\nelse:\n    c = "a is bigger"'
    }
  ]

  const renderExamples = () => {
    return examples.map((example) => {
      return (
        <div
          key={example.name}
          className="text-sm hover-bg-light-grey cursor-pointer transition-all"
          style={{ padding: '5px 10px' }}
          onClick={() => {
            insertText(example.code)
          }}
        >
          {example.name}
        </div>
      )
    })
  }

  const handleAiScriptGeneration = () => {
    if (spreadsheetData.tableDetails.publicId && columnId) {
      setIsAiScriptLoading(true)
      setAiScriptResponse(undefined)

      const scriptText = aiScriptText + '. My current script is ```' + newScript + '```'

      const text = scriptError ? scriptText + '. I have an error in my script: ```' + scriptError + '```' : scriptText

      api
        .aiScriptHelp(spreadsheetData.tableDetails.publicId, columnId, text)
        .then((response) => {
          setAiScriptResponse(response.data)
          setIsAiScriptLoading(false)
        })
        .catch((e) => {
          displayErrorMessage(e)
          setIsAiScriptLoading(false)
        })
    }
  }

  const copyCodeToClipboard = (code: string) => {
    try {
      navigator.clipboard.writeText(code)
      setSnackbarMessage({
        status: 'success',
        message: `Code copied to clipboard`
      })
    } catch (error) {
      displayErrorMessage(error)
    }
  }

  function splitText(text: string): (string | JSX.Element)[] {
    const regex = /```(\w+)?\n?([\s\S]*?)```/g
    const result: (string | JSX.Element)[] = []
    let lastIndex = 0

    text.replace(regex, (match, lang, code, index) => {
      // Add the text before the code block
      result.push(text.substring(lastIndex, index))

      // Add the code block as a JSX element
      result.push(
        <div>
          <CodeMirror
            key={index}
            value={code.trim()}
            options={{
              mode: lang,
              lineNumbers: true,
              readOnly: true,
              lineWrapping: true,
              indentUnit: 4,
              indentWithTabs: false,
              foldGutter: true,
              gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
              theme: 'material-darker'
            }}
          />
          <div className="flex" style={{ marginTop: '5px' }}>
            <Button internalType="accept" style={{ marginLeft: 'auto' }} onClick={() => copyCodeToClipboard(code)}>
              Copy Code To Clipboard
            </Button>
          </div>
        </div>
      )

      lastIndex = index + match.length
      return match // This return value is not used, but required by replace function
    })

    // Add any remaining text after the last code block
    result.push(text.substring(lastIndex))

    return result
  }

  const content = aiScriptResponse ? splitText(aiScriptResponse) : null

  const allOptions = getOptions()
  const tableId = currentKindOptions?.tableOptions?.tableId
  const viewId = currentKindOptions?.tableOptions?.viewId
  const viewColumnId = currentKindOptions?.tableOptions?.columnId
  const dependencies = currentKindOptions?.tableOptions?.dependencies || []
  const selectedTableName = tables ? tables.find((table: ITable) => table.publicId === tableId)?.name : undefined
  const selectedViewName = selectedTableViews
    ? selectedTableViews.find((view) => view.publicId === viewId)?.name
    : undefined

  const disabled = isJoined || processing
  const isSynced =
    column &&
    (column.viewpointSynced !== IntegrationSyncTypes.NOT_SYNCED ||
      column.viewpointRfisSynced !== IntegrationSyncTypes.NOT_SYNCED ||
      column.autodeskBim360Synced !== IntegrationSyncTypes.NOT_SYNCED ||
      column.autodeskBim360ChecklistsSynced !== IntegrationSyncTypes.NOT_SYNCED ||
      column.procoreSynced !== IntegrationSyncTypes.NOT_SYNCED ||
      column.mortaSynced !== IntegrationSyncTypes.NOT_SYNCED ||
      column.reviztoIssuesSynced !== IntegrationSyncTypes.NOT_SYNCED ||
      column.asiteDocumentsSynced !== IntegrationSyncTypes.NOT_SYNCED ||
      column.aconexWorkflowsSynced !== IntegrationSyncTypes.NOT_SYNCED ||
      column.aconexSynced !== IntegrationSyncTypes.NOT_SYNCED)
  const disableSelectConfiguration = isJoined || isSynced
  if (column)
    return (
      <Modal id={id} open={open} setOpen={setOpen} title={`Edit Column '${name}'`}>
        {!isSynced && (
          <div className="mb-8">
            <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Column Name</div>
            <input
              type="text"
              defaultValue={name}
              readOnly={isSynced}
              onChange={(event) => handleNameChange(event.target.value)}
            />
          </div>
        )}

        <div className="mb-8">
          <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Column Description</div>
          <Editor
            databaseDoc={description}
            readOnly={false}
            editorId={publicId ? publicId : ''}
            border={true}
            onChange={handleDescriptionChange}
          />
        </div>

        <div className="mb-8">
          <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Column Type</div>
          <div className="w-full mb-4">
            <Select
              options={ALL_TYPES.sort((typeA, typeB) =>
                COLUMN_TYPE_NAMES[typeA] > COLUMN_TYPE_NAMES[typeB]
                  ? 1
                  : COLUMN_TYPE_NAMES[typeA] < COLUMN_TYPE_NAMES[typeB]
                  ? -1
                  : 0
              ).map((type) => {
                return { label: COLUMN_TYPE_NAMES[type], value: type as string }
              })}
              disabled={disabled || isSynced}
              optionsSelected={column ? [column.kind] : []}
              onOptionClick={(option) => handleOnKindChange(option)}
            />
          </div>

          {column.kind &&
            (column.kind === 'integer' ||
              column.kind === 'float' ||
              column.kind === 'duration' ||
              column.kind === 'autonumber' ||
              column.kind === 'percentage') && (
              <div className="w-full mb-4">
                <Checkbox
                  checked={column ? column.thousandSeparator : true}
                  onChange={(event) => handleOnThousandSeparatorChange(event.target.checked)}
                >
                  Format With Thousand Separator
                </Checkbox>
              </div>
            )}

          {column.kind && (column.kind === 'float' || column.kind === 'percentage') && (
            <div className="w-full mb-4">
              <input
                type="number"
                defaultValue={column ? column.decimalPlaces : 2}
                style={{ width: '60px', height: '30px', padding: '10px', marginRight: '10px' }}
                onChange={(event) => handleOnDecimalChange(parseInt(event.target.value))}
                min={0}
                max={10}
              />
              Decimal places
            </div>
          )}

          {column.kind && DATE_COLUMN_TYPES.includes(column.kind) && (
            <div className="flex items-center w-full mb-4">
              <input
                type="text"
                defaultValue={column && column.dateFormat ? column.dateFormat : ''}
                placeholder={column && column.kind === 'datetime' ? 'e.g. YYYY-MM-DD HH:mm:ss' : 'e.g. YYYY-MM-DD'}
                style={{ marginRight: '10px' }}
                onChange={(event) => handleOnDateFormatChange(event.target.value)}
              />
              <div style={{ whiteSpace: 'nowrap' }}>Date format</div>
            </div>
          )}
        </div>

        {!disableSelectConfiguration &&
          ((selectedKind && SELECT_COLUMN_TYPES.includes(selectedKind)) ||
            (kind && SELECT_COLUMN_TYPES.includes(kind))) && (
            <div className="mb-8">
              <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Select Options</div>
              <div className="flex items-center">
                <div style={{ minWidth: '150px' }}>Source Table:</div>
                <div className="w-full">
                  <Select
                    options={
                      tables
                        ? tables.map((table) => {
                            return { label: table.name, value: table.publicId }
                          })
                        : []
                    }
                    loading={tables === undefined ? true : false}
                    optionsSelected={tableId ? [tableId] : []}
                    onOptionClick={(option) => handleOnTableChange(option)}
                    info={'This is the table containing the source column.'}
                  />
                </div>
              </div>
              <div className="flex items-center" style={{ marginTop: '15px' }}>
                <div style={{ minWidth: '150px' }}>Source View:</div>
                <div className="w-full">
                  <Select
                    options={
                      selectedTableViews
                        ? selectedTableViews.map((view) => {
                            return { label: view.name, value: view.publicId }
                          })
                        : []
                    }
                    onOptionClick={(option) => handleOnTableViewChange(option)}
                    optionsSelected={viewId ? [viewId] : []}
                    disabled={!tableId}
                    loading={tableId && !selectedTableViews ? true : false}
                    info={'A table must be selected before you can select the view.'}
                  />
                </div>
              </div>
              <div className="flex items-center" style={{ marginTop: '15px' }}>
                <div style={{ minWidth: '150px' }}>Source Column:</div>
                <div className="w-full">
                  <Select
                    options={
                      selectedTableViewColumns
                        ? selectedTableViewColumns.map((column) => {
                            return { label: column.name, value: column.publicId }
                          })
                        : []
                    }
                    onOptionClick={(option) => handleOnTableViewColumnChange(option)}
                    optionsSelected={viewColumnId ? [viewColumnId] : []}
                    disabled={!viewId}
                    loading={viewId && selectedTableViewColumns === undefined ? true : false}
                    info={'A table and view must be selected before you can select the column.'}
                  />
                </div>
              </div>
              {noAccess && (
                <p style={{ marginTop: '20px', marginBottom: '20px', color: 'red' }}>
                  You do not have access to the table or view that has been selected by another admin of this table.
                  {tableId && viewId && (
                    <span>
                      <a
                        href={`/project/${project.publicId}/table/${tableId}/view/${viewId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Go to the table
                      </a>{' '}
                      to request access.
                    </span>
                  )}
                </p>
              )}
              {currentKindOptions && currentKindOptions.tableOptions && currentKindOptions.tableOptions.tableId && (
                <div className="flex items-center" style={{ marginTop: '15px', marginBottom: '15px' }}>
                  <div style={{ minWidth: '150px' }}>Reset Options:</div>
                  <Button internalType="danger" onClick={() => handleRemoveTableOptions()}>
                    Reset Source Column Options (Table, View and Column)
                  </Button>
                </div>
              )}
              <div className="flex items-center" style={{ marginTop: '15px', marginBottom: '15px' }}>
                <div style={{ minWidth: '150px' }}>Live Values:</div>
                <div className="w-full">
                  <Checkbox
                    checked={currentKindOptions?.tableOptions.liveValues}
                    onChange={(event) => handleSetLiveValues(event.target.checked)}
                  >
                    Keep Values Up To Date
                  </Checkbox>
                  <p>
                    Non-blank values are automatically updated from the source table. Make sure the source values are
                    unique to avoid unintended updates. Emptying a value in the source table will result in a validation
                    warning, wherever that value is selected.
                  </p>
                </div>
              </div>
              {dependencies.length === 0 && viewColumnId && !showDependencyColumn && (
                <Button internalType="accept" onClick={() => setShowDependencyColumn(true)}>
                  Add Dependency Column
                </Button>
              )}
              {viewColumnId && showDependencyColumn && (
                <div>
                  <div
                    className="inline-flex justify-between w-full font-semibold"
                    style={{ height: '20px', margin: '15px 0 5px' }}
                  >
                    Dependency Columns:
                  </div>
                  <div style={{ display: 'grid' }}>
                    {dependencies.length > 0 &&
                      dependencies.map((dependency, index) => {
                        const foundCurrentColumn = spreadsheetData.viewDetails.columns.find(
                          (col) => col.publicId === dependency.columnId
                        )
                        const columnJoinId = dependency.columnJoinId
                          ? dependency.columnJoinId
                          : foundCurrentColumn?.kindOptions?.tableOptions?.columnId

                        const columnMatchName = selectedTableViewColumns?.find((col) => col.publicId === columnJoinId)
                          ?.name
                        const remoteMatch = `${selectedTableName}/${selectedViewName}/${columnMatchName}`

                        return (
                          <div key={`column-select-${index}`} className="inline-flex items-center space-between">
                            {selectedViewName && columnMatchName && foundCurrentColumn?.name ? (
                              <div className="inline-flex items-center w-full space-evenly">
                                <div
                                  className="inline-flex items-center w-full space-evenly"
                                  style={{ marginRight: '10px' }}
                                >
                                  <span>
                                    {spreadsheetData.tableDetails.name}/{spreadsheetData.viewDetails.name}/
                                    {foundCurrentColumn?.name}
                                  </span>
                                  <span>Match to</span>
                                  <span>{remoteMatch}</span>
                                </div>
                                <div onClick={() => handleRemoveTableDependency(index)}>
                                  <Delete style={{ height: '24px', width: '24px' }} />
                                </div>
                              </div>
                            ) : (
                              <div className="spin" style={{ height: '16px', width: '16px' }} />
                            )}
                          </div>
                        )
                      })}
                  </div>
                  <div className="inline-flex items-end" style={{ marginTop: '10px' }}>
                    <div className="block" style={{ flex: 3 }}>
                      <div className="font-bold" style={{ marginBottom: '5px' }}>
                        {spreadsheetData.tableDetails.name}/{spreadsheetData.viewDetails.name}
                      </div>
                      <div style={{ width: '200px' }}>
                        <Select
                          options={spreadsheetData.viewDetails.columns.map((column) => {
                            return { label: column.name, value: column.publicId }
                          })}
                          onOptionClick={(option) => handleOnColumnDependencyChange(option)}
                          optionsSelected={dependencyColumn.columnId ? [dependencyColumn.columnId] : []}
                        />
                      </div>
                    </div>
                    {showDependencyJoinColumn && (
                      <React.Fragment>
                        <div className="text-center" style={{ margin: '10px' }}>
                          Match to
                        </div>
                        <div className="block">
                          <div className="font-bold" style={{ marginBottom: '5px' }}>
                            {selectedTableName}/{selectedViewName}
                          </div>
                          <div style={{ width: '200px' }}>
                            <Select
                              options={
                                selectedTableViewColumns
                                  ? selectedTableViewColumns.map((column) => {
                                      return { label: column.name, value: column.publicId }
                                    })
                                  : []
                              }
                              onOptionClick={(option) => handleOnColumnDependencyJoinChange(option)}
                              optionsSelected={dependencyColumn.columnJoinId ? [dependencyColumn.columnJoinId] : []}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                    <Button
                      internalType="accept"
                      style={{ width: 50, margin: '5px 10px' }}
                      disabled={!canAddDependency}
                      onClick={() => handleAddDependencyColumn()}
                    >
                      <span>Add</span>
                    </Button>
                    {canAddDependency && (
                      <div className="relative inline-block" style={{ margin: `5px 5px` }}>
                        {
                          spreadsheetData.viewDetails.columns.find(
                            (column) => column.publicId === dependencyColumn?.columnId
                          )!.name
                        }{' '}
                        {dependencyColumn?.columnJoinId ? (
                          <span>
                            needs to be linked to a column in {selectedTableName}/{selectedViewName}
                          </span>
                        ) : (
                          <span>
                            has already a link to {selectedTableName}/{selectedViewName}.
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="font-semibold w-full flex items-center" style={{ margin: '15px 0 5px' }}>
                <span>Manual Options</span>
                <Button className="ml-auto" internalType="primary" onClick={() => generateManualOptions()}>
                  + Add Unique Column Values
                </Button>

                {currentKindOptions?.manualOptions && currentKindOptions?.manualOptions.length > 0 && (
                  <div
                    className="flex items-center bg-light-grey rounded"
                    style={{ marginLeft: '15px', width: '160px', padding: '5px' }}
                  >
                    <div
                      className={`flex items-center justify-center rounded border-1px border-solid border-transparent cursor-pointer 
                        ${manualOptionSortDirection === sortDirections.asc ? 'bg-grey' : ''}`}
                      style={{ marginRight: '10px', padding: '5px', flex: 1 }}
                      onClick={() => {
                        handleChangeSortDirectionManualOption(sortDirections.asc)
                      }}
                    >
                      {`A → Z`}
                    </div>
                    <div
                      className={`flex items-center justify-center rounded border-1px border-solid border-transparent cursor-pointer 
                          ${manualOptionSortDirection === sortDirections.desc ? 'bg-grey' : ''}`}
                      style={{ padding: '5px', flex: 1 }}
                      onClick={() => {
                        handleChangeSortDirectionManualOption(sortDirections.desc)
                      }}
                    >
                      {`Z → A`}
                    </div>
                  </div>
                )}
              </div>
              <div className="inline-flex w-full" style={{ margin: '0 0 10px 0' }}>
                <input
                  type="text"
                  placeholder={'Enter manual option'}
                  value={manualOption}
                  onChange={(event) => setManualOption(event.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') handleAddManualOption()
                  }}
                />
                <Button
                  internalType="accept"
                  onClick={() => handleAddManualOption()}
                  style={{ marginLeft: 5, width: 100 }}
                  disabled={!!!manualOption}
                >
                  Add
                </Button>
              </div>
              <div className="font-semibold" style={{ marginBottom: '10px', marginTop: '15px' }}>
                Options List Preview:
              </div>

              <div className="rounded border-1px border-solid border-black" style={{ padding: '5px', height: '160px' }}>
                {allOptions.length === 0 ? (
                  <span>No options available</span>
                ) : (
                  <FixedSizeList height={150} itemCount={allOptions.length} itemSize={35} width={'100%'}>
                    {({ index, style }) => {
                      const [newValue, setNewValue] = useState<string>(allOptions[index])
                      const option = allOptions[index]
                      const isManualOption =
                        currentKindOptions?.manualOptions && currentKindOptions.manualOptions.length > 0
                          ? currentKindOptions.manualOptions.includes(option)
                          : false
                      const editing = editingOption === index
                      const disableAction = processing || editingOption !== undefined
                      return (
                        <div
                          key={`${index}-${option}`}
                          className={`flex ${isManualOption ? '' : 'bg-light-green'}`}
                          style={style}
                        >
                          {editing ? (
                            <input
                              value={newValue}
                              onChange={(event) => setNewValue(event.target.value)}
                              className="edit-manual-option-input"
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  handleEditManualOption(index, newValue)
                                }
                              }}
                              autoFocus
                            />
                          ) : (
                            <span className="truncate" style={{ width: 'calc(100% - 30px)' }}>
                              {option}
                            </span>
                          )}
                          {isManualOption && (
                            <div className="flex items-center">
                              <span
                                style={{
                                  cursor: disableAction ? 'auto' : 'pointer',
                                  display: 'inline-flex'
                                }}
                              >
                                {editingOption === undefined && (
                                  <div>
                                    {index > 0 && (
                                      <span
                                        className="flex items-center"
                                        style={{ color: processing ? 'disabled' : 'inherit' }}
                                        onClick={() => handleSortManualOption(index, index - 1)}
                                      >
                                        <Arrow type="up" />
                                      </span>
                                    )}
                                    {index < currentKindOptions!.manualOptions?.length - 1 && (
                                      <span
                                        className="flex items-center"
                                        style={{ color: processing ? 'disabled' : 'inherit' }}
                                        onClick={() => handleSortManualOption(index, index + 1)}
                                      >
                                        <Arrow type="down" />
                                      </span>
                                    )}
                                  </div>
                                )}

                                {editing ? (
                                  <span
                                    className="flex items-center"
                                    style={{ color: processing ? 'disabled' : 'inherit' }}
                                  >
                                    <span onClick={() => handleEditManualOption(index, newValue)}>
                                      <Save />
                                    </span>
                                    <span onClick={() => setEditingOption(undefined)}>
                                      <Cross />
                                    </span>
                                  </span>
                                ) : (
                                  <span
                                    className="flex items-center"
                                    style={{ color: processing ? 'disabled' : 'inherit' }}
                                    onClick={() => setEditingOption(index)}
                                  >
                                    <Pencil style={{ height: '24px', width: '24px' }} />
                                  </span>
                                )}
                              </span>
                              <span
                                style={{
                                  cursor: disableAction && editing ? 'auto' : 'pointer',
                                  color: disableAction && editing ? 'disabled' : 'inherit'
                                }}
                                onClick={() => handleRemoveManualOption(index)}
                              >
                                <Delete style={{ height: '24px', width: '24px' }} />
                              </span>
                            </div>
                          )}
                        </div>
                      )
                    }}
                  </FixedSizeList>
                )}
              </div>
            </div>
          )}

        {kind && TEXT_COLUMN_TYPES.includes(kind) && (
          <div>
            <div className="mb-8">
              <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Text Column Validation</div>
              <input
                type="text"
                defaultValue={stringValidation ? stringValidation : ''}
                onChange={(event) => handleStringValidationChange(event.target.value)}
              />
              <br />
              {regexError ? <div style={{ color: 'red', marginTop: '5px' }}>{regexError}</div> : null}
              <br />
              <i style={{ marginTop: '10px' }}>
                This{' '}
                <a href="https://regex101.com/" target="_blank" rel="noopener noreferrer">
                  regex statement
                </a>{' '}
                is used to validate input into this column.
              </i>
            </div>
          </div>
        )}

        <div className="mb-8">
          <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Validation Message</div>
          <input
            type="text"
            defaultValue={validationMessage ? validationMessage : ''}
            onChange={(event) => handleValidationMessageChange(event.target.value)}
          />
          <br />
          <br />
          <i style={{ marginTop: '10px' }}>
            This is the message that will be shown to users when they enter data which does not comply to your
            validation specification.
          </i>
        </div>

        <div className="mb-8">
          <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Other Validation Options</div>
          <div style={{ width: '300px' }}>
            <Checkbox
              checked={validationNoDuplicates}
              onChange={(event) => handleNoDuplicatesChange(event.target.checked)}
            >
              No Duplicates
            </Checkbox>
          </div>

          <div style={{ width: '300px' }}>
            <Checkbox checked={validationNoBlanks} onChange={(event) => handleNoBlanksChange(event.target.checked)}>
              No Blanks
            </Checkbox>
          </div>

          <div style={{ width: '300px' }}>
            <Checkbox checked={hardValidation} onChange={(event) => handleHardValidationChange(event.target.checked)}>
              Block Data Entry
            </Checkbox>
          </div>
        </div>

        {!isSynced && (
          <div className="mb-8">
            <div className="flex items-center border-b-2px border-solid border-grey py-10px mb-4 font-bold">
              Script Enabled
            </div>
            <p className="mb-8">
              Morta utilises the{' '}
              <a href="https://www.python.org/" target="_blank" rel="noopener noreferrer">
                Python
              </a>{' '}
              programming language for column scripts. Python is an open-source programming language and is one of the
              most popular programming languages in the world used by companies such as Google, Facebook, Netflix and
              Spotify. Rather than learning a custom formula builder for Morta, you can use this open-source language to
              create powerful column scripts. A lot of helpful material on Python can be found on the{' '}
              <a href="https://www.w3schools.com/python" target="_blank" rel="noopener noreferrer">
                w3schools website
              </a>
              .
            </p>
            <div className="flex w-full">
              <Checkbox
                disabled={disabled}
                checked={scriptEnabled}
                onChange={(event) => handleScriptEnabledChange(event.target.checked)}
              >
                Column Is Script
              </Checkbox>
            </div>
          </div>
        )}

        {scriptEnabled && (
          <div>
            <div style={{ background: constants.aiBackground, padding: '20px', marginBottom: '20px' }}>
              <div className="flex">
                <AI style={{ marginRight: '5px' }} />
                <b>Morta AI Script Helper</b>
                <Beta />
              </div>

              <input
                placeholder="e.g. 'Combine column 1 and column 2' or 'How can I fix my python script?'"
                style={{ marginTop: '20px' }}
                disabled={isAiScriptLoading}
                onChange={(event) => {
                  setAiScriptResponse(undefined)
                  setAiScriptText(event.target.value)
                }}
              />
              <div className="flex" style={{ marginTop: '10px' }}>
                <Button
                  internalType="gradient"
                  className="text-base ml-auto"
                  style={{ width: '200px', padding: '10px' }}
                  onClick={() => handleAiScriptGeneration()}
                  disabled={isAiScriptLoading || aiScriptText === ''}
                  isLoading={isAiScriptLoading}
                >
                  <AI style={{ marginRight: '5px' }} />
                  Ask Morta AI
                </Button>
              </div>
              {aiScriptResponse && (
                <div>
                  <div className="font-bold" style={{ marginBottom: '20px' }}>
                    <AI style={{ marginRight: '5px' }} /> Morta AI Powered Answer
                  </div>
                  <div className="select-text" style={{ lineHeight: 1.5, whiteSpace: 'pre-wrap', padding: '0px 5px' }}>
                    {content ? (
                      content.map((part, index) => <React.Fragment key={index}>{part}</React.Fragment>)
                    ) : (
                      <p>No content available.</p>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div style={{ marginBottom: '20px' }}>
              {scriptError !== '' && (
                <pre
                  className="text-sm w-full bg-light-grey"
                  style={{
                    lineHeight: 2,
                    marginBottom: '20px',
                    padding: '10px',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'normal',
                    border: `2px solid ${constants.lightRed}`
                  }}
                >
                  {scriptError}
                </pre>
              )}
              <div className="flex">
                <div
                  className="border-1px border-solid border-grey overflow-y-auto overflow-x-hidden"
                  style={{ height: '600px', width: '300px', marginRight: '5px' }}
                >
                  <div className="bg-light-blue text-sm italic" style={{ padding: '5px 10px' }}>
                    Available Libraries
                  </div>
                  {renderLibraries()}

                  <div className="bg-light-blue text-sm italic" style={{ padding: '5px 10px' }}>
                    Column Values
                  </div>
                  {renderTableFields()}

                  <div className="bg-light-blue text-sm italic" style={{ padding: '5px 10px' }}>
                    Row Values
                  </div>
                  {renderRowFields()}

                  <div className="bg-light-blue text-sm italic" style={{ padding: '5px 10px' }}>
                    Row Changes
                  </div>
                  {renderRowChanges()}

                  <div className="bg-light-blue text-sm italic" style={{ padding: '5px 10px' }}>
                    Other Variables
                  </div>
                  {renderOtherVariables()}

                  <div className="bg-light-blue text-sm italic" style={{ padding: '5px 10px' }}>
                    Examples
                  </div>

                  {renderExamples()}
                </div>
                <div className="w-full" style={{ height: '600px', overflow: 'auto', background: '#212121' }}>
                  <CodeMirror
                    ref={codeMirrorRef}
                    value={script ? script : ''}
                    onChange={(code: string) => setNewScript(code)}
                    options={{
                      lineNumbers: true,
                      mode: 'python',
                      indentUnit: 4,
                      indentWithTabs: false,
                      lineWrapping: true,
                      foldGutter: true,
                      gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
                      theme: 'material-darker',
                      extraKeys: {
                        Tab: function (cm) {
                          const spaces = Array(5).join(' ')
                          cm.replaceSelection(spaces)
                        }
                      }
                    }}
                  />
                </div>
              </div>
              <div className="flex" style={{ marginTop: '10px' }}>
                <Button className="ml-auto" internalType="accept" onClick={() => handleScriptChange()}>
                  Update Script
                </Button>
              </div>
            </div>
          </div>
        )}

        <div className="mb-8">
          <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Column Styling</div>
          <div className="flex items-center">
            <span style={{ minWidth: '300px', maxWidth: '300px' }}>Column Header Background Colour:</span>
            <select
              style={{ minWidth: '200px', maxWidth: '200px', marginRight: '20px' }}
              value={column.headerBackgroundColor ? 'manual' : 'auto'}
              onChange={(event) => {
                updateColumn(
                  project.publicId,
                  'headerBackgroundColor',
                  spreadsheetData,
                  setSpreadsheetData,
                  column.publicId,
                  event.target.value === 'auto' ? null : constants.lightGrey,
                  handleUpdateColumnSuccess,
                  handleUpdateColumnError
                )
              }}
            >
              <option value="auto">Auto</option>
              <option value="manual">Manual</option>
            </select>
            {column.headerBackgroundColor && (
              <CompactPicker
                color={column.headerBackgroundColor}
                onChange={(value: any) =>
                  updateColumn(
                    project.publicId,
                    'headerBackgroundColor',
                    spreadsheetData,
                    setSpreadsheetData,
                    column.publicId,
                    value.hex,
                    handleUpdateColumnSuccess,
                    handleUpdateColumnError
                  )
                }
              />
            )}
          </div>
          <div className="italic flex mb-8 text-secondary" style={{ marginTop: '5px' }}>
            Leave this blank to leave as default values. This will be light grey in the Morta platform and your project
            colour in exports.
          </div>
          <div className="flex items-center">
            <span style={{ minWidth: '300px', maxWidth: '300px' }}>Column Header Text Colour:</span>
            <select
              style={{ minWidth: '200px', maxWidth: '200px', marginRight: '20px' }}
              value={column.headerTextColor ? 'manual' : 'auto'}
              onChange={(event) => {
                updateColumn(
                  project.publicId,
                  'headerTextColor',
                  spreadsheetData,
                  setSpreadsheetData,
                  column.publicId,
                  event.target.value === 'auto' ? null : constants.lightGrey,
                  handleUpdateColumnSuccess,
                  handleUpdateColumnError
                )
              }}
            >
              <option value="auto">Auto</option>
              <option value="manual">Manual</option>
            </select>
            {column.headerTextColor && (
              <CompactPicker
                color={column.headerTextColor}
                onChange={(value: any) =>
                  updateColumn(
                    project.publicId,
                    'headerTextColor',
                    spreadsheetData,
                    setSpreadsheetData,
                    column.publicId,
                    value.hex,
                    handleUpdateColumnSuccess,
                    handleUpdateColumnError
                  )
                }
              />
            )}
          </div>
          <div className="italic flex mb-8 text-secondary" style={{ marginTop: '5px' }}>
            Leave this blank to leave as default values. This will be black in the Morta platform and in exports.
          </div>

          <div className="flex items-center" style={{ height: '50px' }}>
            <span style={{ minWidth: '300px', maxWidth: '300px' }}>Column Header Export Width (cm):</span>
            <select
              style={{ minWidth: '200px', maxWidth: '200px', marginRight: '20px' }}
              value={column.exportWidth !== null ? 'manual' : 'auto'}
              onChange={(event) => {
                updateColumn(
                  project.publicId,
                  'exportWidth',
                  spreadsheetData,
                  setSpreadsheetData,
                  column.publicId,
                  event.target.value === 'auto' ? null : 5,
                  handleUpdateColumnSuccess,
                  handleUpdateColumnError
                )
              }}
            >
              <option value="auto">Auto</option>
              <option value="manual">Manual</option>
            </select>
            {column.exportWidth !== null && (
              <input
                type="number"
                style={{ minWidth: '200px', maxWidth: '200px' }}
                min={0}
                defaultValue={column.exportWidth}
                onChange={(event) => handleExportWidthChange(Number.parseInt(event.target.value))}
              />
            )}
          </div>
        </div>

        {changeColumnKindModalState && selectedKind && (
          <ColumnKindModal
            id="change-column-kind-modal"
            open={changeColumnKindModalState}
            setOpen={setChangeColumnKindModalState}
            newKind={selectedKind}
            columnId={publicId}
            onCancel={() => {
              setSelectedKind(column.kind)
            }}
          />
        )}
      </Modal>
    )
  else return <div />
}

export default EditColumnModal
