import React, { useState, useEffect, useMemo, useRef } from 'react'
import Editor from 'components/editor'
import { IContextMenuState, ITableViewColumn, ICellValue, EditorContent, IUpdateTableViewCell } from 'types'
import IconSelector from 'components/spreadsheet/components/cell/components/icon'
import { INITIAL_SELECTED_CELL, PREVIEW_COLUMN_TYPES } from 'components/spreadsheet/constants/const'
import { Option, Lock, Join } from 'components/icons'
import ColumnMenu from 'components/spreadsheet/components/menu/views/column'
import Cell from 'components/spreadsheet/components/cell'
import { updateView } from 'components/spreadsheet/contexts/data/actions'
import Button from 'components/button'
import { useApplicationStore } from 'hooks/application'
import { isFieldValueEmpty, isEditorEmpty } from 'components/spreadsheet/helpers/functions'
import { isValidValue } from 'components/spreadsheet/helpers/validation'
import { useProject } from 'hooks/project'
import DeleteColumnModal from 'components/spreadsheet/components/modal/views/deletecolumn'
import EditColumnModal from 'components/spreadsheet/components/modal/views/editcolumn'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import { cancelTimeout, requestTimeout, TimeoutID } from 'helpers/timer'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import api from 'helpers/api'

const createFormValueState = (values: Record<string, ICellValue>, columns: ITableViewColumn[]) => {
  const newValues: Record<string, ICellValue> = {}
  for (const column of columns) {
    newValues[column.publicId] = values[column.publicId] ? values[column.publicId] : null
  }

  return newValues
}

const FormComponent: React.FC = () => {
  const { project } = useProject()
  const {
    spreadsheetData,
    setSpreadsheetData,
    uniqueNumber,
    selectedCell,
    setSelectedCell,
    handleCreateRow,
    handleKeyDownForm,
    setIsFocused
  } = useDataContext()

  const [editColumnMenu, setEditColumnMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [deleteColumnModal, setDeleteColumnModal] = useState<boolean>(false)
  const [editColumnModal, setEditColumnModal] = useState<boolean>(false)
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
  const [currentViewId, setCurrentViewId] = useState<string>(spreadsheetData.viewDetails.publicId)
  const controllerRef = useRef<AbortController | null>()
  const [confirmSubmit, setConfirmSubmit] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const descriptionTimeout = useRef<TimeoutID | null>(null)

  // The columns we use to build the form
  const columns = useMemo(() => {
    return spreadsheetData.viewDetails.columns.filter(
      (column) =>
        !spreadsheetData.userConfiguration.hiddenColumns.find((hiddenColumnId) => hiddenColumnId === column.publicId)
    )
  }, [spreadsheetData.viewDetails.columns, spreadsheetData.userConfiguration.hiddenColumns])

  const [formValues, setFormValues] = useState(() => createFormValueState({}, columns))

  // If column info is changed, update the form's values
  useEffect(() => {
    setFormValues(createFormValueState(formValues, columns))
  }, [columns])

  // If new view is selected
  useEffect(() => {
    if (currentViewId !== spreadsheetData.viewDetails.publicId) {
      setFormSubmitted(false)
      setFormValues(createFormValueState({}, columns))
      setCurrentViewId(spreadsheetData.viewDetails.publicId)
    }
  }, [spreadsheetData.viewDetails])

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>, columnId: string) => {
    if (spreadsheetData.isAdmin) {
      event.dataTransfer.setData('columnId', columnId)
      event.dataTransfer.effectAllowed = 'move'
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    if (spreadsheetData.isAdmin) {
      event.preventDefault()
    }
  }

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    if (spreadsheetData.isAdmin) {
      event.dataTransfer.dropEffect = 'move'
    }
  }

  const handleOnDrop = (event: React.DragEvent<HTMLDivElement>, columnNumber: number) => {
    if (spreadsheetData.isAdmin) {
      const draggedColumnId = event.dataTransfer.getData('columnId')
      if (draggedColumnId && draggedColumnId !== '')
        setSpreadsheetData({
          type: 'CHANGE_COLUMN_ORDER',
          columnId: draggedColumnId,
          newIndex: columnNumber
        })
    }
  }

  const handleOnValueChange = async (cells: IUpdateTableViewCell, onSuccess: () => void) => {
    const columnId = cells.cells[0].columnId
    const value = cells.cells[0].value

    // make sure values are saved in the state before calling preview row
    setFormValues((existingFormValues) => {
      return { ...existingFormValues, [columnId]: value }
    })

    // check if any of the of the columns needs to display pre-calculated data e.g. created/createdBy/script
    // TODO: add join to validation
    const previewColumns = columns.filter(
      (col) => PREVIEW_COLUMN_TYPES.includes(col.kind) || col.scriptEnabled || col.isJoined
    )
    if (previewColumns.length > 0) {
      // abort any call made to preview row if any has been initiated
      if (controllerRef.current) {
        controllerRef.current.abort()
      }
      const controller = new AbortController()
      controllerRef.current = controller
      try {
        const previewRowRequest = await api.previewRow(
          project.publicId,
          spreadsheetData.activeTableView,
          { ...formValues, [columnId]: value },
          controllerRef.current?.signal
        )
        setFormValues((formValues) => {
          const newFormValues = { ...formValues }
          for (const column of previewColumns) {
            newFormValues[column.publicId] = previewRowRequest.data[column.publicId]
          }
          return newFormValues
        })
      } catch (e) {
        displayErrorMessage(e)
      }
    }
    onSuccess()
  }

  const handleSubmit = async () => {
    if (submitting) return
    setSubmitting(true)

    // We need to remove keys with  and values that are:
    // - Empty strings : Empty strings are used as default values for keys
    // - Script and joined values: They are calculated values in the BE
    const allowedColumnValues = columns
      .filter((column) => !(column.isJoined || column.scriptEnabled))
      .map((column) => column.publicId)
    const values = Object.keys(formValues).reduce((acc, key) => {
      if (allowedColumnValues.includes(key) && formValues[key] !== '') {
        acc[key] = formValues[key]
      }
      return acc
    }, {} as Record<string, ICellValue>)

    // Check that required fields have a value
    for (const column of columns) {
      if (
        allowedColumnValues.includes(column.publicId) &&
        column.required &&
        isFieldValueEmpty(column.kind, values[column.publicId])
      ) {
        setConfirmSubmit(false)
        setSubmitting(false)
        setSnackbarMessage({
          message: `The form field ${column.name} is required`,
          status: 'error'
        })
        return
      }
    }

    // Check that values are valid
    for (const column of columns) {
      if (spreadsheetData && spreadsheetData.rows) {
        const validValue = isValidValue(
          formValues[column.publicId],
          null,
          spreadsheetData.columnValuesCount,
          column,
          formValues,
          spreadsheetData?.viewDetails.type,
          true
        )

        if (validValue.error) {
          if (validValue.warn) setSnackbarMessage({ status: 'warning', message: validValue.errorMessage })
          else {
            setConfirmSubmit(false)
            setSubmitting(false)
            setSnackbarMessage({ status: 'error', message: validValue.errorMessage })
            return
          }
        }
      }
    }

    try {
      await handleCreateRow(values)
      setFormValues(createFormValueState({}, columns))
      setFormSubmitted(true)
      setSelectedCell(INITIAL_SELECTED_CELL)
      setConfirmSubmit(false)
      setSnackbarMessage({ message: 'Successfully submitted', status: 'success' })
      setSubmitting(false)
    } catch (e) {
      displayErrorMessage(e)
      setConfirmSubmit(false)
      setSubmitting(false)
    }
  }

  const handleResetSubmit = () => {
    setFormSubmitted(false)
  }

  const handleViewUpdateSuccess = () => {
    setSnackbarMessage({ status: 'success', message: 'The view description was updated successfully!' })
  }

  const handleViewUpdateFailure = (error: any) => {
    displayErrorMessage(error)
  }

  const handleDescriptionChange = (description: EditorContent) => {
    if (descriptionTimeout.current !== null) cancelTimeout(descriptionTimeout.current)

    descriptionTimeout.current = requestTimeout(() => {
      updateView(
        'description',
        spreadsheetData,
        setSpreadsheetData,
        spreadsheetData.viewDetails.publicId,
        isEditorEmpty(description) ? null : description,
        project.publicId,
        handleViewUpdateSuccess,
        handleViewUpdateFailure
      )
    }, 1000)
  }

  const disable = spreadsheetData.userConfiguration.unsavedChanges || submitting

  return (
    <div className="relative bg-white rounded shadow" style={{ width: '70%', margin: '40px auto', padding: '40px' }}>
      <div
        className="flex w-full truncate font-bold text-2xl border-b-2px border-solid border-grey"
        style={{ marginBottom: '30px', height: '70px', lineHeight: '70px' }}
      >
        {spreadsheetData.viewDetails.name}
        {spreadsheetData.tableDetails.logo && (
          <img
            className="h-full ml-auto"
            src={spreadsheetData.tableDetails.logo}
            alt={`${spreadsheetData.tableDetails.name} Logo`}
          />
        )}
      </div>

      {!formSubmitted && (
        <div>
          {(spreadsheetData.viewDetails.description || spreadsheetData.isAdmin) && (
            <div className="border-b-2px border-solid border-grey" style={{ marginBottom: '30px' }}>
              <Editor
                databaseDoc={spreadsheetData.viewDetails.description}
                readOnly={!spreadsheetData.isAdmin}
                editorId={spreadsheetData.viewDetails.publicId}
                onChange={(value) => handleDescriptionChange(value)}
              />
            </div>
          )}
          {columns.map((column: ITableViewColumn, columnNumber: number) => {
            const selected = selectedCell.rowId === 'new-row-form' && selectedCell.columnId === column.publicId
            const hasActiveValidation =
              column.stringValidation ||
              column.hardValidation ||
              column.validationNoBlanks ||
              column.validationNoDuplicates
            return (
              <div
                key={columnNumber}
                className="w-full rounded border-1px border-solid border-grey"
                style={{ marginTop: '20px', marginBottom: '20px' }}
                onDragStart={(event) => handleDragStart(event, column.publicId)}
                onDragOver={handleDragOver}
                onDrop={(event) => handleOnDrop(event, columnNumber)}
                onDragEnter={handleDragEnter}
                draggable={spreadsheetData.isAdmin && !column.locked}
              >
                <div
                  className="flex items-center bg-light-grey rounded w-full"
                  style={{ height: '50px', padding: '10px' }}
                >
                  <IconSelector kind={column.kind} />
                  <div style={{ marginLeft: '10px' }}>{column.name}</div>
                  {spreadsheetData.isAdmin && !column.locked && !column.isJoined && (
                    <div
                      className="cursor-pointer"
                      style={{ marginLeft: '15px' }}
                      onClick={(event: React.MouseEvent) =>
                        setEditColumnMenu({
                          open: true,
                          top: `${event.currentTarget.getBoundingClientRect().bottom}px`,
                          left: `${event.currentTarget.getBoundingClientRect().left - 130}px`,
                          right: 'auto',
                          bottom: 'auto',
                          columnNumber: columnNumber,
                          columnId: column.publicId,
                          rowNumber: -1,
                          rowId: ''
                        })
                      }
                    >
                      <Option />
                    </div>
                  )}

                  {column.locked && (
                    <div className="cursor-pointer" style={{ marginLeft: '15px' }}>
                      <Lock />
                    </div>
                  )}
                  {column.isJoined && (
                    <div className="cursor-pointer" style={{ marginLeft: '15px' }}>
                      <Join />
                    </div>
                  )}

                  {column.required && <div className="text-red ml-auto text-sm">* This field is required</div>}
                </div>
                {column.description && (
                  <div>
                    <Editor
                      databaseDoc={column.description}
                      readOnly={true}
                      editorId={`form-field-description-${column.publicId}`}
                    />
                  </div>
                )}
                <div className="form-field-cell">
                  <Cell
                    key={`$new-row-form-column-${column.publicId}`}
                    value={formValues[column.publicId]}
                    rowNumber={-2000}
                    rowId="new-row-form"
                    prevRowId="new-row-form"
                    nextRowId="new-row-form"
                    columnNumber={columnNumber}
                    columnId={column.publicId}
                    nextColumnId={columnNumber <= columns.length - 2 ? columns[columnNumber + 1].publicId : undefined}
                    prevColumnId={columnNumber > 0 ? columns[columnNumber - 1].publicId : undefined}
                    setSelectedCell={setSelectedCell}
                    kind={column.kind}
                    scriptEnabled={column.scriptEnabled}
                    isJoined={column.isJoined}
                    formValues={formValues}
                    frozen={false}
                    rowHeight={spreadsheetData.viewDetails.rowHeight}
                    isContributor={spreadsheetData.isContributor}
                    isAdmin={spreadsheetData.isAdmin}
                    handleKeyDown={handleKeyDownForm}
                    uniqueNumber={uniqueNumber}
                    locked={column.locked || column.isJoined}
                    setCellValue={handleOnValueChange}
                    columnName={column.name}
                    selected={selected}
                    isExpanded={true}
                    editing={selected && selectedCell.editing}
                    tempValue={selected ? selectedCell.value : undefined}
                    setIsFocused={setIsFocused}
                    type={spreadsheetData.viewDetails.type}
                    colourSettings={spreadsheetData.userConfiguration.colourSettings}
                    columnValuesCount={hasActiveValidation ? spreadsheetData.columnValuesCount : undefined}
                    column={column}
                    startRowId={spreadsheetData.rows[0]?.publicId}
                    finalRowId={spreadsheetData.rows[spreadsheetData?.rows.length - 1]?.publicId}
                    finalRowNumber={spreadsheetData.rows.length}
                    startColumnId={spreadsheetData.viewDetails.columns[0]?.publicId}
                    finalColumnId={
                      spreadsheetData.viewDetails.columns[spreadsheetData.viewDetails.columns.length - 1].publicId
                    }
                    finalColumnNumber={spreadsheetData.viewDetails.columns.length}
                  />
                </div>
              </div>
            )
          })}

          {spreadsheetData.isContributor && (
            <div
              className="font-medium truncate border-t-2px border-solid border-grey text-lg"
              style={{ marginTop: '30px' }}
            >
              {!confirmSubmit && (
                <div>
                  <p className="truncate" style={{ margin: '2rem 0' }}>
                    Please make sure you have filled in all the required fields before submitting the form.
                  </p>
                  <Button
                    disabled={spreadsheetData.userConfiguration.unsavedChanges}
                    onClick={() => setConfirmSubmit(true)}
                  >
                    Submit Form
                  </Button>
                  {spreadsheetData.userConfiguration.unsavedChanges ? (
                    <span className="text-red" style={{ marginLeft: '1rem' }}>
                      Please save changes to current view first, then submit form
                    </span>
                  ) : null}
                </div>
              )}

              {confirmSubmit && (
                <div>
                  <p className="truncate" style={{ margin: '2rem 0' }}>
                    Are you sure you would like to submit the form?
                  </p>
                  <div className="flex">
                    <Button
                      internalType="danger"
                      disabled={disable}
                      onClick={() => {
                        if (submitting) return
                        setConfirmSubmit(false)
                      }}
                    >
                      No
                    </Button>
                    <Button
                      internalType="accept"
                      style={{ marginLeft: '15px' }}
                      disabled={disable}
                      onClick={handleSubmit}
                      isLoading={submitting}
                    >
                      Yes
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {formSubmitted && (
        <div className="font-medium text-lg truncate" style={{ marginTop: '30px' }}>
          <p style={{ margin: '0 0 2rem 0' }}>Thank you for your submission.</p>
          <Button data-testid="form-submit-button" onClick={handleResetSubmit}>
            Submit Another Form
          </Button>
        </div>
      )}

      <ColumnMenu
        id={`column-context-menu-${uniqueNumber}`}
        menuState={editColumnMenu}
        setMenuState={setEditColumnMenu}
        width={175}
        setDeleteColumnModal={setDeleteColumnModal}
        setEditColumnModal={setEditColumnModal}
      />

      {editColumnModal && (
        <EditColumnModal
          id="expanded-row-modal"
          open={editColumnModal}
          setOpen={setEditColumnModal}
          initialColumnNumber={editColumnMenu.columnNumber}
          initialColumnId={editColumnMenu.columnId}
        />
      )}
      {deleteColumnModal && (
        <DeleteColumnModal
          id="delete-column-modal"
          open={deleteColumnModal}
          setOpen={setDeleteColumnModal}
          initialColumnId={editColumnMenu.columnId}
        />
      )}
    </div>
  )
}

export default React.memo(FormComponent)
